import { DataTable, TableToolbar, useTable } from '@pay/admin-data-table';
import { Page, PageTitle, useModals } from '@pay/admin-ui-kit';
import React, { useMemo, FC, useEffect } from 'react';
import { useTranslation } from 'startup/utils';
import { BlackboxData } from 'apis-generated/mapper-processing-admin';
import { getPosTerminalApkColumns } from './tableDefs';
import { Button } from '@material-ui/core';
import { UploadApkModal } from './UploadApkModal';
import { Refresh } from '@material-ui/icons';
import { useAuthStore } from 'modules/auth';
import { EPermissionType } from 'modules/user-management';
import { usePosTerminalsService, usePosTerminalEvents } from '../module';

interface IProps {}

export const PosTerminalApkPage: FC<IProps> = () => {
  const { t } = useTranslation();
  const { fetchTable } = usePosTerminalsService();
  const { onRefreshTable$ } = usePosTerminalEvents();

  const { showModal } = useModals();

  const canUpload = !!useAuthStore().currentUser?.hasOneOfPermissions([
    EPermissionType.FinancialOrganizationWrite,
  ]);

  const columns = useMemo(() => getPosTerminalApkColumns(t), [t]);

  const { tableProps, toolBarProps, refresh } = useTable({
    fetchData: fetchTable(),
    pageSize: 20,
    columns,
    keyGetter: (it) => it.versionCode || Math.random().toString(),
  });

  const handleAddClick = () => {
    showModal(<UploadApkModal onUploaded={refresh} open={false} />);
  };

  return (
    <Page>
      <PageTitle>{t('pos_terminal_apk')}</PageTitle>
      <TableToolbar<BlackboxData> {...toolBarProps} columns={columns}>
        {canUpload && (
          <Button variant="contained" color="primary" size="small" onClick={handleAddClick}>
            {t('pos_terminal_upload')}
          </Button>
        )}
        <Button startIcon={<Refresh />} variant="contained" color="primary" onClick={refresh}>
          {t('common_refresh')}
        </Button>
      </TableToolbar>
      <DataTable {...tableProps} />
    </Page>
  );
};
