import { UserLocationSchoolsDto } from 'apis-generated/reports';

export type Location = {
  code: string;
  name: string;
  parrentId?: string;
  parentsStr?: string;
};

export type School = {
  bin: string;
  name: string;
  address: string;
};

export type UserLocationSchools = {
  location: Location;
  // empty if aviable all schools
  schools?: School[];
};

export type UserLocationSchoolsShort = {
  locationCode: string;
  // empty if aviable all schools
  schoolBins?: string[];
};

export const isAviableAllSchoolByLocation = (userLocationSchools: UserLocationSchools) => {
  return !!userLocationSchools.schools;
};

export const toUserLocationSchoolsShort = (
  userLocationSchools: UserLocationSchools
): UserLocationSchoolsShort => {
  return {
    locationCode: userLocationSchools.location.code,
    schoolBins: userLocationSchools.schools?.map((school) => school.bin),
  };
};

export const makeFromDto = ({ location, schools }: UserLocationSchoolsDto): UserLocationSchools => {
  return {
    location: {
      code: location.code,
      name: location.name,
      parrentId: location.parrentId,
      parentsStr: location.parents,
    },
    schools: schools?.map((school) => ({
      bin: school.bin,
      name: school.name,
      address: school.address,
    })),
  };
};
