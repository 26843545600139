import React from 'react';
import { Column } from '@pay/admin-data-table';
import Button from '@material-ui/core/Button';

import { ClientRegistrationResponse } from 'apis-generated/mapper-sso-admin';
import { IAppTransFunction } from 'startup/utils';
import { formatDate } from 'modules/common/format';
import { DATE_FORMAT } from 'modules/common/constants';

export const getClientDevicesColumns = (
  t: IAppTransFunction,
  onDeregisterDevice: (registrationId: ClientRegistrationResponse) => void
): Column<ClientRegistrationResponse>[] => {
  return [
    {
      accessor: 'deviceType',
      Header: t('client_details_devices_device_type'),
      sortable: false,
    },
    {
      accessor: 'osVersion',
      Header: t('client_details_devices_os_version'),
      sortable: false,
    },
    {
      accessor: 'registrationId',
      Header: t('client_details_devices_registration_id'),
      sortable: false,
    },
    {
      accessor: 'deviceId',
      Header: t('client_details_devices_device_id'),
      sortable: false,
    },
    {
      accessor: 'model',
      Header: t('client_details_devices_model'),
      sortable: false,
    },
    {
      accessor: 'registrationDate',
      Header: t('client_details_devices_date_registration'),
      sortable: false,
      Cell: ({ row: { original }, value }) => (
        <>{formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm)}</>
      ),
    },

    {
      accessor: 'lastLogin',
      Header: t('client_details_devices_last_login'),
      sortable: false,
      Cell: ({ row: { original }, value }) => (
        <>{formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm)}</>
      ),
    },
    {
      id: 'deregister_device',
      accessor: 'model',
      width: 50,
      Cell: ({ row: { original } }) => {
        return (
          <Button color="primary" variant="contained" onClick={() => onDeregisterDevice(original)}>
            {t('client_details_added_fi_unbind_particular')}
          </Button>
        );
      },
    },
  ];
};
