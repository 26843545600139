import { combineValidators, makeRegexValidator, makeRequiredValidator } from '@pay/common-utils';
import { COUNTRY_PHONE_FORMAT, ECountryCode, formatPhone } from 'modules/common/format-phone';
import { ITransKey } from 'startup/i18n';

export const userNameValidator = combineValidators(
  makeRequiredValidator<ITransKey>('validation_required'),
  makeRegexValidator<ITransKey>(/^[A-Za-z0-9_\-.]+$/, 'user_validation_username')
);
export const phoneValidator = makeRegexValidator(
  COUNTRY_PHONE_FORMAT[ECountryCode.Kk].regex,
  'validation_phone'
);

export const formatPhoneRifm = (val: string) => {
  const digits = formatPhone(val, ECountryCode.Kk);
  return digits;
};
