import { CurrentUserModel } from 'modules/auth';
import { EPermissionType } from 'modules/user-management';

export const hasOneOfPermissions = (
  currentUser: CurrentUserModel,
  permissions: EPermissionType[]
) => {
  if (isServiceMealAdmin(currentUser)) return true;

  return currentUser.hasOneOfPermissions(permissions);
};

export const hasAllPermissions = (
  currentUser: CurrentUserModel,
  permissions: EPermissionType[]
) => {
  if (isServiceMealAdmin(currentUser)) return true;

  return currentUser.hasOneOfPermissions(permissions);
};

export const isServiceMealAdmin = (currentUser: CurrentUserModel) => {
  return currentUser.hasOneOfPermissions([EPermissionType.ServiceMealAdmin]);
};
