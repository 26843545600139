import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import { mapFetcherResultEither } from 'modules/common/fetcher';
import { LegalId, WorkspaceApiInterface } from 'apis-generated/mapper-processing-admin';

export class WorkspaceService {
  constructor(private _api: WorkspaceApiInterface) {}

  public getWorkspaceStats = (fromDate: Date, toDate: Date) =>
    pipe(
      () => this._api.getWorkspaceStats({ from: fromDate, to: toDate }),
      T.map(mapFetcherResultEither)
    );

  public deleteWorkspace = (id: string) =>
    pipe(() => this._api.deleteWorkspace({ id: id }), T.map(mapFetcherResultEither));

  public addEmployeeToLegal = (userName: string, data: LegalId) =>
    pipe(
      () => this._api.addLegalWorkspace({ legalId: data }, { username: userName }),
      T.map(mapFetcherResultEither)
    );

  public addEmployeeToEntrepreneur = (userName: string, data: LegalId) =>
    pipe(
      () => this._api.addEntrepreneurWorkspace({ legalId: data }, { username: userName }),
      T.map(mapFetcherResultEither)
    );

  public blockWorkspace = (id: string) =>
    pipe(() => this._api.blockWorkspace({ id }), T.map(mapFetcherResultEither));

  public unblockWorkspace = (id: string) =>
    pipe(() => this._api.unblockWorkspace({ id }), T.map(mapFetcherResultEither));
}
