import { makeTableDataSource } from '@pay/admin-resources';
import { IFetcher } from '@pay/data-fetching';
import {
  BackofficeMealUserApiInterface,
  BackofficeRemoveUserReqDto,
  BOMealUserAddRequest,
  BOMealUserDto,
  BOMealUserEditRequest,
} from 'apis-generated/mapper-sso-admin';
import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import { mapFetcherResultEither } from 'modules/common/fetcher';

export const makeBOMealUserService = (fetcher: IFetcher, api: BackofficeMealUserApiInterface) => {
  const fetchMealUsers = makeTableDataSource<BOMealUserDto>(fetcher, 'bo/user/meal/list');
  const addMealUser = (body: BOMealUserAddRequest) => {
    return pipe(
      () => api.addBackofficeMealUser({ bOMealUserAddRequest: body }),
      T.map(mapFetcherResultEither)
    );
  };
  const editMealUser = (mealUser: BOMealUserDto, body: BOMealUserEditRequest) => {
    return pipe(
      () => api.editBackofficeMealUser({ bOMealUserEditRequest: body }, { id: mealUser.id }),
      T.map(mapFetcherResultEither)
    );
  };

  const deleteMealUser = (id: string, code: BackofficeRemoveUserReqDto) => {
    return pipe(
      () => api.deleteBackofficeMealUser({ backofficeRemoveUserReqDto: code }, { id }),
      T.map(mapFetcherResultEither)
    );
  };
  const getMealRoles = () => {
    return pipe(() => api.boUserMealRolesGet(), T.map(mapFetcherResultEither));
  };

  const viewMealUser = (userId: string) => {
    return pipe(() => api.viewBackofficeMealUser({ id: userId }), T.map(mapFetcherResultEither));
  };

  return {
    fetchMealUsers,
    addMealUser,
    editMealUser,
    deleteMealUser,
    viewMealUser,
    getMealRoles,
  };
};

export type BOMealUserService = ReturnType<typeof makeBOMealUserService>;
