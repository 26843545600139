import i18next from 'i18next';
import { format as _format } from 'date-fns';
import { ru } from 'date-fns/locale';

const locales = { ru };

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export function formatDate(date: Date | number | string, formatStr = 'PP'): string {
  if (!date) {
    return '';
  }

  const dateCandidate = typeof date === 'string' ? new Date(date) : date;

  return _format(dateCandidate, formatStr, {
    locale: (locales as any)[i18next.language],
  });
}

export const formatMoney = (amount: number, currency: string = '') => {
  return amount.toLocaleString() + (currency ? ` ${currency}` : '');
};

export const formatEmpty = (v: string) => v.slice(0, 1).replace(/\s/g, '') + v.slice(1);
