import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button } from '@pay/mui-enhancement';
import React, { FC, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { Controller, useForm } from 'react-hook-form';

import { IRemoteError } from 'modules/common/store';
import { I2FaVerificationError } from '../../constants';
import { SECOND_AUTH_CODE_ERROR_TRANS_MAP } from '../constants';
import { SecondAuthCodeField } from '../second-factor/SecondAuthCodeField';
import { secondAuthCodeValidator, useTranslation } from '../utils';

enum ECodeAuthFields {
  Code = 'code',
}
type IFormValues = Record<ECodeAuthFields, string>;

export interface ISecondAuthCodeModalProps {
  onClose: () => void;
  onSubmit: (code: string) => Promise<IRemoteError | I2FaVerificationError | undefined>; // TODO: change to unknwon
}
export const SecondAuthCodeModal: FC<ISecondAuthCodeModalProps> = ({ onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<IRemoteError | I2FaVerificationError | undefined>(undefined);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormValues>();

  const handleSubmitInternal = useCallback(
    async (values: IFormValues) => {
      setIsLoading(true);
      setError(undefined);
      const error = await onSubmit(values.code);
      ReactDOM.unstable_batchedUpdates(() => {
        setError(error);
        if (!error) {
          onClose();
        }
        setIsLoading(false);
      });
    },
    [onClose, onSubmit]
  );

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        PaperProps={{
          style: {
            width: 350,
          },
        }}
        open={true}
      >
        <DialogTitle id="confirmation-dialog-title">{t('auth_2nd_auth_code_title')}</DialogTitle>
        <DialogContent dividers>
          <Box component="form" id="CodeForm" onSubmit={handleSubmit(handleSubmitInternal)}>
            <Grow in={!!error} unmountOnExit>
              <Box mb={2}>
                <Alert severity="error">
                  <AlertTitle>{t('common_error')}</AlertTitle>
                  {error && t(SECOND_AUTH_CODE_ERROR_TRANS_MAP[error.type])}
                </Alert>
              </Box>
            </Grow>
            <Typography gutterBottom variant="body2" color="textSecondary">
              {t('auth_2nd_auth_code_description')}
            </Typography>
            <Box mt={2} textAlign="center">
              <Controller
                control={control}
                name={ECodeAuthFields.Code}
                rules={{ validate: secondAuthCodeValidator }}
                render={({ field }) => (
                  <SecondAuthCodeField disabled={isLoading} error={errors.code} {...field} />
                )}
              ></Controller>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disableElevation color="primary" variant="text" onClick={onClose}>
            {t('common_cancel')}
          </Button>
          <Button
            type="submit"
            form="CodeForm"
            loading={isLoading}
            disableElevation
            variant="contained"
            color="primary"
          >
            {t('common_ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
