import { Fetcher } from '@pay/data-fetching';

import { PreferencesModuleContext } from './module';
import { DictionariesStore } from './DictionariesStore/DictionariesStore';

export const initializePreferencesModuleContext = (fetcher: Fetcher): PreferencesModuleContext => {
  return {
    preferences: {
      store: new DictionariesStore(fetcher),
    },
  };
};
