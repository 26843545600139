import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import { Dialog, DialogProps, DialogTitle } from '@material-ui/core';

import { shouldTrans } from 'modules/common/utils';
import React, { FC, useCallback } from 'react';
import { SelectSchoolForm, SelectSchoolFormProps } from './SelectSchoolForm';
import { useNonMonetaryService } from 'modules/business-accounts/module';

type AddSchoolModalProps = Omit<DialogProps, 'children'> & {
  onClose?: () => void;
  onAdded: () => void;
  legalId: string;
};

export const AddSchoolModal: FC<AddSchoolModalProps> = (props) => {
  const { onClose, legalId, onAdded, ...dialogProps } = props;

  const { addSchool } = useNonMonetaryService();

  const handleSucessAdded = useCallback(() => {
    onAdded();
    onClose?.();
  }, [onAdded, onClose]);

  const handleAddEmployeeSubmit: SelectSchoolFormProps['onSubmit'] = (values) => {
    const bin = values.school.bin;

    return pipe(
      addSchool(legalId, bin),
      TE.chainFirstIOK(() => () => {
        handleSucessAdded();
      })
    );
  };

  return (
    <>
      <Dialog
        {...dialogProps}
        fullWidth
        maxWidth="xs"
        onClose={onClose}
        PaperProps={{
          component: 'form',
        }}
      >
        <DialogTitle>{shouldTrans('Добавить Школу')}</DialogTitle>

        <SelectSchoolForm onSubmit={handleAddEmployeeSubmit} onClose={onClose} />
      </Dialog>
    </>
  );
};
