import { LogsStore } from './data/LogsStore';
import { useGlobalContext } from '@pay/modules';

export interface LogsModuleContext {
  logs: {
    store: LogsStore;
  };
}

export const useLogsStore = () => useGlobalContext<LogsModuleContext>().logs.store;
