import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { GlobalContextProvider } from '@pay/modules';

import App from 'modules/main/App';
import { initializei18n } from 'startup/i18n';
import * as serviceWorker from './serviceWorker';
import { initializeModules } from './startup/di';

import 'startup/imports';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'mobx-react-lite/batchingForReactDom';

initializei18n();
configure({ enforceActions: 'observed' });

const globalCtx = initializeModules();

ReactDOM.render(
  <GlobalContextProvider value={globalCtx}>
    <App />
  </GlobalContextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
