import { Box } from '@material-ui/core';
import { Column, FilterType, TextFilterFormat } from '@pay/admin-data-table';
import { DetailsLinkContainer } from '@pay/admin-ui-kit';
import { ROLE_DETAILS_ENTITY_ID, USER_DETAILS_ENTITY_ID } from 'modules/user-management/constants';
import React from 'react';
import { ITransFunction } from 'startup/i18n';
import { BackofficeUserRespDto } from 'apis-generated/mapper-sso-admin';

export const getUserColumns = (t: ITransFunction): Column<BackofficeUserRespDto>[] => {
  type RolesFilterPath = [
    keyof BackofficeUserRespDto,
    keyof NonNullable<BackofficeUserRespDto['roles']>[0]
  ];
  const rolesFilterPath: RolesFilterPath = ['roles', 'name'];

  return [
    {
      accessor: 'username',
      Header: t('users_table_column_user_name') as string,
      Cell: ({ row: { original }, value }) => (
        <>
          <DetailsLinkContainer
            entityId={original.id}
            entityType={USER_DETAILS_ENTITY_ID}
            title={value}
          />
        </>
      ),
      customFilter: {
        type: FilterType.Text,
        // TODO: refactor
        field: 'userName',
        format: TextFilterFormat.Text,
      },
      sorting: {
        rsqlOptions: {
          field: 'userName',
        },
      },
    },
    {
      accessor: 'firstName',
      Header: t('users_table_column_first_name') as string,
      customFilter: {
        type: FilterType.Text,
        format: TextFilterFormat.Text,
      },
    },
    {
      accessor: 'lastName',
      Header: t('users_table_column_last_name') as string,
      customFilter: {
        type: FilterType.Text,
        format: TextFilterFormat.Text,
      },
    },
    {
      accessor: 'position',
      Header: t('users_table_column_position') as string,
      customFilter: {
        type: FilterType.Text,
        format: TextFilterFormat.Text,
      },
    },
    {
      accessor: 'roles',
      Header: t('users_table_column_role') as string,
      Cell: ({ value = [] }) => (
        <Box>
          {value.map((role, ind, arr) => (
            <React.Fragment key={role.id}>
              <DetailsLinkContainer
                entityId={role.id}
                entityType={ROLE_DETAILS_ENTITY_ID}
                title={role.name}
              />
              {ind !== arr.length - 1 && <span>, </span>}
            </React.Fragment>
          ))}
        </Box>
      ),
      customFilter: {
        type: FilterType.Text,
        field: rolesFilterPath.join('.') as Extract<keyof BackofficeUserRespDto, string>,
        format: TextFilterFormat.Text,
      },
    },
    {
      accessor: 'email',
      Header: t('users_table_column_email') as string,
      Cell: ({ row: { original }, value }) => <>{value}</>,
      customFilter: {
        type: FilterType.Text,
        format: TextFilterFormat.Text,
      },
    },
    // {
    //   Header: t('user_field_status'),
    //   horizontalAlign: 'center',
    //   sortable: false,
    //   Cell: ({ row: { original } }: CellProps<BackofficeUserRespDto>) => (
    //     <>
    //       {original.deleted ? (
    //         <BadgeCell color="error">{t('user_status_deleted')}</BadgeCell>
    //       ) : original.locked ? (
    //         <Tooltip
    //           title={t('user_locked_tooltip', {
    //             reason: original.lockReason!,
    //             date: formatDate(new Date(original.lockDate!), DATE_FORMAT.DD_MM_YYYY_HH_mm),
    //             user: original.lockedBy?.name || 'N/A',
    //           })}
    //         >
    //           <BadgeCell color="default">
    //             {t('user_status_locked')}
    //             <HelpOutline style={{ fontSize: '16px', marginLeft: 2 }} />
    //           </BadgeCell>
    //         </Tooltip>
    //       ) : (
    //         <BadgeCell color="success">{t('user_status_active')}</BadgeCell>
    //       )}
    //     </>
    //   ),
    // },
  ];
};
