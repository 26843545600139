import { ITransKey } from 'startup/i18n';
import { EPermissionType } from '../../../constants';

export enum EPermissionGroup {
  Dash = 'dash',
  Users = 'users',
  Roles = 'roles',
  Reports = 'reports',
  Organizations = 'organizations',
  Audit = 'audit',
  Apk = 'apk',
  Legals = 'legals',
  Agreement = 'agreement',
  Sms = 'sms',
  PrivacyPolicy = 'privacyPolicy',
  Blackbox = 'blackbox',
  Workspace = 'workspace',
  Coid = 'coid',
}

export const PERMISSION_GROUPS: Record<EPermissionGroup, EPermissionType[]> = {
  [EPermissionGroup.Dash]: [EPermissionType.DashboardRead],
  [EPermissionGroup.Users]: [
    EPermissionType.UsersRead,
    EPermissionType.UsersWrite,
    EPermissionType.UsersPassword,
    EPermissionType.UsersAccess,
  ],
  [EPermissionGroup.Roles]: [EPermissionType.RolesRead, EPermissionType.RolesWrite],
  [EPermissionGroup.Reports]: [
    EPermissionType.ReportsRead,
    EPermissionType.ReportsWrite,
    EPermissionType.ReportsDownload,
    EPermissionType.ServiceMealReports,
  ],
  [EPermissionGroup.Organizations]: [
    EPermissionType.FinancialOrganizationRead,
    EPermissionType.FinancialOrganizationWrite,
  ],
  [EPermissionGroup.Audit]: [EPermissionType.LogRead],
  [EPermissionGroup.Apk]: [EPermissionType.ApkRead, EPermissionType.ApkWrite],
  [EPermissionGroup.Legals]: [EPermissionType.LegalRead, EPermissionType.LegalWrite],
  [EPermissionGroup.Agreement]: [EPermissionType.AgreementRead, EPermissionType.AgreementWrite],
  [EPermissionGroup.Sms]: [EPermissionType.SmsRead, EPermissionType.SmsWrite],
  [EPermissionGroup.PrivacyPolicy]: [
    EPermissionType.PrivacyPolicyRead,
    EPermissionType.PrivacyPolicyWrite,
  ],
  [EPermissionGroup.Blackbox]: [EPermissionType.BlackboxRead, EPermissionType.BlackboxWrite],
  [EPermissionGroup.Workspace]: [EPermissionType.WorkspaceRead, EPermissionType.WorkspaceWrite],
  [EPermissionGroup.Coid]: [EPermissionType.CoidRead],
};

export const PERMISSION_GROUPS_TRANS_MAP: Record<EPermissionGroup, ITransKey> = {
  [EPermissionGroup.Users]: 'permission_group_users',
  [EPermissionGroup.Roles]: 'permission_group_roles',
  [EPermissionGroup.Reports]: 'permission_group_reports',
  [EPermissionGroup.Organizations]: 'permission_group_organizations',
  [EPermissionGroup.Dash]: 'permission_group_dash',
  [EPermissionGroup.Audit]: 'permission_group_audit',
  [EPermissionGroup.Apk]: 'permission_group_apk',
  [EPermissionGroup.Legals]: 'permission_group_legals',
  [EPermissionGroup.Agreement]: 'permission_group_agreement',
  [EPermissionGroup.Sms]: 'permission_group_sms',
  [EPermissionGroup.PrivacyPolicy]: 'permission_group_privacy_policy',
  [EPermissionGroup.Blackbox]: 'permission_group_blackbox',
  [EPermissionGroup.Workspace]: 'permission_group_workspace',
  [EPermissionGroup.Coid]: 'permission_group_coid',
};
