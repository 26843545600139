import { BackofficeUserRespDto } from 'apis-generated/mapper-sso-admin';
import { uniq } from 'lodash-es';
import { EPermissionType } from 'modules/user-management';
import { CurrentUserModel } from '../data/CurrentUserModel';

export const mapToCurrentUserModel = (userDto: BackofficeUserRespDto): CurrentUserModel => {
  const currentUser = new CurrentUserModel();
  currentUser.username = userDto.username;
  currentUser.firstName = userDto.firstName ?? '';
  currentUser.lastName = userDto.lastName ?? '';
  currentUser.roles.push(...(userDto.roles ?? []));
  currentUser.permissions = uniq(
    userDto.roles
      ?.flatMap((role) => role.permissions)
      .filter((perm) => !!perm)
      .map((perm) => perm!.name as EPermissionType) ?? []
  );

  return currentUser;
};
