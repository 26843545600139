import { makeTableDataSource } from '@pay/admin-resources';
import { IFetcher } from '@pay/data-fetching';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';

import {
  DownloadPrivacyPolicyTypeEnum,
  PrivacyPolicyApiInterface,
  PrivacyPolicyHistoryRespDto,
  PrivacyPolicyUpdateScheduleRespDto,
  UpdatePrivacyPolicyRequest,
} from 'apis-generated/mapper-sso-admin';
import { constUndefined, pipe } from 'fp-ts/lib/function';
import { mapFetcherResultEither } from 'modules/common/fetcher';
import { EApiLang } from 'startup/i18n';

export class PrivacyPolicyService {
  constructor(private _privacyPolicyApi: PrivacyPolicyApiInterface, private _fetcher: IFetcher) {}

  public updatePrivacyPolicy(req: UpdatePrivacyPolicyRequest) {
    return pipe(
      () => this._privacyPolicyApi.updatePrivacyPolicy({ updatePrivacyPolicyRequest: req }),
      T.map(mapFetcherResultEither),
      TE.map(constUndefined)
    );
  }

  public downloadPrivacyPolicy(id: string, type: DownloadPrivacyPolicyTypeEnum, lang: EApiLang) {
    return pipe(
      () => {
        return this._fetcher.get<Blob>(`bo/privacy-policy/download/${type}/${id}`, {
          headers: { 'X-Locale': lang },
        });
      },
      T.map((result) => mapFetcherResultEither(result))
    );
  }

  public fetchPrivacyPolicyHistory = makeTableDataSource<PrivacyPolicyHistoryRespDto>(
    this._fetcher,
    'bo/privacy-policy/history'
  );

  public fetchPrivacyPolicyChangeRequests = makeTableDataSource<PrivacyPolicyUpdateScheduleRespDto>(
    this._fetcher,
    'bo/privacy-policy/update-schedule'
  );
}
