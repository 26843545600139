import { AuthResponse } from 'apis-generated/mapper-sso-admin';
import { CurrentUserModel } from '../data';

export enum InitialStage {
  Login = 'log',
  SecondFactor = '2auth',
  NewPassword = 'new-password',
}

export type LoginStageContext = {
  stage: InitialStage.Login;
};

export type SecondAuthStageContext = {
  stage: InitialStage.SecondFactor;
  authResponse: AuthResponse;
  password: string;
};

export type SetNewPasswordContext = {
  stage: InitialStage.NewPassword;
  user: CurrentUserModel;
  password: string;
};

type ExpiredSession =
  | { fromExpiredSession: true; currentUser: CurrentUserModel }
  | { fromExpiredSession: false };

export enum LogoutReason {
  SessionExpiredOrInvalidToken = 'SessionExpiredOrInvalidToken',
  NotEnoughPermissions = 'NotEnoughPermissions',
}

export type Initial = {
  type: 'Initial';
  stageContext: LoginStageContext | SecondAuthStageContext;
  logOutReason?: LogoutReason;
  returnUrl?: string;
} & ExpiredSession;

export type LoggedIn = {
  type: 'LoggedIn';
  currentUser: CurrentUserModel;
};

export type AuthState = Initial | LoggedIn;

export type T = AuthState;

export const initialize = (): Initial => ({
  type: 'Initial',
  stageContext: {
    stage: InitialStage.Login,
  },
  fromExpiredSession: false,
});

export const expireSession = (currentUser: CurrentUserModel): Initial => ({
  type: 'Initial',
  stageContext: {
    stage: InitialStage.Login,
  },
  fromExpiredSession: true,
  currentUser,
});

export const handleLoggedIn = (currentUser: CurrentUserModel): LoggedIn => ({
  type: 'LoggedIn',
  currentUser,
});

export const isInitial = (state: AuthState): state is Initial => state.type === 'Initial';
export const isLoggedIn = (state: AuthState): state is LoggedIn => state.type === 'LoggedIn';

export const isSessionExpired = (state: AuthState): state is Initial =>
  state.type === 'Initial' && state.fromExpiredSession;
