import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { MuiRouteLink } from '@pay/admin-ui-kit';
import { delay } from '@pay/common-utils';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    background: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    textAlign: 'center',
  },
}));

interface IProps {
  title: string;
  description?: string;
  shouldReload?: boolean;
}
export const ErrorScreen: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { description, title, shouldReload } = props;
  const { t } = useTranslation();

  const handleClick = useCallback(async () => {
    if (shouldReload) {
      await delay(100);
      window.location.reload();
    }
  }, [shouldReload]);

  return (
    <Box className={classes.root}>
      <div className={classes.container}>
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
        {description && <Typography gutterBottom>{description}</Typography>}
        <Button
          onClick={handleClick}
          {...({ component: MuiRouteLink, to: '/' } as any)}
          variant="contained"
        >
          {t('back_to_home')}
        </Button>
      </div>
    </Box>
  );
};
