import { DataTable, TableToolbar, useTable } from '@pay/admin-data-table';
import { Page, PageTitle, SimplePopup, useModals, useNotificationsStore } from '@pay/admin-ui-kit';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'startup/utils';
import { BlackboxData } from 'apis-generated/mapper-processing-admin';
import { Button } from '@material-ui/core';
import { useSchoolService, useSchoolEvents, useSchoolStore } from '../module';
import { CreateSchoolModal } from './CreateSchool';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import { SchoolViewDTO } from 'apis-generated/school-meal';
import { useSchoolColumns } from './tableDefs';

interface IProps {}

export const SchoolsPage: FC<IProps> = () => {
  const { t } = useTranslation();
  const { fetchTable, deleteSchool } = useSchoolService();
  const { onRefreshTable$ } = useSchoolEvents();
  const { showNotification } = useNotificationsStore();
  const store = useSchoolStore();

  const { showModal } = useModals();

  const handleDeleteClick = useCallback(
    (school: SchoolViewDTO) => {
      const title = (school.number && `№${school.number}`) ?? school.name ?? '';
      showModal((close) => (
        <SimplePopup
          title={t('meal_school_delete')}
          onOk={() => {
            return pipe(
              deleteSchool(school.bin),
              TE.match(
                () => {
                  showNotification({
                    text: t('meal_school_delete_failured', { title }),
                    options: { variant: 'error' },
                  });
                  close();
                },
                () => {
                  showNotification({
                    text: t('meal_school_delete_successed', { title }),
                    options: { variant: 'success' },
                  });
                  refresh();
                  close();
                }
              )
            )();
          }}
          okLabel={t('common_delete')}
          content={t('meal_school_delete_ask', { title })}
        />
      ));
    },
    [deleteSchool, showModal, showNotification, t]
  );

  const handleEditClick = useCallback(
    (school: SchoolViewDTO) => {
      showModal(() => <CreateSchoolModal onSuccess={refresh} open={true} school={school} />);
    },
    [showModal]
  );

  const columns = useSchoolColumns(t, handleEditClick, handleDeleteClick);

  const { tableProps, toolBarProps, refresh } = useTable({
    fetchData: store.loadItems,
    pageSize: 20,
    columns,
    keyGetter: (it) => it.bin,
  });

  const handleAddClick = () => {
    showModal(() => <CreateSchoolModal onSuccess={refresh} open={true} />);
  };

  return (
    <Page>
      <PageTitle>{t('meal_schools')}</PageTitle>
      <TableToolbar<BlackboxData> {...toolBarProps} columns={columns}>
        <Button variant="contained" color="primary" onClick={handleAddClick}>
          {t('meal_school_add')}
        </Button>
      </TableToolbar>
      <DataTable {...tableProps} />
    </Page>
  );
};
