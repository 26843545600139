import { Fetcher } from '@pay/data-fetching';
import { ReportModuleContext } from './module';
import { makeReportApi } from './api';
import { makeCompanyServiceApi } from './companyServiceApi';
import { makeReportService } from './services/ReportService';
import { makeComapnyService } from './services/CompanyService';

export { StatReportPage } from './ui/StatReportPage';
export { MealVoucherReportPage } from './ui/MealVoucherReportPage';
export { ReportsPage } from './ui/ReportsPage';

export { useReportService } from './module';

export const initializeReportModuleContext = (fetcher: Fetcher): ReportModuleContext => {
  const reportApi = makeReportApi(fetcher);
  const companyServiceApi = makeCompanyServiceApi(fetcher);

  const reportService = makeReportService(reportApi);
  const companyService = makeComapnyService(companyServiceApi);
  return {
    reports: {
      reportService,
      companyService,
    },
  };
};
