export const API_HOST = window.API_HOST || 'http://localhost:8082';
export const GRAFANA_API_KEY =
  'eyJrIjoidHNwVzZVM29BanFFS3RBaklJTWRZWFNzYmo2Q3JwYXMiLCJuIjoiYWRtaW4iLCJpZCI6MX0=';
export const IS_DEV =
  API_HOST.startsWith('http://localhost') ||
  API_HOST.startsWith('https://dev.') ||
  API_HOST.startsWith('https://dev-') ||
  API_HOST.startsWith('https://api.dev.') ||
  API_HOST.startsWith('http://192.168') ||
  API_HOST.startsWith('http://10.0.2.2:8082');
