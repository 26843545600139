/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface BOReportRequest
 */
export interface BOReportRequest {
    /**
     * 
     * @type {string}
     * @memberof BOReportRequest
     */
    searchType?: BOReportRequestSearchTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof BOReportRequest
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BOReportRequest
     */
    dateTo?: Date;
    /**
     * 
     * @type {number}
     * @memberof BOReportRequest
     */
    serviceCompanyId?: number;
    /**
     * 
     * @type {string}
     * @memberof BOReportRequest
     */
    childIin?: string;
    /**
     * 
     * @type {string}
     * @memberof BOReportRequest
     */
    school?: string;
    /**
     * 
     * @type {string}
     * @memberof BOReportRequest
     */
    mealType?: BOReportRequestMealTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BOReportRequest
     */
    locationCode?: string;
}

/**
* @export
* @enum {string}
*/
export enum BOReportRequestSearchTypeEnum {
    School = 'SCHOOL',
    Student = 'STUDENT'
}
/**
* @export
* @enum {string}
*/
export enum BOReportRequestMealTypeEnum {
    Lunch = 'LUNCH',
    Breakfast = 'BREAKFAST'
}


