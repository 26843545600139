import React, { useState, useMemo, useContext, useCallback } from 'react';
import {
  ISecondAuthCodeModalProps as ISecondAuthCodeModalPropsOrig,
  SecondAuthCodeModal,
} from './SecondAuthModal/SecondAuthCodeModal';

export type ISecondAuthCodeModalProps = Omit<ISecondAuthCodeModalPropsOrig, 'onClose'>;

interface IAuthModalContext {
  showSecondAuthModal: (options: ISecondAuthCodeModalProps) => void;
}

const AuthContext = React.createContext<IAuthModalContext>({
  showSecondAuthModal: () => Promise.resolve(),
});

export const AuthModalsProvider: React.FC = (props) => {
  const [currentModal, setCurrentModal] = useState<ISecondAuthCodeModalProps | undefined>(
    undefined
  );

  const contextValue: IAuthModalContext = useMemo(
    () => ({
      showSecondAuthModal: (props) => {
        setCurrentModal(props);
      },
    }),
    []
  );

  const handleClose = useCallback(() => {
    setCurrentModal(undefined);
  }, []);

  return (
    <>
      <AuthContext.Provider value={contextValue}>
        {props.children}
        {currentModal && (
          <SecondAuthCodeModal onClose={handleClose} onSubmit={currentModal.onSubmit} />
        )}
      </AuthContext.Provider>
    </>
  );
};

export const useAuthModals = () => {
  const { showSecondAuthModal } = useContext(AuthContext);

  return {
    showSecondAuthModal,
  };
};
