import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline, makeStyles, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DetailsContainer, ModalProvider, NotificationsProvider } from '@pay/admin-ui-kit';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { useUiPrefencesStore } from 'modules/common';
import { AuthModalsProvider, AuthPage, useAuthStore } from 'modules/auth';
import { Page404 } from 'modules/main/error/404Page';
import { LayoutContainer } from 'modules/main/Layout';
import { browserHistory } from 'startup/di';
import { DATE_FNS_LOCALE_MAP } from 'startup/i18n';
import { muiDarkTheme, muiLightTheme } from '../../startup/theme';
import { DETAILS_MAP } from './details';
import { ErrorBound } from './ErrorBound';
import { getFirstAvailableRoute, NAVIGATION_ROUTES } from './routes';
import { PrivateRoute } from './routing/PrivateRoute';
import { AuthExpiredSessionModal } from 'modules/auth/ui/expired-session/AuthExpiredSessionModal';

interface IProps {}

const AppContainerBase: React.FC<IProps> = (props) => {
  const authStore = useAuthStore();
  const { isLoggedIn, currentUser } = authStore;

  useEffect(() => {
    const globalLoader = document.getElementById('global-loader');
    if (globalLoader) {
      globalLoader.style.transition = 'all .2s ease-in';
      globalLoader.style.opacity = '0';
      setTimeout(() => globalLoader.remove(), 200);
    }
  }, []);

  const firstAvailableRoute = useMemo(() => getFirstAvailableRoute(currentUser), [currentUser]);

  const appRoutes = authStore.currentUser ? (
    <LayoutContainer>
      <Switch>
        {NAVIGATION_ROUTES.map((route) => {
          const { permissions, component: Cmp, ...otherProps } = route;
          return (
            <PrivateRoute
              key={route.path as string}
              {...otherProps}
              permissions={route.permissions}
              render={(props) => <Cmp {...props} />}
            />
          );
        })}
        <Route path="/" exact>
          <Redirect to={firstAvailableRoute.path} />
        </Route>
        <Route>
          <Redirect to="/not-found" />
        </Route>
      </Switch>
      <DetailsContainer elementMap={DETAILS_MAP} />
    </LayoutContainer>
  ) : null;

  return (
    <>
      <Switch>
        <Route exact path="/auth">
          {isLoggedIn ? (
            <Redirect to={authStore.initialState?.returnUrl || '/'} />
          ) : (
            <AuthPage backgroundUrl="/images/auth-bg.jpg" />
          )}
        </Route>
        <Route path="/not-found" exact component={Page404} />
        <PrivateRoute path="/">{appRoutes}</PrivateRoute>
      </Switch>
      <AuthExpiredSessionModal />
    </>
  );
};
const AppContainer = observer(AppContainerBase);

const useStyles = makeStyles((theme) => ({}));

const App = observer(() => {
  const uiPreferencesStore = useUiPrefencesStore();

  const classes = useStyles();

  return (
    <MuiThemeProvider theme={uiPreferencesStore.theme === 'light' ? muiLightTheme : muiDarkTheme}>
      <ModalProvider>
        <AuthModalsProvider>
          <Router history={browserHistory}>
            <NotificationsProvider>
              <MuiPickersUtilsProvider
                locale={DATE_FNS_LOCALE_MAP[uiPreferencesStore.lang]}
                utils={DateFnsUtils}
              >
                <ModalProvider>
                  <CssBaseline />
                  <ErrorBound>
                    <AppContainer />
                  </ErrorBound>
                </ModalProvider>
              </MuiPickersUtilsProvider>
            </NotificationsProvider>
          </Router>
        </AuthModalsProvider>
      </ModalProvider>
    </MuiThemeProvider>
  );
});

export default App;
