/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { IFetcherResult, IRequestInit, Fetcher } from '@pay/data-fetching';
import {
  CommonResponseWrapperAgreementHistoryRespDto,
  CommonResponseWrapperAgreementUpdateScheduleRespDto,
  UpdateAgreementRequest,
} from '../models';

export interface AgreementHistoryQueryParams {
  search?: string;
}

export interface AgreementLegalHistoryQueryParams {
  search?: string;
}

export interface AgreementUpdateScheduleHistoryQueryParams {
  search?: string;
}

export interface DownloadAgreementPathParams {
  id: string;
  type: DownloadAgreementTypeEnum;
}

export interface DownloadLegalAgreementPathParams {
  id: string;
  type: DownloadAgreementTypeEnum;
}

export interface LegalAgreementUpdateScheduleHistoryQueryParams {
  search?: string;
}

export interface UpdateAgreementOperationRequestBody {
  updateAgreementRequest?: UpdateAgreementRequest;
}

export interface UpdateLegalAgreementRequestBody {
  updateAgreementRequest?: UpdateAgreementRequest;
}

/**
 * AgreementApi - interface
 *
 * @export
 * @interface AgreementApiInterface
 */
export interface AgreementApiInterface {
  /**
   *
   * @summary Исторические данные офферты ФЛ
   * @param {string} [search]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgreementApiInterface
   * URL: /bo/agreement/history
   * METHOD: GET
   */
  agreementHistory(
    queryParams: AgreementHistoryQueryParams
  ): Promise<IFetcherResult<CommonResponseWrapperAgreementHistoryRespDto>>;
  /**
   *
   * @summary Исторические данные офферты ЮЛ
   * @param {string} [search]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgreementApiInterface
   * URL: /bo/legalagreement/history
   * METHOD: GET
   */
  agreementLegalHistory(
    queryParams: AgreementLegalHistoryQueryParams
  ): Promise<IFetcherResult<CommonResponseWrapperAgreementHistoryRespDto>>;
  /**
   *
   * @summary График обновлений офферты ФЛ
   * @param {string} [search]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgreementApiInterface
   * URL: /bo/agreement/update-schedule
   * METHOD: GET
   */
  agreementUpdateScheduleHistory(
    queryParams: AgreementUpdateScheduleHistoryQueryParams
  ): Promise<IFetcherResult<CommonResponseWrapperAgreementUpdateScheduleRespDto>>;
  /**
   *
   * @summary Скачать оффеерту ФЛ
   * @param {string} id
   * @param {'DOCUMENT' | 'ACTIVATION'} type
   * @param {string} [xLocale]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgreementApiInterface
   * URL: /bo/agreement/download/{type}/{id}
   * METHOD: GET
   */
  downloadAgreement(pathParams: DownloadAgreementPathParams): Promise<IFetcherResult<void>>;
  /**
   *
   * @summary Скачать оффеерту ЮЛ
   * @param {string} id
   * @param {'DOCUMENT' | 'ACTIVATION'} type
   * @param {string} [xLocale]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgreementApiInterface
   * URL: /bo/leaglagreement/download/{type}/{id}
   * METHOD: GET
   */
  downloadLegalAgreement(
    pathParams: DownloadLegalAgreementPathParams
  ): Promise<IFetcherResult<void>>;
  /**
   *
   * @summary График обновлений офферты ЮЛ
   * @param {string} [search]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgreementApiInterface
   * URL: /bo/legalagreement/update-schedule
   * METHOD: GET
   */
  legalAgreementUpdateScheduleHistory(
    queryParams: LegalAgreementUpdateScheduleHistoryQueryParams
  ): Promise<IFetcherResult<CommonResponseWrapperAgreementUpdateScheduleRespDto>>;
  /**
   *
   * @summary Обновить офферту для ФЛ
   * @param {UpdateAgreementRequest} [updateAgreementRequest] Контент офферты ФЛ
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgreementApiInterface
   * URL: /bo/agreement
   * METHOD: POST
   */
  updateAgreement(
    requestParameters: UpdateAgreementOperationRequestBody
  ): Promise<IFetcherResult<void>>;
  /**
   *
   * @summary Обновить офферту для ЮЛ
   * @param {UpdateAgreementRequest} [updateAgreementRequest] Контент офферты ЮЛ
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgreementApiInterface
   * URL: /bo/legalagreement
   * METHOD: POST
   */
  updateLegalAgreement(
    requestParameters: UpdateLegalAgreementRequestBody
  ): Promise<IFetcherResult<void>>;
}

/**
 * @export
 * @enum {string}
 */
export enum DownloadAgreementTypeEnum {
  Document = 'DOCUMENT',
  Activation = 'ACTIVATION',
}
/**
 * @export
 * @enum {string}
 */
export enum DownloadLegalAgreementTypeEnum {
  Document = 'DOCUMENT',
  Activation = 'ACTIVATION',
}
