import * as AuthState from './core/AuthState';

export enum HttpLogoutReasonCode {
  Unauthorized = 401,
  // Forbidden = 403,
}

export const HTTP_LOGOUT_REASON_MAP: Record<HttpLogoutReasonCode, AuthState.LogoutReason> = {
  [HttpLogoutReasonCode.Unauthorized]: AuthState.LogoutReason.SessionExpiredOrInvalidToken,
  // [HttpLogoutReasonCode.Forbidden]: AuthState.LogoutReason.NotEnoughPermissions,
};

export type IAuthLoginError = {
  type: 'InvalidCredentials';
};

export enum EAuthCodeError {
  WrongCode = '2nd-factor-wrong-code',
}

// TODO: Get rid of enum!
export interface I2FaVerificationError {
  type: EAuthCodeError.WrongCode;
}
