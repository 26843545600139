import { DashboardService } from './service/DashboardService';
import { useGlobalContext } from '@pay/modules';

export interface DashboardModuleContext {
  dashboard: {
    service: DashboardService;
  };
}

export const useDashboardService = () =>
  useGlobalContext<DashboardModuleContext>().dashboard.service;
