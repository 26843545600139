import React from 'react';
import { makeStyles, Paper, Box, Avatar, Typography } from '@material-ui/core';
import { PersonOutline } from '@material-ui/icons';
import { CurrentUserModel } from '../../data';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 100,
    height: 100,
  },
}));

interface IProps {
  user: CurrentUserModel;
}
export const ProfileCard: React.FC<IProps> = (props) => {
  const { user } = props;
  const classes = useStyles();
  return (
    <>
      <Paper style={{ width: 360 }}>
        <Box
          p={4}
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <Box mb={2}>
            <Avatar className={classes.avatar}>
              <PersonOutline style={{ width: '80%', height: '80%' }} />
            </Avatar>
          </Box>
          <Typography gutterBottom variant="h5">
            {user.fullName}
          </Typography>
          <Typography gutterBottom color="textSecondary">
            {user.username}
          </Typography>
        </Box>
      </Paper>
    </>
  );
};
