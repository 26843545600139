import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { DataTable, TableToolbar, useTable } from '@pay/admin-data-table';

import { WithPermissions } from 'modules/auth';
import { EPermissionType } from 'modules/user-management';

import { BOMealUserDto, BackofficeUserRespDto } from 'apis-generated/mapper-sso-admin';
import { getUserColumns } from './tableDefs';
import { useBOMealUserService, useMealsEvents } from '../module';
import { MEAL_ADMIN_DETAILS_ENTITY_ID } from '../constants';
import { useDetailsStore } from 'modules/common/details';
import { useSubscription } from 'utils';

export interface IProps {}

export const MealAdminPane: FC<IProps> = () => {
  const { t } = useTranslation();
  const boMealUserService = useBOMealUserService();
  const detailsStore = useDetailsStore();
  const { onRefreshTable$ } = useMealsEvents();

  const columns = useMemo(() => getUserColumns(t), [t]);
  const { tableProps, toolBarProps, refreshIfContainsId, refresh } = useTable({
    columns: columns,
    keyGetter: (it) => it.id,
    fetchData: boMealUserService.fetchMealUsers,
  });

  const handleAddMealAdminClick = useCallback(() => {
    detailsStore.openNewDetails(MEAL_ADMIN_DETAILS_ENTITY_ID);
  }, [detailsStore]);

  useSubscription(
    onRefreshTable$,
    useCallback(
      ({ itemId }) => {
        if (itemId) {
          refreshIfContainsId([String(itemId)]);
        } else {
          refresh();
        }
      },
      [refresh, refreshIfContainsId]
    )
  );

  return (
    <>
      <TableToolbar<BackofficeUserRespDto> {...toolBarProps} columns={columns}>
        <WithPermissions allow={[EPermissionType.Superadmin, EPermissionType.ServiceMealAdmin]}>
          <Button
            size="small"
            disableElevation
            variant="contained"
            color="primary"
            onClick={handleAddMealAdminClick}
          >
            {t('meal_page_action_new_user')}
          </Button>
        </WithPermissions>
      </TableToolbar>
      <DataTable<BOMealUserDto> {...tableProps} />
    </>
  );
};
