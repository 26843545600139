import { makeClientApi, makeClientControlApi } from './api/ClientApi';
import { ClientService } from './service/ClientService';
import { ClientModuleContext } from './module';
import { ClientsApi } from './api/ClientsApi';
import { MockClientWsApi } from './api/ClientAuditApi';
import { ClientsStore } from './data/ClientsStore';
import { Fetcher } from '@pay/data-fetching';
import { WorkspaceService } from './service/WorkspaceService';
import { makeWorkspaceApi } from '../../api';

export { ClientsPage } from './ui/ClientsPage';
export { getClientsColumns } from './ui/tableDefs';
export type { IClientsApi } from './api/ClientsApi';
export { useClientsStore } from './data';
export { CLIENT_DETAIL_ENTITY_ID } from './constants';
export { ClientDetails } from './ui/details';

export const initializeClientsModuleContext = (
  ssoFetcher: Fetcher,
  mapperFetcher: Fetcher
): ClientModuleContext => {
  const clientApi = makeClientApi(ssoFetcher);
  const clientControlApi = makeClientControlApi(mapperFetcher);
  const workspaceApi = makeWorkspaceApi(mapperFetcher);

  const clientService = new ClientService(clientApi, clientControlApi, ssoFetcher);
  const workspaceService = new WorkspaceService(workspaceApi);

  return {
    client: {
      store: new ClientsStore(new ClientsApi('bo/client', ssoFetcher), new MockClientWsApi()),
      clientService,
      workspaceService,
    },
  };
};
