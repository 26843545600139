import { IFetcher } from '@pay/data-fetching';

import { PrivacyPolicyModuleContext } from './module';
import { makePrivacyPolicyApi } from '../../api';
import { PrivacyPolicyService } from './service/PrivacyPolicyService';

export { NewPrivacyPolicyModal } from './ui/NewPrivacyPolicyModal';
export { PrivacyPolicyPage } from './ui/PrivacyPolicyPage';

export const initializePrivacyPolicyModuleContext = (
  ssoFetcher: IFetcher
): PrivacyPolicyModuleContext => {
  const privacyPolicyApi = makePrivacyPolicyApi(ssoFetcher);

  const service = new PrivacyPolicyService(privacyPolicyApi, ssoFetcher);
  return {
    privacyPolicy: {
      service,
    },
  };
};
