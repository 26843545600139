import { DashboardModuleContext } from './module';
import { IFetcher } from '@pay/data-fetching/dist/Fetcher/IFetcher';
import { makeDashboardApi } from '../../api';
import { DashboardService } from './service/DashboardService';

export { DashboardPage } from './ui/DashboardPage';
export { DashboardApi } from './api/DashboardApi';

export const initializeDashboardModuleContext = (
  mapperFetcher: IFetcher
): DashboardModuleContext => {
  const dashboardApi = makeDashboardApi(mapperFetcher);
  const service = new DashboardService(dashboardApi);
  return {
    dashboard: {
      service,
    },
  };
};
