import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';

import { mapFetcherResultEither } from 'modules/common/fetcher';
import {
  BackofficeTestUserRespDto,
  BoTestUserControllerApiInterface,
} from 'apis-generated/mapper-sso-admin';
import { makeTableDataSource } from '@pay/admin-resources';
import { IFetcher } from '@pay/data-fetching';

export const makeTestClientsService = (
  fetcher: IFetcher,
  api: BoTestUserControllerApiInterface
) => {
  return {
    fetchTestClientsTable: makeTableDataSource<BackofficeTestUserRespDto>(fetcher, 'bo/testclient'),
    addTestClient: () => pipe(api.addTestClient, T.map(mapFetcherResultEither)),
    deleteTestClient: (id: string) =>
      pipe(() => api.removeTestClient({ id }), T.map(mapFetcherResultEither)),
  };
};

export type TestClientService = ReturnType<typeof makeTestClientsService>;
