import React, { FC } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  YAxis,
  Legend,
  Brush,
  ReferenceLine,
} from 'recharts';
import { makeStyles, Paper, Typography, useTheme } from '@material-ui/core';

import { IDataEntry } from '../service/DashboardService';
import { useTranslation } from 'startup/utils';

export const FIAvailabilityChart: FC<{ finOrgs: IDataEntry[] }> = ({ finOrgs }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ResponsiveContainer height={360}>
      <BarChart
        data={finOrgs}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dateTime" />
        <YAxis hide={true} />
        <Tooltip content={<CustomTooltip />} labelStyle={{ color: theme.palette.common.black }} />
        <Legend verticalAlign="bottom" wrapperStyle={{ lineHeight: '40px' }} />
        <ReferenceLine y={0} stroke={theme.palette.common.black} />
        <Brush dataKey="dateTime" height={30} stroke={theme.palette.primary.main} />
        <Bar
          dataKey="available"
          name={t('dashboard_fin_org_available')}
          fill={theme.palette.success.main}
          stackId="stack"
        />
        <Bar
          dataKey="notAvailable"
          name={t('dashboard_fin_org_not_available')}
          fill={theme.palette.error.main}
          stackId="stack"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  label: {
    color: theme.palette.common.black,
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    return (
      <Paper className={classes.wrapper}>
        <Typography className={classes.label}>{t('dashboard_tooltip_text', { label })}</Typography>
      </Paper>
    );
  }
  return null;
};
