import { Grid, makeStyles } from '@material-ui/core';
import { CurrentUserModel, ProfileCard } from '../../../../auth';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 100,
    height: 100,
  },
}));

interface IProps {
  user: CurrentUserModel;
}
export const GeneralSettings: React.FC<IProps> = (props) => {
  const { user } = props;
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <ProfileCard user={user} />
        </Grid>
      </Grid>
    </>
  );
};
