import { OrganizationsService } from './service/OrganizationsService';
import { FinOrganizationsStore } from './data/FinOrganizationsStore';
import { useGlobalContext } from '@pay/modules';

export interface FinOrganizationsModuleContext {
  finOrganizations: {
    service: OrganizationsService;
    store: FinOrganizationsStore;
  };
}

export const useFinOrganizationsService = () =>
  useGlobalContext<FinOrganizationsModuleContext>().finOrganizations.service;
export const useFinOrganizationsStore = () =>
  useGlobalContext<FinOrganizationsModuleContext>().finOrganizations.store;
