import { makeStyles, Tab } from '@material-ui/core';
import { Page, PageTitle, Tabs } from '@pay/admin-ui-kit';
import React, { FC, useState } from 'react';

import { useTranslation } from 'startup/utils';
import { PrivacyPolicyHistoryPane } from './history/PrivacyPolicyHistoryPane';
import { PrivacyPolicyChangeRequestsPane } from './changeRequests/PrivacyPolicyChangeRequestsPane';

const useStyles = makeStyles((theme) => ({
  root: {},
  tabsRoot: {
    padding: `0 ${theme.spacing(6)}px`,
    marginBottom: theme.spacing(2),
    flex: 0,
  },
}));

export enum ETab {
  History = '',
  ChangeRequests = 'changeRequests',
}

interface IProps {}

export const PrivacyPolicyPage: FC<IProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(ETab.History);

  const handleChange = (event: React.ChangeEvent<{}>, tab: ETab) => {
    setTab(tab);
  };

  const renderTabContent = () => {
    return (
      <>
        <Page>
          <PageTitle>{t('privacy_policy_page_title')}</PageTitle>
          <Tabs
            value={tab}
            indicatorColor="primary"
            onChange={handleChange}
            textColor="primary"
            variant="standard"
            classes={{ root: classes.tabsRoot }}
          >
            <Tab value={ETab.History} label={t('privacy_policy_tab_history')} />
            <Tab value={ETab.ChangeRequests} label={t('privacy_policy_tab_change_requests')} />
          </Tabs>
          {tab === ETab.History && <PrivacyPolicyHistoryPane />}
          {tab === ETab.ChangeRequests && <PrivacyPolicyChangeRequestsPane />}
        </Page>
      </>
    );
  };

  return <>{renderTabContent()}</>;
};
