/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    MerchantCategoryCode,
} from './';


/**
 * 
 * @export
 * @interface LegalWorkspace
 */
export interface LegalWorkspace {
    /**
     * 
     * @type {string}
     * @memberof LegalWorkspace
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalWorkspace
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LegalWorkspace
     */
    bin: string;
    /**
     * 
     * @type {string}
     * @memberof LegalWorkspace
     */
    type: LegalWorkspaceTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LegalWorkspace
     */
    active?: boolean;
    /**
     * 
     * @type {MerchantCategoryCode}
     * @memberof LegalWorkspace
     */
    mcc?: MerchantCategoryCode;
}

/**
* @export
* @enum {string}
*/
export enum LegalWorkspaceTypeEnum {
    Legal = 'LEGAL'
}


