import { Box, Grid } from '@material-ui/core';
import { ChangePasswordContainer, useAuthModals, ResetSecondAuthContainer } from '../../../../auth';
import { useNotificationsStore } from '@pay/admin-ui-kit';
import React from 'react';
import { useTranslation } from 'startup/utils';

export const SecuritySettingsContainer: React.FC = (props) => {
  const { showNotification } = useNotificationsStore();
  const { showSecondAuthModal } = useAuthModals();
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Box width={460}>
            <ChangePasswordContainer />
          </Box>
        </Grid>
        <Grid item>
          <Box width={460}>
            <ResetSecondAuthContainer />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
