import { BOReportRequestMealTypeEnum, BOReportRequestSearchTypeEnum } from 'apis-generated/reports';
import { ITransKey } from 'startup/i18n';
import { EFormField, EMode } from './types';

export const SEARCH_TYPE_TRANS_MAP: Record<BOReportRequestSearchTypeEnum, ITransKey> = {
  [BOReportRequestSearchTypeEnum.School]: 'report_meal_voucher_search_type_school',
  [BOReportRequestSearchTypeEnum.Student]: 'report_meal_voucher_search_type_student',
};

export const MEAL_TYPE_TRANS_MAP: Record<BOReportRequestMealTypeEnum, ITransKey> = {
  [BOReportRequestMealTypeEnum.Breakfast]: 'report_meal_voucher_meal_type_breakfast',
  [BOReportRequestMealTypeEnum.Lunch]: 'report_meal_voucher_meal_type_lunch',
};

export const TITLE_MODE_MAP: Record<EMode, ITransKey> = {
  [EMode.Create]: 'meal_admin_editor_create_title',
  [EMode.View]: 'meal_admin_editor_view_title',
  [EMode.Edit]: 'meal_admin_editor_edit_title',
};

export const FIELD_LABEL_MAP: Record<EFormField, ITransKey> = {
  [EFormField.Firstname]: 'user_field_first_name',
  [EFormField.Lastname]: 'user_field_last_name',
  [EFormField.Email]: 'user_field_email',
  [EFormField.Phone]: 'user_field_phone_number',
  [EFormField.Username]: 'user_field_login',
  [EFormField.Roles]: 'user_field_roles',
  [EFormField.Position]: 'user_field_position',
  [EFormField.SchoolLocations]: 'user_field_region',
};

export const MEAL_ADMIN_DETAILS_ENTITY_ID = 'meal_admin';
export const MEAL_ADMIN_EDIT_DETAILS_ENTITY_ID = 'meal_user';
