import React, { useMemo, useState, FC } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DetailsCard, DetailsCardDefinitions, IDefinitionItem } from '@pay/admin-ui-kit';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import { isNil } from 'lodash-es';
import { useCallbackOne as useCallback } from 'use-memo-one';

import {
  BlackboxDataStatusEnum,
  BlackboxEmojiData,
  BlackboxEmojiDataStatusEnum,
} from 'apis-generated/mapper-processing-admin';
import { WithPermissions } from 'modules/auth';
import { extractErrorMessage } from 'modules/common/errors';
import { IRemoteError } from 'modules/common/store';
import { EPermissionType } from 'modules/user-management';
import { useTranslation } from 'startup/utils';
import { useFinOrganizationsService } from '../../module';
import { BB_STATUS_MAP } from '../constants';

const compactDefinitions = (defs: IDefinitionItem[]) => defs.filter((def) => !isNil(def.value));

interface ITransactionsInfoPaneProps {
  organization: BlackboxEmojiData;
  onRefresh: () => void;
}
export const OrganizationInfoPane: FC<ITransactionsInfoPaneProps> = ({
  organization,
  onRefresh,
}) => {
  const { t } = useTranslation();

  const orgService = useFinOrganizationsService();
  const [connectError, setConnectError] = useState<IRemoteError | undefined>();

  const handleConnect = useCallback(() => {
    return pipe(
      orgService.approveOrganization(organization.bin ?? ''),
      TE.map((_) => {
        onRefresh();
        return undefined;
      }),
      TE.mapLeft((e) => {
        setConnectError(e);
        return undefined;
      })
    )();
  }, [onRefresh, orgService, organization.bin]);

  const orgDetails = useMemo(
    () =>
      compactDefinitions([
        { title: t('fin_organizations_column_name'), value: organization.name },
        { title: t('fin_organizations_column_bin'), value: organization.bin },
        {
          title: t('fin_organizations_column_status'),
          value: t(BB_STATUS_MAP[organization.status ?? BlackboxDataStatusEnum.Init]),
        },
        {
          title: t('fin_organizations_field_contact_phone'),
          value: organization.contactPhone,
        },
        {
          title: t('fin_organizations_field_url'),
          value: organization.blackboxUrl,
        },
      ]),
    [t, organization]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={4} sm={6} xs={12}>
          <DetailsCard title={t('organization_details_tab_info')}>
            <DetailsCardDefinitions items={orgDetails} />
          </DetailsCard>
          <WithPermissions allow={[EPermissionType.BlackboxWrite]}>
            {organization.status === BlackboxEmojiDataStatusEnum.Init && (
              <DetailsCard title={t('fin_organization_details_connect_title')}>
                <Box p={2} pt={0}>
                  <Alert icon={false} color="info">
                    {t('fin_organization_details_connect_description')}
                  </Alert>
                </Box>
                {connectError && (
                  <Box p={2} pt={0}>
                    <Alert color="error">{extractErrorMessage(connectError, t)}</Alert>
                  </Box>
                )}
                <Box p={2} pt={1} style={{ fontSize: 22 }} margin="auto" textAlign="center">
                  {organization.emoji}
                  <Box textAlign="right">
                    <Button variant="contained" color="primary" onClick={handleConnect}>
                      {t('fin_organization_details_connect')}
                    </Button>
                  </Box>
                </Box>
              </DetailsCard>
            )}
          </WithPermissions>
        </Grid>
        {/* <Grid item lg={4} sm={6} xs={12}>
        
        </Grid> */}
      </Grid>
    </>
  );
};
