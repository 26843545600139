import { PrivacyPolicyService } from './service/PrivacyPolicyService';
import { useGlobalContext } from '@pay/modules';

export interface PrivacyPolicyModuleContext {
  privacyPolicy: {
    service: PrivacyPolicyService;
  };
}

export const usePrivacyPolicyService = () =>
  useGlobalContext<PrivacyPolicyModuleContext>().privacyPolicy.service;
