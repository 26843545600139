import { useGlobalContext } from '@pay/modules';

import { ClientService } from './service/ClientService';
import { ClientsStore } from './data/ClientsStore';
import { WorkspaceService } from './service/WorkspaceService';

export interface ClientModuleContext {
  client: {
    clientService: ClientService;
    workspaceService: WorkspaceService;
    store: ClientsStore;
  };
}

export const useClientService = () => useGlobalContext<ClientModuleContext>().client.clientService;
export const useWorkspaceService = () =>
  useGlobalContext<ClientModuleContext>().client.workspaceService;
export const useClientsStore = () => useGlobalContext<ClientModuleContext>().client.store;
