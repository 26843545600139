import { ServerError } from '@pay/data-fetching';
import { I2FaVerificationError } from 'modules/auth/constants';
import { ITransKey } from 'startup/i18n';
import { IAppTransFunction } from 'startup/utils';
import { ICommonError, IRemoteError } from './store';

export enum EErrorCode {}

const ERROR_TRANS_MAP: Record<EErrorCode, ITransKey> = {};

export const extractErrorMessage = (error: IRemoteError, t: IAppTransFunction) => {
  if (error.serverError && error.serverError.message) {
    return error.serverError.message;
  }
  return t('common_unknown_error');
};

export const matchServerError = <TM extends Record<string, (serErr: ServerError) => unknown>>(
  matchers: TM
) => (err: IRemoteError): IRemoteError | ReturnType<TM[keyof TM]> => {
  const errorCode = err.serverError?.errors[0]?.errorCode;
  if (!err.serverError || !errorCode) {
    return err;
  }
  const matcher = matchers[errorCode];
  if (!matcher) {
    return err;
  }
  return matcher(err.serverError) as any;
};
