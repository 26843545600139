import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';

export type IListAsynState<T> =
  | { loading: 'more'; value: T }
  | { loading: false; value: T; hasMoreItems: boolean }
  | { loading: 'initial' };

export type IListAsyncState<TItem, TError> =
  | { loading: 'more'; error?: undefined; value: TItem[] }
  | { loading: false; error: TError; value?: undefined }
  | {
      loading: false;
      error?: undefined;
      value: TItem[];
      loadMoreError?: TError;
      hasMoreItems: boolean;
    }
  | { loading: 'initial' };

export type IAsyncStateSimple<T> =
  | { loading: false; value: T }
  | { loading: true; value?: undefined };

type IAsyncLoadingState = {
  loading: true;
  value?: undefined;
  error?: undefined;
};
type IAsyncSuccessState<TResult> = {
  loading: false;
  value: TResult;
  error?: undefined;
};
type IAsyncErrorState<TError> = {
  loading: false;
  value?: undefined;
  error: TError;
};
export type IAsyncState<T, TError> =
  | IAsyncLoadingState
  | IAsyncSuccessState<T>
  | IAsyncErrorState<TError>;

enum EAsyncStateTag {
  Loading = 'loading',
  Error = 'error',
  Success = 'success',
}

interface IAsyncStateTagMap<TR, TE> {
  [EAsyncStateTag.Loading]: IAsyncLoadingState;
  [EAsyncStateTag.Error]: IAsyncErrorState<TE>;
  [EAsyncStateTag.Success]: IAsyncSuccessState<TR>;
}

interface IAsyncStateMapperFn<TR, TE> {
  [EAsyncStateTag.Loading]: (st: IAsyncLoadingState) => any;
  [EAsyncStateTag.Error]: (st: TE) => any;
  [EAsyncStateTag.Success]: (st: TR) => any;
}

export const fromEither = <TL, TR>(either: E.Either<TL, TR>): IAsyncState<TR, TL> => {
  return pipe(
    either,
    E.fold(
      (e) => ({ loading: false, error: e } as IAsyncState<TR, TL>),
      (v) => ({
        loading: false,
        value: v,
      })
    )
  );
};

export const mapAsyncState = <
  TState extends IAsyncState<any, any>,
  TMapper extends IAsyncStateMapperFn<
    TState extends IAsyncSuccessState<infer US> ? US : never,
    TState extends IAsyncErrorState<infer UE> ? UE : never
  >
>(
  state: TState,
  mapper: TMapper
): TMapper extends Record<EAsyncStateTag, (a: any) => infer U> ? U : never => {
  // TOOD: Investigate why type is not narrowed automatically
  // https://github.com/microsoft/TypeScript/issues/13995
  if (state.loading) {
    return mapper[EAsyncStateTag.Loading](state as IAsyncLoadingState);
  }
  if (state.error) {
    return mapper[EAsyncStateTag.Error]((state as IAsyncErrorState<any>).error);
  }
  return mapper[EAsyncStateTag.Success]((state as IAsyncSuccessState<any>).value);
};

/**
 * Usage: <SwitchTransation key={getAsyncStateKey(state)} />
 */
export const getAsyncStateKey = (state: IAsyncState<unknown, unknown>): string => {
  if (state.loading) {
    return 'loading';
  }
  if (state.value) {
    return 'done';
  }
  if (state.error) {
    return 'error';
  }
  // this should never, but TS is complaining somehow
  return '';
};
