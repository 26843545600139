export enum EFormField {
  Username = 'username',
  Roles = 'roles',
  Firstname = 'firstName',
  Lastname = 'lastName',
  Email = 'email',
  Phone = 'phone',
  Position = 'position',
}

export type IFormValues = Record<EFormField, string>;
