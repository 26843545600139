import { Box, Button, Grow } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';

import { useTranslation } from 'startup/utils';
import { extractErrorMessage } from '../errors';
import { IRemoteError } from '../store';

interface IProps {
  error: IRemoteError | string | undefined;
  onRetry?: () => Promise<void> | void;
}
export const ErrorAlert: React.FC<IProps> = ({ error, onRetry }) => {
  const { t } = useTranslation();

  return (
    <Grow in={!!error} unmountOnExit>
      <Box mb={2}>
        <Alert severity="error">
          <AlertTitle>{t('common_error')}</AlertTitle>
          {error && (typeof error === 'string' ? error : extractErrorMessage(error, t))}
          {onRetry && (
            <Box pt={1}>
              <Button onClick={onRetry} variant="contained" color="primary" size="small">
                {t('common_retry')}
              </Button>
            </Box>
          )}
        </Alert>
      </Box>
    </Grow>
  );
};
