import React, { FC } from 'react';

import { useAuthStore } from '../../../auth/module';
import { EPermissionType } from 'modules/user-management';

interface IProps {
  allow: EPermissionType[];
  fallback?: React.ReactElement;
}
export const WithPermissions: FC<IProps> = ({ allow, children, fallback }) => {
  const { currentUser } = useAuthStore();

  if (!currentUser) {
    return null;
  }

  if (!currentUser.hasAllPermissions(allow)) {
    return fallback ?? null;
  }

  return <>{children}</>;
};
