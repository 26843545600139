import { RouteProps, Route, useLocation, Redirect } from 'react-router-dom';
import { useAuthStore } from '../../auth';
import React from 'react';
import { EPermissionType } from 'modules/user-management';
import { useMount } from 'react-use';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
interface IPrivateRouteProps extends RouteProps {
  permissions?: EPermissionType[];
}
export const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  children,
  permissions,
  render,
  ...rest
}) => {
  const authStore = useAuthStore();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authStore.currentUser) {
          return <RedirectToAuth />;
        }
        if (permissions && !authStore.currentUser.hasOneOfPermissions(permissions)) {
          return <Redirect push={false} to={'/not-found'} />;
        }
        return render?.(props) || children;
      }}
    />
  );
};

const RedirectToAuth: React.FC = (props) => {
  const authStore = useAuthStore();
  const location = useLocation();

  useMount(() => {
    authStore.setReturnUrl(location.pathname);
  });

  return (
    <Redirect
      to={{
        pathname: '/auth',
        state: { returnUrl: location.pathname },
      }}
    />
  );
};
