import { createMuiTheme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';

const PRIMARY_MAIN = '#2F77ED';
const BACKGROUND = '#F3F3F3';

const SPACING_UNIT = 8;

const overrides: Overrides = {
  MuiButton: {
    root: {
      // borderRadius: 4,
      // lineHeight: 1.45,
      textTransform: 'none',
      letterSpacing: '0.25px',
      fontWeight: 500,
    },
  },
  MuiSelect: {
    root: {},
    select: {
      fontWeight: 500,
    },
  },
  MuiInput: {
    root: {},
  },
  // MuiOutlinedInput: {
  //   input: {
  //     paddingTop: 6,
  //     paddingBottom: 6,
  //     // padding: `6px 14px`,
  //     height: 22,
  //   },
  //   inputMarginDense: {
  //     paddingTop: 6,
  //     paddingBottom: 6,
  //     // padding: `6px 14px`,
  //     height: 22,
  //   },
  //   root: {
  //     overflow: 'hidden',
  //   },
  //   notchedOutline: {
  //     // borderColor: '#707070',
  //   },
  // },
  // MuiInputBase: {
  //   input: {
  //     fontSize: 14,
  //     padding: '7px 0 8px',
  //     lineHeight: '20px',
  //   },
  // },
  // MuiInputLabel: {
  //   outlined: {
  //     transform: 'translate(14px, 9px) scale(1)',
  //     fontSize: 14,
  //     '&$marginDense': {
  //       transform: 'translate(14px, 9px) scale(1)',
  //     },

  //   },

  //   marginDense: {
  //     transform: 'translate(14px, 9px) scale(1)',
  //     fontSize: 14,
  //   }
  // },
  MuiSvgIcon: {
    root: {},
  },
  MuiDialogActions: {
    root: {
      padding: SPACING_UNIT * 2,
    },
  },
  MuiPaper: {
    elevation1: {
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    },
  },
  MuiCardActions: {
    root: {
      justifyContent: 'flex-end',
    },
    spacing: {
      padding: SPACING_UNIT * 2,
    },
  },
};

const props: ComponentsProps = {
  MuiTextField: {
    variant: 'outlined',
  },
  MuiButton: {
    disableElevation: true,
    size: 'small',
  },
};

export const muiLightTheme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: '#707070',
    },
    background: {
      default: BACKGROUND,
    },
    error: {
      main: '#D1745F',
    },
    warning: {
      main: '#D4AD47',
    },
    action: {
      selected: BACKGROUND,
    },
    success: {
      main: '#82ca9d',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  overrides: overrides,
  props: props,
});

export const muiDarkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#90caf9',
    },
    error: {
      main: '#D1745F',
    },
    success: {
      main: '#82ca9d',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  overrides: overrides,
  props: props,
});

// TODO: calcualte based on theme font size
export const BODY_1_LINE_HEIGHT_PX = 24;
