import { useTranslation } from 'startup/utils';
import { EPermissionType } from 'modules/user-management';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import React, { useCallback, useMemo } from 'react';
import { usePermissionsCheck } from 'modules/auth/hooks/usePermissionsCheck';

import { CellProps } from 'react-table';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Column } from '@pay/admin-data-table';
import { SimplePopup, useModals, useNotificationsStore } from '@pay/admin-ui-kit';
import { useNonMonetaryService } from 'modules/business-accounts/module';
import { School } from 'apis-generated/mapper-admin-nonmonetary-admin';
import { shouldTrans } from 'modules/common/utils';

export const useBusinessSchoolColumns = (
  legalId: string,
  onRefreshTable: () => void
): Column<School>[] => {
  const { t } = useTranslation();
  const { showModal } = useModals();
  const { showNotification } = useNotificationsStore();

  const { deleteSchool } = useNonMonetaryService();

  const canSchoolWrite = usePermissionsCheck([
    EPermissionType.Superadmin,
    EPermissionType.FinancialOrganizationWrite,
  ]);

  const handleDelete = useCallback(
    (school: School) => {
      const title = (school.number && `№${school.number}`) ?? school.name ?? '';
      showModal((close) => (
        <SimplePopup
          title={t('business_school_delete')}
          onOk={() => {
            return pipe(
              deleteSchool(legalId, school.bin),
              TE.match(
                () => {
                  showNotification({
                    text: t('business_school_delete_failured', { title }),
                    options: { variant: 'error' },
                  });
                  close();
                },
                () => {
                  showNotification({
                    text: t('business_school_delete_successed', { title }),
                    options: { variant: 'success' },
                  });
                  onRefreshTable();
                  close();
                }
              )
            )();
          }}
          okLabel={t('common_delete')}
          content={t('business_school_delete_ask', { title })}
        />
      ));
    },
    [legalId, deleteSchool, showModal, showNotification, onRefreshTable, t]
  );

  return useMemo(() => {
    const actionColumns: Column<School>[] = canSchoolWrite
      ? [
          {
            id: 'delete',
            width: 60,
            disableResizing: true,
            horizontalAlign: 'center',
            Cell: ({ row: { original } }: CellProps<School>) => (
              <IconButton size="small" onClick={() => handleDelete(original)}>
                <Delete />
              </IconButton>
            ),
            sortable: false,
          },
        ]
      : [];

    const commonColumns: Column<School>[] = [
      {
        accessor: 'number',
        Header: shouldTrans('Номер'),
      },

      {
        accessor: 'bin',
        Header: shouldTrans('БИН'),
      },

      {
        accessor: 'name',
        Header: shouldTrans('Наименование'),
      },

      {
        accessor: 'address',
        Header: shouldTrans('Адрес'),
      },
    ];
    return [...commonColumns, ...actionColumns];
  }, [t, canSchoolWrite, handleDelete]);
};
