import React, { useCallback } from 'react';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import { CircularProgress, makeStyles, Tab } from '@material-ui/core';
import {
  DetailsError,
  DetailsLoading,
  DetailsView,
  IDetailsComponentProps,
  Tabs,
} from '@pay/admin-ui-kit';

import { useDetailsStore } from 'modules/common/details';
import { useFetching } from 'modules/common/hooks';
import { useClientService, useClientsStore } from '../../module';
import { useTaskEitherImmediate } from 'modules/common/async/hooks';

import { CLIENT_DETAIL_ENTITY_ID } from '../../constants';
import { ClientInfoPane } from './ClientInfoPane';
import { useTranslation } from 'startup/utils';
import { ClientRespDto } from 'apis-generated/mapper-sso-admin';
import { ClientLogsPane } from './logs/ClientLogsPane';
import { ClientDevicesPane } from './devices/ClientDevicesPane';
import { ClientWorkspacesPane } from './workspaces/ClientWorkspacesPane';
import { mapAsyncState } from 'modules/common/async';
import { ErrorAlert } from 'modules/common/ui/errors';

const useStyles = makeStyles((theme) => ({
  root: {},
  contentRoot: {
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    overflow: 'auto',
    position: 'relative',
    flexGrow: 1,
  },
  tabsRoot: {
    padding: `0 ${theme.spacing(6)}px`,
  },
}));

enum ETab {
  Default = '',
  Devices = 'devices',
  Workspaces = 'workspaces',
  Logs = 'logs',
}

interface IProps extends IDetailsComponentProps {}

export const ClientDetails: React.FC<IProps> = (props) => {
  const { tab = ETab.Default } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const detailsStore = useDetailsStore();
  const clientService = useClientService();

  const handleTabChange = useCallback(
    (_: unknown, tab: ETab) => {
      detailsStore.openDetails({
        entityId: props.itemId,
        entityType: CLIENT_DETAIL_ENTITY_ID,
        tab: tab,
      });
    },
    [detailsStore, props.itemId]
  );

  const store = useClientsStore();
  const [state, refresh] = useFetching(() => store.fetchOne(props.itemId));
  const { state: docState, retry: docRetry } = useTaskEitherImmediate(() =>
    pipe(
      clientService.getClientsDocumentInfo(),
      TE.map((doc) => doc)
    )
  );

  const getFullName = (client: ClientRespDto) =>
    `${client.firstName ?? ''} ${client.lastName ?? ''}`.trim();

  const title =
    (!state.loading &&
      state.result.isRight &&
      `${t('client')}: ${getFullName(state.result.right)} (${state.result.right.iin ?? ''})`) ??
    '';

  const renderTabContent = () => {
    if (state.loading) {
      return <DetailsLoading />;
    }

    if (state.result.isLeft) {
      return <DetailsError />;
    }

    const client = state.result.right;

    return (
      <>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          classes={{ root: classes.tabsRoot }}
        >
          <Tab value={ETab.Default} label={t('client_details_tab_main')} />
          <Tab value={ETab.Workspaces} label={t('client_details_tab_workspaces')} />
          <Tab value={ETab.Logs} label={t('client_details_tab_logs')} />
          <Tab value={ETab.Devices} label={t('client_details_tab_devices')} />
        </Tabs>
        <div className={classes.contentRoot}>
          <>
            {tab === ETab.Default && (
              <>
                {mapAsyncState(docState, {
                  error: (e) => <ErrorAlert error={e} onRetry={docRetry} />,
                  loading: (_) => <CircularProgress />,
                  success: (state) => (
                    <ClientInfoPane client={client} document={state} onChanged={refresh} />
                  ),
                })}
              </>
            )}
            {tab === ETab.Workspaces && <ClientWorkspacesPane client={client} />}
            {tab === ETab.Logs && <ClientLogsPane client={client} />}
            {tab === ETab.Devices && (
              <ClientDevicesPane client={client} onRefreshClient={refresh} />
            )}
          </>
        </div>
      </>
    );
  };

  return (
    <DetailsView onClose={props.onClose} title={title} entered={props.entered}>
      {renderTabContent()}
    </DetailsView>
  );
};
