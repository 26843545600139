import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';

import { mapFetcherResultEither } from 'modules/common/fetcher';
import { BOReportRequest, MealReportsControllerApiInterface } from 'apis-generated/reports';

export const makeReportService = (api: MealReportsControllerApiInterface) => {
  return {
    downloadStatsReport: (dateFrom: Date, dateTo: Date) => {
      return pipe(() => api.statisticReport({ dateFrom, dateTo }), T.map(mapFetcherResultEither));
    },
    downloadExcelReportBySchoolVauchers: (requestBody: BOReportRequest) => {
      return pipe(
        () =>
          api.excelReportBySchoolVauchers({
            bOReportRequest: requestBody,
          }),
        T.map(mapFetcherResultEither)
      );
    },
  };
};

export type ReportService = ReturnType<typeof makeReportService>;
