import { Column, FilterType, TextFilterFormat } from '@pay/admin-data-table';
import { AgreementUpdateScheduleRespDto } from 'apis-generated/mapper-sso-admin';
import React from 'react';
import { IAppTransFunction } from 'startup/utils';
import { DATE_FORMAT } from 'modules/common/constants';
import { formatDate } from 'modules/common/format';
import { Button } from '@material-ui/core';
import { EApiLang } from 'startup/i18n';

export const getAgreementChangeRequestsColumns = (
  t: IAppTransFunction,
  onClick: (agreementUpdateScheduleRespDto: AgreementUpdateScheduleRespDto, lang: EApiLang) => void
): Column<AgreementUpdateScheduleRespDto>[] => {
  return [
    {
      accessor: 'createdDate',
      sorting: {
        rsqlOptions: {
          field: 'createdAt',
        },
      },
      Header: t('agreement_column_created_date'),
      Cell: ({ row: { original }, value }) => (
        <>{value ? formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm) : null}</>
      ),
    },
    {
      accessor: 'activationDate',
      Header: t('agreement_column_activation_date'),
      Cell: ({ row: { original }, value }) => (
        <>{value ? formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm) : null}</>
      ),
    },
    {
      accessor: 'createdBy',
      Header: t('agreement_column_created_by'),
    },
    {
      accessor: 'nameKz',
      sorting: {
        rsqlOptions: {
          field: 'contentKz',
        },
      },
      Header: t('agreement_column_content_kk'),
      Cell: ({ row: { original }, value }) => (
        <Button onClick={() => onClick(original, EApiLang.Kk)}>{value}</Button>
      ),
    },
    {
      accessor: 'nameRu',
      sorting: {
        rsqlOptions: {
          field: 'contentRu',
        },
      },
      Header: t('agreement_column_content_ru'),
      Cell: ({ row: { original }, value }) => (
        <Button onClick={() => onClick(original, EApiLang.Ru)}>{value}</Button>
      ),
    },
    {
      accessor: 'active',
      Header: t('agreement_column_verification_status'),
      Cell: ({ row: { original }, value }) => (
        <>{value ? t('agreement_status_active') : t('agreement_status_inactive')}</>
      ),
    },
  ];
};
