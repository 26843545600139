import {
  AgreementApiInterface,
  AgreementHistoryRespDto,
  AgreementUpdateScheduleRespDto,
  DownloadAgreementTypeEnum,
  UpdateAgreementRequest,
  UpdateLegalAgreementRequestBody,
} from 'apis-generated/mapper-sso-admin';
import { constUndefined, pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { mapFetcherResultEither } from 'modules/common/fetcher';
import { makeTableDataSource } from '@pay/admin-resources';
import { IFetcher } from '@pay/data-fetching';
import { EApiLang } from 'startup/i18n';
import { lazy } from 'react';

export class LegalAgreementService {
  constructor(private _api: AgreementApiInterface, private _fetcher: IFetcher) {}

  public updateAgreement(req: UpdateAgreementRequest) {
    return pipe(
      () => this._api.updateLegalAgreement({ updateAgreementRequest: req }),
      T.map(mapFetcherResultEither),
      TE.map(constUndefined)
    );
  }

  public downloadAgreement(id: string, type: DownloadAgreementTypeEnum, lang: EApiLang) {
    return pipe(
      () => {
        return this._fetcher.get<Blob>(`bo/legalagreement/download/${type}/${id}`, {
          headers: { 'X-Locale': lang },
        });
      },
      T.map((result) => mapFetcherResultEither(result))
    );
  }

  public fetchLegalAgreementHistory = makeTableDataSource<AgreementHistoryRespDto>(
    this._fetcher,
    'bo/legalagreement/history'
  );

  public fetchAgreementChangeRequests = makeTableDataSource<AgreementUpdateScheduleRespDto>(
    this._fetcher,
    'bo/legalagreement/update-schedule'
  );
}
