import { makeStyles, Tab } from '@material-ui/core';
import {
  DetailsError,
  DetailsLoading,
  DetailsView,
  IDetailsComponentProps,
  Tabs,
} from '@pay/admin-ui-kit';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDetailsStore } from 'modules/common/details';
import { useFetching } from 'modules/common/hooks';
import { useBusinessAccountsStore } from '../../module';
import { BUSINESS_ACCOUNT_DETAILS_ENTITY_ID } from '../constants';
import { BusinessAccountInfoPane } from './BusinessAccountInfoPane';
import { StaticQrDownloadPane } from './StaticQrDownloadPane/StaticQrDownloadPane';
import { usePermissionsCheck } from 'modules/auth/hooks/usePermissionsCheck';
import { EPermissionType } from 'modules/user-management';

const useStyles = makeStyles((theme) => ({
  root: {},
  contentRoot: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    overflow: 'auto',
    '& [data-table-body=true]': {
      height: 'auto!important',
    },
  },
  tabsRoot: {
    padding: `0 ${theme.spacing(6)}px`,
  },
}));

enum ETab {
  Default = '',
  Transactions = 'transactions',
  Qr = 'qr',
}

interface IProps extends IDetailsComponentProps {}
export const BusinessAccountDetails: FC<IProps> = ({ tab = ETab.Default, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const canView = usePermissionsCheck([EPermissionType.Superadmin]);

  const detailsStore = useDetailsStore();

  const handleTabChange = useCallback(
    (_: unknown, tab: ETab) => {
      detailsStore.openDetails({
        entityId: props.itemId,
        entityType: BUSINESS_ACCOUNT_DETAILS_ENTITY_ID,
        tab: tab,
      });
    },
    [detailsStore, props.itemId]
  );

  const store = useBusinessAccountsStore();
  const [state, refresh] = useFetching(() => store.fetchOne(props.itemId));

  const title =
    (!state.loading &&
      state.result.isRight &&
      `${t('business_account')}: ${state.result.right.name ?? ''}`) ??
    '';

  const renderTabContent = () => {
    if (state.loading) {
      return <DetailsLoading />;
    }

    if (state.result.isLeft) {
      return <DetailsError />;
    }

    const businessAccount = state.result.right;

    return (
      <>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          classes={{ root: classes.tabsRoot }}
        >
          <Tab value={ETab.Default} label={t('business_accounts_details_tab_main')} />
          {canView && <Tab value={ETab.Qr} label={t('business_participants_title_static_qr')} />}
        </Tabs>
        <div className={classes.contentRoot}>
          <>
            {tab === ETab.Default && (
              <BusinessAccountInfoPane account={businessAccount} onRefreshAccount={refresh} />
            )}
            {tab === ETab.Qr && <StaticQrDownloadPane />}
          </>
        </div>
      </>
    );
  };

  return (
    <DetailsView
      style={{ overflowY: 'hidden' }}
      contentBoxProps={{ flexShrink: 1 }}
      onClose={props.onClose}
      title={title}
      entered={props.entered}
      className="loli"
    >
      {renderTabContent()}
    </DetailsView>
  );
};
