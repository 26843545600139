import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import { Grid } from '@material-ui/core';
import {
  DetailsCard,
  DetailsCardDefinitions,
  IDefinitionItem,
  useNotificationsStore,
} from '@pay/admin-ui-kit';
import { Button } from '@pay/mui-enhancement';

import { ClientRespDto } from 'apis-generated/mapper-sso-admin';
import { useAuthStore } from 'modules/auth/module';
import { EPermissionType } from 'modules/user-management';
import { useClientService } from '../../module';
import { IClientDocInfo } from 'modules/clients/service/ClientService';

interface IProps {
  client: ClientRespDto;
  document: IClientDocInfo;
  onChanged: () => void;
}

export const ClientInfoPane: FC<IProps> = ({ client, document, onChanged }) => {
  const { t } = useTranslation();
  const clientService = useClientService();
  const authStore = useAuthStore();
  const { showNotification } = useNotificationsStore();

  const canEdit = authStore.currentUser?.hasOneOfPermissions([EPermissionType.ClientsWrite]);

  const clientDetails: IDefinitionItem[] = useMemo(
    () => [
      { title: t('clients_column_id'), value: client.clientId },
      { title: t('clients_column_iin'), value: client.iin },
      {
        title: t('clients_column_name'),
        value: `${client.firstName || ''} ${client.lastName || ''}`,
      },
      { title: t('clients_column_phone'), value: client.username },
    ],
    [t, client]
  );

  const handleSubmitInternal = useCallback(() => {
    if (client.active) {
      pipe(
        clientService.blockUser(client.username),
        TE.map(onChanged),
        TE.mapLeft((err) => showNotification({ text: t('common_unknown_error') }))
      )();
    } else {
      pipe(
        clientService.activateUser(client.username),
        TE.map(onChanged),
        TE.mapLeft((err) => showNotification({ text: t('common_unknown_error') }))
      )();
    }
  }, [client, clientService, onChanged, showNotification, t]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid lg={6} sm={6} xs={12} item>
          <DetailsCard title={t('client_details_area_passport')}>
            <DetailsCardDefinitions items={clientDetails} />
          </DetailsCard>
          {canEdit && (
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={handleSubmitInternal}
            >
              {client.active ? t('client_details_block') : t('client_details_unblock')}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
