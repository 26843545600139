import {
  AccountBalance,
  AccountBalanceOutlined,
  AccountCircle,
  AccountCircleOutlined,
  Build,
  BuildOutlined,
  BusinessCenter,
  BusinessCenterOutlined,
  Dashboard,
  DashboardOutlined,
  Description,
  DescriptionOutlined,
  ListAlt,
  ListAltOutlined,
  Settings,
  SettingsOutlined,
  SupervisorAccount,
  SupervisorAccountOutlined,
  Policy,
  PolicyOutlined,
  PhoneAndroid,
  PhoneAndroidOutlined,
  AndroidOutlined,
  Android,
  School,
  SchoolOutlined,
} from '@material-ui/icons';
import { INavigationLinkUnion } from '@pay/admin-ui-kit';
import { CurrentUserModel } from 'modules/auth';
import { BusinessAccountsPage } from 'modules/business-accounts';
import { ClientsPage } from 'modules/clients';
import { DashboardPage } from 'modules/dashboard';
import { FinOrganizationsPage } from 'modules/fin-organizations';
import { LogsPage } from 'modules/logs';
import { SettingsPage } from 'modules/settings';
import { EPermissionType, UserManagementPage } from 'modules/user-management';
import React from 'react';
import { IAppTransFunction } from 'startup/utils';
import { ConfigurationPage } from 'modules/configuration';
import { AgreementPage } from 'modules/agreement';
import { LegalAgreementPage } from 'modules/legal-agreement';
import { PrivacyPolicyPage } from 'modules/privacy-policy';
import { CoidLogPage } from 'modules/coid';
import { TestClientsPage } from 'modules/test-clients';
import { PosTerminalApkPage } from 'modules/pos-terminals';
import { getNavigationLinks as getMealsNavigationLinks } from 'modules/meals';
import { RouteConfig } from 'modules/common/routing';
import { getRoutes as getMealsRoutes } from 'modules/meals';
import { SchoolsPage } from 'modules/schools';

const settingsRoute: RouteConfig = {
  path: '/settings',
  exact: false,
  component: SettingsPage,
};

export const NAVIGATION_ROUTES: readonly RouteConfig[] = [
  {
    path: '/home',
    exact: false,
    component: () => <DashboardPage />,
    permissions: [EPermissionType.DashboardRead],
  },
  {
    path: '/clients',
    exact: false,
    component: () => <ClientsPage />,
    permissions: [EPermissionType.ClientsRead],
  },
  {
    path: '/business-accounts',
    exact: false,
    component: () => <BusinessAccountsPage />,
    permissions: [EPermissionType.LegalRead],
  },
  {
    path: '/financial-institutes',
    exact: false,
    component: () => <FinOrganizationsPage />,
    permissions: [EPermissionType.BlackboxRead],
  },
  {
    path: '/test-clients',
    exact: false,
    component: () => <TestClientsPage />,
    permissions: [EPermissionType.ClientsRead],
  },
  {
    path: '/pos-terminals-apk',
    exact: false,
    component: () => <PosTerminalApkPage />,
    permissions: [EPermissionType.ApkRead],
  },
  ...getMealsRoutes(),
  // FFU
  // {
  //   path: '/transactions',
  //   exact: false,
  //   component: () => <TransactionsPage />,
  // },
  {
    path: '/user-management/:tab?',
    exact: false,
    component: UserManagementPage,
    permissions: [EPermissionType.UsersRead, EPermissionType.RolesRead, EPermissionType.System],
  },
  {
    path: '/logs',
    exact: false,
    component: () => <LogsPage />,
    permissions: [EPermissionType.UsersRead],
  },
  {
    path: '/coid',
    exact: false,
    component: () => <CoidLogPage />,
  },
  {
    path: '/agreement/sw',
    exact: false,
    component: () => <AgreementPage />,
    permissions: [EPermissionType.AgreementRead],
  },
  {
    path: '/agreement/legal',
    exact: false,
    component: () => <LegalAgreementPage />,
    permissions: [EPermissionType.AgreementRead],
  },
  {
    path: '/privacy-policy',
    exact: false,
    component: () => <PrivacyPolicyPage />,
    permissions: [EPermissionType.PrivacyPolicyRead],
  },
  {
    path: '/config',
    exact: false,
    component: () => <ConfigurationPage />,
    permissions: [EPermissionType.SmsRead],
  },
  // {
  //   path: '/statistics',
  //   exact: false,
  //   component: () => <ClientsStatisticsPage />,
  // },
  settingsRoute,
  {
    path: '/spps',
    exact: false,
    component: () => <>SPP</>,
  },
];

export const getFirstAvailableRoute = (userModel: CurrentUserModel | undefined) => {
  if (!userModel) return settingsRoute;
  const isAccessibleRoute = (route: RouteConfig) =>
    route.permissions?.length ? userModel.hasOneOfPermissions(route.permissions) : true;

  return NAVIGATION_ROUTES.find(isAccessibleRoute) ?? settingsRoute;
};

export const getNavigationLinks = (t: IAppTransFunction, userModel: CurrentUserModel) => {
  const firstGroupLinks: INavigationLinkUnion[] = [];
  const secondGroupLinks: INavigationLinkUnion[] = [];
  if (userModel.hasOneOfPermissions([EPermissionType.DashboardRead])) {
    firstGroupLinks.push({
      group: false,
      path: '/home',
      exact: true,
      menuIcon: DashboardOutlined,
      menuIconSelected: Dashboard,
      title: t('navigation_main') as string,
    });
  }
  if (userModel.hasOneOfPermissions([EPermissionType.ClientsRead])) {
    firstGroupLinks.push({
      group: false,
      path: '/clients',
      exact: false,
      menuIconSelected: AccountCircle,
      menuIcon: AccountCircleOutlined,
      title: t('navigation_clients'),
    });
  }
  if (userModel.hasOneOfPermissions([EPermissionType.LegalRead])) {
    firstGroupLinks.push({
      group: false,
      path: '/business-accounts',
      exact: false,
      menuIconSelected: BusinessCenter,
      menuIcon: BusinessCenterOutlined,
      title: t('navigation_business_accounts'),
    });
  }
  if (userModel.hasOneOfPermissions([EPermissionType.BlackboxRead])) {
    firstGroupLinks.push({
      group: false,
      path: '/financial-institutes',
      exact: false,
      menuIconSelected: AccountBalance,
      menuIcon: AccountBalanceOutlined,
      title: t('navigation_financial_organizations'),
    });
  }
  if (userModel.hasOneOfPermissions([EPermissionType.Superadmin])) {
    firstGroupLinks.push({
      group: false,
      path: '/test-clients',
      exact: false,
      menuIconSelected: PhoneAndroidOutlined,
      menuIcon: PhoneAndroid,
      title: t('test_clients'),
    });
  }
  if (userModel.hasOneOfPermissions([EPermissionType.ApkRead])) {
    firstGroupLinks.push({
      group: false,
      path: '/pos-terminals-apk',
      exact: false,
      menuIconSelected: AndroidOutlined,
      menuIcon: Android,
      title: t('pos_terminal_apk'),
    });
  }
  const mealsLinks = getMealsNavigationLinks({ userModel, t });
  if (mealsLinks) firstGroupLinks.push(mealsLinks);

  // FFU
  // firstGroupLinks.push({
  //   group: false,
  //   path: '/transactions',
  //   exact: false,
  //   menuIconSelected: Assignment,
  //   menuIcon: AssignmentOutlined,
  //   title: t('navigation_transactions'),
  // });
  if (
    userModel.hasOneOfPermissions([
      EPermissionType.UsersRead,
      EPermissionType.RolesRead,
      EPermissionType.System,
    ])
  ) {
    firstGroupLinks.push({
      group: false,
      path: '/user-management/:tab?',
      exact: false,
      menuIconSelected: SupervisorAccount,
      menuIcon: SupervisorAccountOutlined,
      title: t('navigation_users'),
      rootLink: '/user-management',
    });
  }
  if (userModel.hasOneOfPermissions([EPermissionType.UsersRead])) {
    firstGroupLinks.push({
      group: false,
      path: '/logs',
      exact: false,
      menuIconSelected: ListAlt,
      menuIcon: ListAltOutlined,
      title: t('navigation_logs'),
    });
  }
  /* firstGroupLinks.push({
    group: false,
    path: '/coid',
    exact: false,
    menuIconSelected: ListAlt,
    menuIcon: ListAltOutlined,
    title: t('navigation_coid'),
  }); */
  if (userModel.hasOneOfPermissions([EPermissionType.AgreementRead])) {
    firstGroupLinks.push({
      group: false,
      path: '/agreement/sw',
      exact: false,
      menuIconSelected: Description,
      menuIcon: DescriptionOutlined,
      title: t('navigation_offer'),
    });
  }
  if (userModel.hasOneOfPermissions([EPermissionType.AgreementRead])) {
    firstGroupLinks.push({
      group: false,
      path: '/agreement/legal',
      exact: false,
      menuIconSelected: Description,
      menuIcon: DescriptionOutlined,
      title: t('legal_navigation_offer'),
    });
  }
  if (userModel.hasOneOfPermissions([EPermissionType.PrivacyPolicyRead])) {
    firstGroupLinks.push({
      group: false,
      path: '/privacy-policy',
      exact: false,
      menuIconSelected: Policy,
      menuIcon: PolicyOutlined,
      title: t('navigation_privacy_policy'),
    });
  }
  if (userModel.hasOneOfPermissions([EPermissionType.SmsRead])) {
    firstGroupLinks.push({
      group: false,
      path: '/config',
      exact: false,
      menuIconSelected: Build,
      menuIcon: BuildOutlined,
      title: t('navigation_config'),
    });
  }
  // firstGroupLinks.push({
  //   group: false,
  //   path: '/statistics',
  //   exact: false,
  //   menuIconSelected: Build,
  //   menuIcon: BuildOutlined,
  //   title: t('navigation_statistic'),
  // });
  // }
  // if (userModel.hasOneOfPermissions([EPermissionType.TransactionsRead])) {
  //   firstGroupLinks.push({
  //     group: false,
  //     path: ERootRoute.Transactions,
  //     exact: false,
  //     menuIconSelected: Assignment,
  //     menuIcon: AssignmentOutlined,
  //     title: t('navigation_transactions') as string,
  //   });
  // }
  // if (userModel.hasOneOfPermissions([EPermissionType.TransactionsRead])) {
  //   firstGroupLinks.push({
  //     key: 'dictionaries',
  //     group: true,
  //     menuIcon: BookOutlined,
  //     menuIconSelected: Book,
  //     title: t('navigation_dictionaries'),
  //     subLinks: compact([
  //       userModel.hasOneOfPermissions([EPermissionType.FeeRead]) && {
  //         group: false,
  //         path: '/fees',
  //         exact: false,
  //         title: t('navigation_comissions') as string,
  //       },
  //       userModel.hasOneOfPermissions([EPermissionType.LimitsRead]) && {
  //         group: false,
  //         path: '/limits',
  //         exact: false,
  //         title: t('navigation_limits') as string,
  //       },
  //       userModel.hasOneOfPermissions([EPermissionType.UpsRead]) && {
  //         group: false,
  //         path: '/mrps',
  //         exact: false,
  //         title: t('navigation_mrps'),
  //       },
  //     ]),
  //   });
  // }
  // if (userModel.hasOneOfPermissions([EPermissionType.ClientsRead])) {
  //   firstGroupLinks.push({
  //     group: false,
  //     path: '/accounts',
  //     exact: false,
  //     menuIconSelected: AccountBox,
  //     menuIcon: AccountBoxOutlined,
  //     title: t('navigation_accounts') as string,
  //   });
  // }
  // if (userModel.hasOneOfPermissions([EPermissionType.UsersRead, EPermissionType.RolesRead])) {
  //   firstGroupLinks.push({
  //     group: false,
  //     path: '/user-management/:tab?',
  //     exact: false,
  //     menuIconSelected: SupervisorAccount,
  //     menuIcon: SupervisorAccountOutlined,
  //     title: t('navigation_users') as string,
  //     rootLink: '/user-management',
  //   });
  // }
  secondGroupLinks.push({
    group: false,
    path: '/settings',
    exact: false,
    menuIconSelected: Settings,
    menuIcon: SettingsOutlined,
    title: t('navigation_settings') as string,
  });

  return { firstGroupLinks, secondGroupLinks };

  // {
  //   group: false,
  //   path: '/reports',
  //   exact: false,
  //   menuIconSelected: ReportsToNBSelectedIcon,
  //   menuIcon: ReportsToNBIcon,
  //   title: t('navigation_reports_nb') as string,
  //   permissions: [EPermissionType.ReportsRead],
  // },
  // {
  //   group: false,
  //   path: '/logs',
  //   exact: false,
  //   menuIconSelected: LogsSelectedIcon,
  //   menuIcon: LogsIcon,

  //   title: t('navigation_logs') as string,
  //   permissions: [EPermissionType.SysReadLogs],
  // },
  // {
  //   key: 'antifraud',
  //   group: true,
  //   menuIconSelected: Security,
  //   menuIcon: Security,
  //   title: t('navigation_antifraud') as string,
  //   subLinks: [
  //     {
  //       group: false,
  //       path: '/antifraud/rules',
  //       exact: false,
  //       title: t('navigation_antifraud_rules'),
  //       permissions: [EPermissionType.AntifroadRulesRead],
  //     },
  //   ],
  // },
};
