import {
  combineValidators,
  requiredValidator,
  makeMinLengthValidator,
  makeMaxLengthValidator,
} from '@pay/common-utils';

export const passwordValidator = combineValidators(
  requiredValidator,
  makeMinLengthValidator(8, 'validation_password_min_length'),
  makeMaxLengthValidator(255, 'validation_password_max_length')
);
