import formatString from 'format-string-by-pattern';
import { makeRegexValidator, getEnumValues } from '@pay/common-utils';

export enum ECountryCode {
  Kk = 'kk',
  Ru = 'ru',
  Uz = 'uz',
  Ua = 'ua',
  Us = 'us',
}

export const COUNTRY_CODES = Object.values(ECountryCode);

export const COUNTRY_PHONE_CODE: Record<ECountryCode, string> = {
  [ECountryCode.Kk]: '7',
  [ECountryCode.Ru]: '7',
  [ECountryCode.Uz]: '998',
  [ECountryCode.Ua]: '380',
  [ECountryCode.Us]: '1',
};

export const COUNTRY_TRANS: Record<ECountryCode, string> = {
  [ECountryCode.Kk]: 'sign_in_screen_kazakhstan',
  [ECountryCode.Ru]: 'sign_in_screen_russia',
  [ECountryCode.Uz]: 'sign_in_screen_uzbekistan',
  [ECountryCode.Ua]: 'sign_in_screen_ukraine',
  [ECountryCode.Us]: 'sign_in_screen_usa',
};

interface IPhoneFormatDescriptor {
  /**
   * Validation Regex for formatted phone
   */
  regex: RegExp;
  /**
   * Regex to parse phone coming from BE, e.g. 712345667
   */
  parseRegex: RegExp;
  pattern: string;
  patternWithCode: string;
}

export const COUNTRY_PHONE_FORMAT: Record<ECountryCode, IPhoneFormatDescriptor> = {
  [ECountryCode.Kk]: {
    parseRegex: new RegExp(`^${COUNTRY_PHONE_CODE[ECountryCode.Kk]}\\d{10}$`),
    regex: /[1-9]\d{2}\s\d{3}\s\d{2}\s\d{2}/,
    pattern: '123 456 78 90',
    patternWithCode: '7 123 456 78 90',
  },
  [ECountryCode.Ru]: {
    parseRegex: new RegExp(`^${COUNTRY_PHONE_CODE[ECountryCode.Ru]}\\d{10}$`),
    regex: /[1-9]\d{2}\s\d{3}\s\d{2}\s\d{2}/,
    pattern: '123 456 78 90',
    patternWithCode: '1 123 456 78 90',
  },
  [ECountryCode.Uz]: {
    parseRegex: new RegExp(`^${COUNTRY_PHONE_CODE[ECountryCode.Uz]}\\d{9}$`),
    regex: /[1-9]\d{1}\s\d{7}/,
    pattern: '12 3456789',
    patternWithCode: '111 12 3456789',
  },
  [ECountryCode.Ua]: {
    parseRegex: new RegExp(`^${COUNTRY_PHONE_CODE[ECountryCode.Ua]}\\d{9}$`),
    regex: /[1-9]\d{1}\s\d{3}\s\d{2}\s\d{2}/,
    pattern: '12 345 67 89',
    patternWithCode: '111 12 345 67 89',
  },
  [ECountryCode.Us]: {
    parseRegex: new RegExp(`^${COUNTRY_PHONE_CODE[ECountryCode.Us]}\\d{10}$`),
    regex: /[1-9]\d{2}\s\d{3}\s\d{4}/,
    pattern: '123 456 7890',
    patternWithCode: '1 123 456 7890',
  },
};

/**
 * Format phone coming from user input
 */
export const formatPhone = (phone: string, country: ECountryCode): string => {
  const format = COUNTRY_PHONE_FORMAT[country];
  const phoneSanitized = phone.replace(/\D/g, '');
  if (!phoneSanitized) {
    return '';
  }

  const value = formatString(format.pattern, phoneSanitized);

  return value;
};

/**
 * Parse raw phone coming from BE
 * @param phone e.g. 7123123123
 */
export const parsePhone = (phone: string): [ECountryCode, string] | undefined => {
  if (!phone) {
    return undefined;
  }
  const countryCode = getEnumValues<ECountryCode>(ECountryCode).find((code) =>
    COUNTRY_PHONE_FORMAT[code].parseRegex.test(phone)
  );
  if (!countryCode) {
    return undefined;
  }
  const phoneString = phone.replace(new RegExp(`^${COUNTRY_PHONE_CODE[countryCode]}`), '');
  const formattedPhone = formatString(COUNTRY_PHONE_FORMAT[countryCode].pattern, phoneString);
  return [countryCode, formattedPhone];
};
