import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { UserLocationsControllerApiInterface } from 'apis-generated/reports';

import { mapFetcherResultEither } from 'modules/common/fetcher';
import { makeMap } from 'modules/common/array';

import * as UserLocationSchools from '../core/UserLocationSchools';

export const makeUserLocationsService = (api: UserLocationsControllerApiInterface) => {
  const fetchCurrentUserLocationSchoolsItems = () => {
    return pipe(
      () => api.getCurrentUserLocationSchoolsItems(),
      T.map(mapFetcherResultEither),
      TE.map(makeMap(UserLocationSchools.makeFromDto))
    );
  };
  const fetchUserLocationSchoolsItems = (userId: string) => {
    return pipe(
      () => api.getCurrentUserLocationSchoolsItems(),
      T.map(mapFetcherResultEither),
      TE.map(makeMap(UserLocationSchools.makeFromDto))
    );
  };

  return {
    fetchCurrentUserLocationSchoolsItems,
    fetchUserLocationSchoolsItems,
  };
};

export type UserLocationsService = ReturnType<typeof makeUserLocationsService>;
