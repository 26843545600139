import { Checkbox, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { EPermissionType, PERMISSION_TRANS_MAP } from '../../../../constants';

interface IPermissionsGroupProps {
  readonly: boolean;
  permissions: EPermissionType[];
  state: Record<EPermissionType, boolean>;
  onPermissionChange: (perm: EPermissionType, value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  groupBody: {
    paddingLeft: theme.spacing(3),
  },
}));

export const PermissionsGroup: FC<IPermissionsGroupProps> = React.memo(
  function PermissionsGroupBase({ permissions, state, onPermissionChange, readonly }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleItemClick = useCallback(
      (perm: EPermissionType, set: boolean) => {
        onPermissionChange(perm, set);
      },
      [onPermissionChange]
    );

    return (
      <>
        <FormGroup className={classes.groupBody}>
          {permissions.map((perm) => {
            return (
              <FormControlLabel
                key={perm}
                control={
                  <Checkbox
                    disabled={readonly}
                    color="primary"
                    checked={Boolean(state[perm])}
                    onChange={(e) => handleItemClick(perm, e.target.checked)}
                    value={perm}
                  />
                }
                label={t(PERMISSION_TRANS_MAP[perm])}
              />
            );
          })}
        </FormGroup>
      </>
    );
  }
);
