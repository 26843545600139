import { Fetcher } from '@pay/data-fetching';
import { makeSchoolControllerApi } from './api';
import { SchoolModuleContext } from './module';
import { makeSchoolService } from './services/SchoolService';
import { Subject } from 'rxjs';
import { SchoolStore } from './data/SchoolStore';
import { SchoolApi } from './api/SchoolApi';
export { SCHOOL_DETAILS_ENTITY_ID } from './ui/constants';

export { SchoolsPage } from './ui/SchoolsPage';

export { useSchoolService } from './module';
export { useSchoolColumns as GetSchoolColumns } from './ui/tableDefs';

export const initializeSchoolModuleContext = (backofficeFetcher: Fetcher): SchoolModuleContext => {
  const api = makeSchoolControllerApi(backofficeFetcher);
  const schoolApi = new SchoolApi('meal/school/search', backofficeFetcher, { pluralize: false });

  const schoolService = makeSchoolService(backofficeFetcher, api);
  return {
    school: {
      schoolService: schoolService,
      events: {
        onRefreshTable$: new Subject<{ itemId?: string }>(),
      },
      store: new SchoolStore(schoolApi),
    },
  };
};
