import { IAppTransFunction } from 'startup/utils';
import React from 'react';
import {
  EntrepreneurWorkspaceTypeEnum,
  IndividualWorkspaceTypeEnum,
  LegalWorkspaceTypeEnum,
  ProfileWorkspace,
} from 'apis-generated/mapper-processing-admin';
import { ClientRespDto } from 'apis-generated/mapper-sso-admin';

export const getWorkspaceName = (
  workspace: ProfileWorkspace | undefined,
  client: ClientRespDto
): string => {
  const data = workspace?.data;
  let workspaceName = '';
  switch (data?.type) {
    case IndividualWorkspaceTypeEnum.Individual:
      workspaceName = `${client.firstName} ${client.lastName}`;
      break;
    case LegalWorkspaceTypeEnum.Legal:
      workspaceName = data?.name;
      break;
    case EntrepreneurWorkspaceTypeEnum.Entrepreneur:
      workspaceName = data?.name;
      break;
  }
  return workspaceName;
};

export const getWorkspaceType = (
  workspace: ProfileWorkspace | undefined,
  t: IAppTransFunction
): string => {
  const data = workspace?.data;
  let type = '';
  switch (data?.type) {
    case IndividualWorkspaceTypeEnum.Individual:
      type = t('client_details_added_fi_workspace_type_individual');
      break;
    case LegalWorkspaceTypeEnum.Legal:
      type = t('client_details_added_fi_workspace_type_legal');
      break;
    case EntrepreneurWorkspaceTypeEnum.Entrepreneur:
      type = t('client_details_added_fi_workspace_type_entrepreneur');
      break;
  }
  return type;
};
