import { useGlobalContext } from '@pay/modules';
import { ReportService } from './services/ReportService';
import { CompanyService } from './services/CompanyService';
import { UserLocationsService } from './services/UserLocationsService';
import { BOMealUserService } from './services/BOMealUserService';
import { Subject } from 'rxjs';
import { DictionaryService } from './services/DictionaryService';

export interface ReportModuleContext {
  meals: {
    reportService: ReportService;
    companyService: CompanyService;
    userLocationsService: UserLocationsService;
    boMealUserService: BOMealUserService;
    dictionaryService: DictionaryService;
    events: {
      onRefreshTable$: Subject<{ itemId?: number | string }>;
    };
  };
}

export const useReportService = () => useGlobalContext<ReportModuleContext>().meals.reportService;
export const useCompanyService = () => useGlobalContext<ReportModuleContext>().meals.companyService;
export const useDictionaryService = () =>
  useGlobalContext<ReportModuleContext>().meals.dictionaryService;
export const useUserLocationsService = () =>
  useGlobalContext<ReportModuleContext>().meals.userLocationsService;
export const useBOMealUserService = () =>
  useGlobalContext<ReportModuleContext>().meals.boMealUserService;
export const useMealsEvents = () => useGlobalContext<ReportModuleContext>().meals.events;
