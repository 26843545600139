import React, { ErrorInfo } from 'react';
import { UnkownErrorPage } from './error/UnknownErrorPage';

interface IState {
  error: unknown | undefined;
}

export class ErrorBound extends React.Component<{}, IState> {
  public state = {
    error: undefined,
  };
  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error });
  }

  public render() {
    if (this.state.error) {
      return <UnkownErrorPage />;
    }
    return this.props.children;
  }
}
