import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';

import { SmsApiInterface } from 'apis-generated/mapper-sso-admin';
import { mapFetcherResultEither } from 'modules/common/fetcher';

export class ConfigurationService {
  constructor(private _api: SmsApiInterface) {}

  public getSmsStatus = () =>
    pipe(() => this._api.getSmsGatewayStatus(), T.map(mapFetcherResultEither));

  public updateSmsStatus = (enabled: boolean) =>
    pipe(
      () => this._api.updateSmsGateway({ updateSmsEnabledValueRequest: { value: enabled } }),
      T.map(mapFetcherResultEither)
    );
}
