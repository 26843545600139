/**
 * TODO: This is naive implementation. Non optimized!
 */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingOverlay, useNotificationsStore } from '@pay/admin-ui-kit';
import { combineValidators, makeRequiredValidator } from '@pay/common-utils';
import { Button, makeRhfMuiTextFieldProps, RifmTextField } from '@pay/mui-enhancement';
import { useModals } from '@pay/admin-ui-kit';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { pipe } from 'fp-ts/lib/function';

import {
  Box,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
  Menu,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Delete from '@material-ui/icons/Delete';

import { BackofficeUserRespDto, RoleRespDto } from 'apis-generated/mapper-sso-admin';
import { ITransKey } from 'startup/i18n';
import { COUNTRY_PHONE_CODE, ECountryCode, parsePhone } from 'modules/common/format-phone';
import { useAuthModals } from 'modules/auth';
import { ErrorAlert } from 'modules/common/ui/errors';
import { IRemoteError } from 'modules/common/store';
import { emailValidator } from 'modules/common/validators';
import { useTaskEitherImmediate } from 'modules/common/async/hooks';
import { EFormField, IFormValues } from './types';
import { I2FaVerificationError } from 'modules/auth/constants';
import { matchI } from 'modules/common/match';
import { extractErrorMessage } from 'modules/common/errors';
import { useTranslation } from 'startup/utils';
import { formatPhoneRifm, phoneValidator, userNameValidator } from './utils';

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    autocompleteInput: {
      '$root &': {
        padding: '0 4px',
      },
    },
  }),
  { name: 'UserEditForm' }
);

export enum EMode {
  Create,
  Edit,
  View,
}

interface IProps {
  onClose: () => void;
  mode: EMode;
  userCanEdit?: boolean;
  userCanLock?: boolean;
  originalUser?: BackofficeUserRespDto;
  // onFetchUser: (userId: string) => TE.TaskEither<ICommonError, UserRpModel>;
  onStartEdit?: () => void;
  onResetPassword: (
    userId: string,
    code: string
  ) => TE.TaskEither<IRemoteError | I2FaVerificationError, boolean>;
  onReset2Fa: (
    userId: string,
    code: string
  ) => TE.TaskEither<IRemoteError | I2FaVerificationError, unknown>;
  onSave?: (
    values: IFormValues,
    roles: string[]
  ) => TE.TaskEither<IRemoteError | { type: 'user-already-exists' }, unknown>;
  onDelete: (
    userId: string,
    code: string
  ) => TE.TaskEither<IRemoteError | I2FaVerificationError, undefined>;
  onRestore?: (
    userId: string,
    code: string
  ) => TE.TaskEither<IRemoteError | I2FaVerificationError, undefined>;
  onLockUnlock?: (
    user: BackofficeUserRespDto,
    reason: string,
    code: string
  ) => TE.TaskEither<IRemoteError | I2FaVerificationError, unknown>;
  onRefresh?: () => void;
  onLoadRoles: () => TE.TaskEither<IRemoteError | I2FaVerificationError, RoleRespDto[]>;
}
export const UserEditForm: FC<IProps> = ({
  onClose,
  onSave,
  mode,
  originalUser,
  userCanEdit,
  userCanLock,
  onResetPassword,
  onLockUnlock,
  onRefresh,
  onReset2Fa,
  onLoadRoles,
  onDelete,
  onRestore,
  ...props
}) => {
  const { t } = useTranslation();
  const notificationStore = useNotificationsStore();
  const classes = useStyles();
  const { showSecondAuthModal } = useAuthModals();
  const [roles, setRoles] = useState<RoleRespDto[]>([]);
  const [error, setError] = useState<IRemoteError | { type: 'user-already-exists' } | undefined>();

  const { showModal } = useModals();
  const rolesState = useTaskEitherImmediate(() => onLoadRoles());

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const rolesOptions = useMemo(() => {
    if (rolesState.state.value) {
      return rolesState.state.value;
    }
    return [];
  }, [rolesState.state.value]);

  useEffect(() => {
    if (rolesState.state.value) {
      const origingalRoles = originalUser?.roles?.map((role) => role.id);
      if (origingalRoles && origingalRoles.length) {
        const newRoles = rolesState.state.value.filter((role) => origingalRoles.includes(role.id));
        setRoles(newRoles);
      }
    }
  }, [originalUser, rolesState.state.value]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    formState,
  } = useForm<IFormValues>({
    defaultValues: {
      email: originalUser?.email,
      firstName: originalUser?.firstName,
      lastName: originalUser?.lastName,
      position: originalUser?.position,
      username: originalUser?.username,
      phone: originalUser?.phone ? parsePhone(originalUser.phone)?.[1] ?? '' : '',
    },
  });

  const handleRolesChange = useCallback(
    (ev: unknown, newRoles: RoleRespDto[] | null) => {
      setRoles(newRoles ?? []);
    },
    [setRoles]
  );

  const handleResetPassword = useCallback(async () => {
    if (!originalUser) {
      return;
    }
    handleMenuClose();
    showSecondAuthModal({
      onSubmit: (code) => {
        return pipe(
          onResetPassword(originalUser.id, code),
          TE.fold(
            (e) => T.of(e),
            (_) => {
              notificationStore.showNotification({
                text: t('user_editor_password_reset_success', { username: originalUser.username }),
                options: {
                  variant: 'success',
                },
              });
              return T.of(undefined);
            }
          )
        )();
      },
    });
  }, [handleMenuClose, notificationStore, onResetPassword, originalUser, showSecondAuthModal, t]);

  const handleResetSecondFactor = useCallback(async () => {
    if (!originalUser) {
      return;
    }
    handleMenuClose();
    showSecondAuthModal({
      onSubmit: (code) =>
        pipe(
          onReset2Fa(originalUser.id, code),
          TE.fold(
            (e) => T.of(e),
            (r) => {
              notificationStore.showNotification({
                text: t('user_editor_reset_second_auth_success', {
                  username: originalUser.username,
                }),
                options: {
                  variant: 'success',
                },
              });
              return T.of(undefined);
            }
          )
        )(),
    });
  }, [handleMenuClose, notificationStore, onReset2Fa, originalUser, showSecondAuthModal, t]);

  // const handleLockInternal = useCallback(
  //   (reason: string) => {
  //     if (!originalUser) {
  //       return;
  //     }
  //     showSecondAuthModal({
  //       onSubmit: (code) =>
  //         pipe(
  //           onLockUnlock(originalUser, reason, code),
  //           TE.fold(
  //             (e) => T.of(e),
  //             (r) => {
  //               notificationStore.showNotification({
  //                 text: t('user_editor_lock_success'),
  //                 options: { variant: 'success' },
  //               });
  //               onRefresh?.();
  //               return T.of(undefined);
  //             }
  //           )
  //         )(),
  //     });
  //   },
  //   [notificationStore, onLockUnlock, onRefresh, originalUser, showSecondAuthModal, t]
  // );

  // const handleUnlockInternal = useCallback(() => {
  //   if (!originalUser) {
  //     return;
  //   }
  //   showSecondAuthModal({
  //     onSubmit: (code) =>
  //       pipe(
  //         onLockUnlock(originalUser, '', code),
  //         TE.fold(
  //           (e) => T.of(e),
  //           (r) => {
  //             notificationStore.showNotification({
  //               text: t('user_editor_unlock_success'),
  //               options: { variant: 'success' },
  //             });
  //             onRefresh?.();
  //             return T.of(undefined);
  //           }
  //         )
  //       )(),
  //   });
  // }, [notificationStore, onLockUnlock, onRefresh, originalUser, showSecondAuthModal, t]);

  // const handleLockUnlock = useCallback(async () => {
  //   if (!originalUser) {
  //     return;
  //   }
  //   if (!originalUser.locked) {
  //     showModal(<UserLockModal onLock={handleLockInternal} open={false} user={originalUser} />);
  //   } else {
  //     handleUnlockInternal();
  //   }
  //   handleMenuClose();
  // }, [handleLockInternal, handleMenuClose, handleUnlockInternal, originalUser, showModal]);

  const handleDeleteUser = useCallback(() => {
    if (!originalUser) {
      return;
    }
    showSecondAuthModal({
      onSubmit: (code) => {
        return pipe(
          onDelete(originalUser.id, code),
          TE.fold(
            (e) => T.of(e),
            (_) => {
              onClose();
              notificationStore.showNotification({
                text: t('user_delete_success', { userName: originalUser.username }),
                options: { variant: 'success' },
              });
              return T.of(undefined);
            }
          )
        )();
      },
    });
  }, [notificationStore, onClose, onDelete, originalUser, showSecondAuthModal, t]);

  // const handleRestoreUser = useCallback(() => {
  //   if (!originalUser) {
  //     return;
  //   }
  //   showSecondAuthModal({
  //     onSubmit: (code) => {
  //       return pipe(
  //         onRestore(originalUser.id, code),
  //         TE.fold(
  //           (e) => T.of(e),
  //           (_) => {
  //             onRefresh?.();
  //             notificationStore.showNotification({
  //               text: t('user_restore_success', { userName: originalUser.username }),
  //               options: { variant: 'success' },
  //             });
  //             return T.of(undefined);
  //           }
  //         )
  //       )();
  //     },
  //   });
  // }, [notificationStore, onRefresh, onRestore, originalUser, showSecondAuthModal, t]);

  const handleSubmitInternal = useCallback(
    async (values: IFormValues) => {
      setError(undefined);
      if (!onSave) {
        return;
      }
      const phoneNumberRaw =
        values.phone && COUNTRY_PHONE_CODE[ECountryCode.Kk] + values.phone.replace(/ /g, '');

      return pipe(
        onSave(
          { ...values, phone: phoneNumberRaw },
          roles.map((role) => role.id)
        ),
        TE.fold(
          (e) => {
            setError(e);
            return T.of(undefined);
          },
          (r) => {
            onClose();
            return T.of(undefined);
          }
        )
      )();
    },
    [onClose, onSave, roles]
  );

  const validateEmail = useMemo(
    () =>
      combineValidators(makeRequiredValidator<ITransKey>('validation_required'), emailValidator),
    []
  );
  const validatePhone = useMemo(
    () =>
      combineValidators(makeRequiredValidator<ITransKey>('validation_required'), phoneValidator),
    []
  );

  if (rolesState.state.loading) {
    return <LoadingOverlay />;
  }

  const actionsEl = (() => {
    const actions: React.ReactElement[] = [];
    if (!userCanEdit || !originalUser) {
      return actions;
    }
    actions.push(
      <MenuItem key="reset-pwd" onClick={handleResetPassword}>
        {t('user_editor_password_reset')}
      </MenuItem>,
      <MenuItem key="reset-2auth" onClick={handleResetSecondFactor}>
        {t('user_editor_reset_second_auth')}
      </MenuItem>
    );
    // if (userCanLock) {
    //   actions.push(
    //     <MenuItem key="lock" onClick={handleLockUnlock}>
    //       {originalUser.locked ? t('user_editor_unlock') : t('user_editor_lock')}
    //     </MenuItem>
    //   );
    // }
    return actions;
  })();

  const errorText = error
    ? matchI(error)({
        'user-already-exists': () => t('user_already_exists'),
        unknown: (err) => extractErrorMessage(err, t),
      })
    : '';

  return (
    <>
      <DialogTitle> {t(TITLE_MODE_MAP[mode])}</DialogTitle>
      <DialogContent dividers className={classes.content}>
        <form autoComplete="off" id="UserEditForm" onSubmit={handleSubmit(handleSubmitInternal)}>
          <ErrorAlert error={errorText} />
          <Box mb={2}>
            <Typography gutterBottom>{t('user_editor_group_personal')}</Typography>
            <Divider />
          </Box>

          <Controller
            name={EFormField.Firstname}
            control={control}
            rules={{ validate: makeRequiredValidator<ITransKey>('validation_required') }}
            render={({ field }) => (
              <TextField
                size="small"
                margin="dense"
                disabled={mode === EMode.View || formState.isSubmitting}
                label={t(FIELD_LABEL_MAP[EFormField.Firstname])}
                autoFocus
                fullWidth
                {...makeRhfMuiTextFieldProps(errors.firstName, t)}
                {...field}
              />
            )}
          />
          <Controller
            name={EFormField.Lastname}
            control={control}
            rules={{ validate: makeRequiredValidator<ITransKey>('validation_required') }}
            render={({ field }) => (
              <TextField
                size="small"
                margin="dense"
                disabled={mode === EMode.View || formState.isSubmitting}
                label={t(FIELD_LABEL_MAP[EFormField.Lastname])}
                autoFocus
                fullWidth
                {...makeRhfMuiTextFieldProps(errors.lastName, t)}
                {...field}
              />
            )}
          />
          <Controller
            name={EFormField.Email}
            control={control}
            rules={{
              validate: validateEmail,
            }}
            render={({ field }) => (
              <TextField
                type="email"
                size="small"
                margin="dense"
                disabled={mode === EMode.View || formState.isSubmitting}
                label={t(FIELD_LABEL_MAP[EFormField.Email])}
                fullWidth
                {...makeRhfMuiTextFieldProps(errors.email, t)}
                {...field}
              />
            )}
          />
          <Controller
            name={EFormField.Phone}
            control={control}
            rules={{
              validate: validatePhone,
            }}
            render={({ field }) => (
              <RifmTextField
                rifm={{
                  format: formatPhoneRifm,
                  accept: /\d/g,
                }}
                type="tel"
                size="small"
                margin="dense"
                disabled={mode === EMode.View || formState.isSubmitting}
                label={t(FIELD_LABEL_MAP[EFormField.Phone])}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      +{COUNTRY_PHONE_CODE[ECountryCode.Kk]}
                    </InputAdornment>
                  ),
                }}
                {...makeRhfMuiTextFieldProps(errors.phone, t)}
                {...field}
              />
            )}
          />
          <Controller
            name={EFormField.Position}
            control={control}
            rules={{ validate: makeRequiredValidator<ITransKey>('validation_required') }}
            render={({ field }) => (
              <TextField
                size="small"
                margin="dense"
                disabled={mode === EMode.View || formState.isSubmitting}
                label={t(FIELD_LABEL_MAP[EFormField.Position])}
                autoFocus
                fullWidth
                {...makeRhfMuiTextFieldProps(errors.position, t)}
                {...field}
              />
            )}
          />
          <Box mb={2}>
            <Typography gutterBottom>{t('user_editor_group_login_info')}</Typography>
            <Divider />
          </Box>
          <Controller
            name={EFormField.Username}
            control={control}
            rules={{
              validate: userNameValidator,
            }}
            render={({ field }) => (
              <TextField
                size="small"
                margin="dense"
                disabled={mode === EMode.Edit || mode === EMode.View}
                label={t(FIELD_LABEL_MAP[EFormField.Username])}
                fullWidth
                autoComplete="off"
                {...makeRhfMuiTextFieldProps(errors.username, t)}
                {...field}
              />
            )}
          />
          <Box mb={2}>
            <Typography gutterBottom>{t('user_editor_group_roles')}</Typography>
            <Divider />
          </Box>
          <Autocomplete<RoleRespDto, true>
            classes={{
              input: classes.autocompleteInput,
            }}
            multiple
            value={roles}
            onChange={handleRolesChange}
            size="small"
            options={rolesOptions}
            filterSelectedOptions
            disabled={mode === EMode.View || formState.isSubmitting}
            getOptionLabel={(it) => it.name}
            noOptionsText={t('common_table_empty')}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip size="small" label={option.name} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField margin="dense" {...params} label={t('user_field_roles')} />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button disableElevation variant="text" onClick={onClose}>
          {mode === EMode.View ? t('common_close') : t('common_cancel')}
        </Button>
        {mode === EMode.Edit && userCanEdit && originalUser && (
          <Button
            startIcon={<Delete />}
            variant="contained"
            color="default"
            onClick={handleDeleteUser}
          >
            {t('common_delete')}
          </Button>
        )}
        {mode === EMode.View && actionsEl.length > 0 && (
          <>
            <Button
              variant="contained"
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              {t('user_editor_security_btn')}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
            >
              {actionsEl}
            </Menu>
          </>
        )}
        {mode === EMode.View && userCanEdit && (
          <Button disableElevation variant="contained" color="primary" onClick={props.onStartEdit}>
            {t('common_edit')}
          </Button>
        )}
        {mode !== EMode.View && (
          <Button
            form="UserEditForm"
            loading={formState.isSubmitting}
            disableElevation
            type="submit"
            onClick={handleSubmit(handleSubmitInternal)}
            disabled={Object.keys(errors).length > 0}
            variant="contained"
            color="primary"
          >
            {t('common_save')}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

const TITLE_MODE_MAP: Record<EMode, ITransKey> = {
  [EMode.Create]: 'user_editor_create_title',
  [EMode.View]: 'user_editor_view_title',
  [EMode.Edit]: 'user_editor_edit_title',
};

const FIELD_LABEL_MAP: Record<EFormField, ITransKey> = {
  [EFormField.Firstname]: 'user_field_first_name',
  [EFormField.Lastname]: 'user_field_last_name',
  [EFormField.Email]: 'user_field_email',
  [EFormField.Phone]: 'user_field_phone_number',
  [EFormField.Username]: 'user_field_login',
  [EFormField.Roles]: 'user_field_roles',
  [EFormField.Position]: 'user_field_position',
};
