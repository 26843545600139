import { IFetcherFailedResult, IFetcherResult, IFetcherSuccessResult } from '@pay/data-fetching';

export const wrapMockSuccessRespone = <T>(body: T): IFetcherSuccessResult<T> => {
  return {
    body: body,
    success: true,
    rawResponse: undefined as any,
  };
};

export const emptyFailedResponse: IFetcherFailedResult<unknown> = {
  success: false,
  response: undefined,
};
