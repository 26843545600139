import { useAsync } from 'react-use';
import { IFetcherResult } from '@pay/data-fetching';
import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

type IFetchingFunc<TSuccess, TError> = () => Promise<IFetcherResult<TSuccess, TError>>;
export const useFetcherRequest = <TSuccess, TError>(fn: IFetchingFunc<TSuccess, TError>) => {
  const { value, loading } = useAsync(fn);
  const result = value?.success && value?.body;
  const error = value?.success === false ? value.response && value.serverError : undefined;
  return { loading, result, error };
};

type UnwrapPromise<T extends Promise<any>> = T extends Promise<infer U> ? U : never;
// This is basically the same as useAsync, but we don't have error, because we never throw exception,
// so to ease the development and avoid unnecesary null checks we create custom hook
export type IUseFetchingState<T> =
  | {
      loading: false;
      result: T;
    }
  | {
      loading: true;
      result: undefined;
    };
export const useFetching = <TFn extends () => Promise<any>>(
  fn: TFn
): [IUseFetchingState<UnwrapPromise<ReturnType<TFn>>>, () => Promise<void>] => {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState<UnwrapPromise<ReturnType<TFn>> | undefined>(undefined);

  const execute = useCallback(async () => {
    setIsLoading(true);
    const value = await fn();
    ReactDOM.unstable_batchedUpdates(() => {
      setResult(value);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    execute();
  }, []);

  if (isLoading) {
    return [
      {
        loading: true,
        result: undefined,
      },
      execute,
    ];
  }

  return [
    {
      loading: false,
      result: result!,
    },
    execute,
  ];
};
