import { Column, FilterType, TextFilterFormat } from '@pay/admin-data-table';
import { IAppTransFunction } from 'startup/utils';
import React, { useMemo } from 'react';
import { Delete } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';
import { SchoolViewDTO } from 'apis-generated/school-meal/models/SchoolViewDTO';
import { useCompanyService } from 'modules/meals/module';
import { pipe } from 'fp-ts/lib/function';
import { TEtoPromise } from 'modules/common/fpts-utils';
import { ServiceCompanyDto } from 'apis-generated/reports';

export const useSchoolColumns = (
  t: IAppTransFunction,
  onEditSchool: (school: SchoolViewDTO) => void,
  onDeleteSchool: (school: SchoolViewDTO) => void
): Column<SchoolViewDTO>[] => {
  const { getServiceCompanies } = useCompanyService();

  const serviceCompaniesMap = useMemo(() => new Map<string, ServiceCompanyDto>(), []);

  useMemo(async () => {
    const serviceCompanies = await pipe(getServiceCompanies(), TEtoPromise)();
    serviceCompanies.forEach((s) => {
      serviceCompaniesMap.set(s.code!!, s);
    });
  }, [getServiceCompanies, serviceCompaniesMap]);

  return useMemo(
    (): Column<SchoolViewDTO>[] => [
      {
        accessor: 'bin',
        Header: t('meal_school_bin_column'),
        sorting: {
          rsqlOptions: {
            field: 'bin',
          },
        },
        customFilter: {
          field: 'bin',
          format: TextFilterFormat.Text,
          type: FilterType.Text,
        },
        Cell: ({ row: { original }, value }) => (
          <Button onClick={() => onEditSchool(original)}>{value}</Button>
        ),
      },
      {
        accessor: 'name',
        Header: t('meal_school_name_ru_column'),
        sortable: false,
        customFilter: {
          field: 'name',
          format: TextFilterFormat.Text,
          type: FilterType.Text,
        },
      },
      {
        accessor: 'number',
        Header: t('meal_school_number_column'),
        sortable: false,
        customFilter: {
          field: 'number',
          format: TextFilterFormat.Text,
          type: FilterType.Text,
        },
      },
      {
        accessor: 'serviceCompany',
        Header: t('meal_school_service_company_column'),
        sortable: true,
        Cell: ({ value }) => {
          return <>{serviceCompaniesMap.get(value)?.name} </>;
        },
      },
      {
        accessor: 'location',
        Header: t('meal_school_location_column'),
        sortable: true,
      },

      //TODO: temp solutions, need update @pay/admin-data-table package
      // blocked by https://jira.aitu-team.kz/browse/MAP-4262
      {
        id: 'delete',
        accessor: '' as any,
        width: 60,
        disableResizing: true,
        horizontalAlign: 'center',
        Cell: ({ row: { original } }: any) => {
          return (
            <IconButton size="small" onClick={() => onDeleteSchool(original)}>
              <Delete />
            </IconButton>
          );
        },
      },
    ],
    [onDeleteSchool, onEditSchool, serviceCompaniesMap, t]
  );
};
