import { DataTable, TableToolbar, useTable } from '@pay/admin-data-table';
import { useNotificationsStore } from '@pay/admin-ui-kit';
import React, { useMemo, FC } from 'react';
import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';

import {
  DownloadPrivacyPolicyTypeEnum,
  PrivacyPolicyUpdateScheduleRespDto,
} from 'apis-generated/mapper-sso-admin';
import { useTranslation } from 'startup/utils';
import { usePrivacyPolicyService } from '../../module';
import { getPrivacyPolicyChangeRequestsColumns } from './tableDefs';
import { downloadFile } from 'modules/common/utils';
import { EApiLang } from 'startup/i18n';

export const PrivacyPolicyChangeRequestsPane: FC = () => {
  const { t } = useTranslation();
  const privacyPolicyService = usePrivacyPolicyService();
  const { showNotification } = useNotificationsStore();

  const columns = useMemo(
    () =>
      getPrivacyPolicyChangeRequestsColumns(
        t,
        (dto: PrivacyPolicyUpdateScheduleRespDto, lang: EApiLang) => {
          pipe(
            privacyPolicyService.downloadPrivacyPolicy(
              dto.id,
              DownloadPrivacyPolicyTypeEnum.Activation,
              lang
            ),
            TE.map((file) => downloadFile(file, lang === 'kk' ? dto.nameKz : dto.nameRu)),
            TE.mapLeft(() => {
              showNotification({
                text: t('common_download_error'),
                options: { variant: 'error' },
              });
            })
          )();
        }
      ),
    [t, privacyPolicyService, showNotification]
  );

  const { tableProps, toolBarProps } = useTable<PrivacyPolicyUpdateScheduleRespDto>({
    fetchData: privacyPolicyService.fetchPrivacyPolicyChangeRequests,
    pageSize: 20,
    columns,
    keyGetter: (it) => it.id,
  });

  return (
    <>
      <TableToolbar<PrivacyPolicyUpdateScheduleRespDto> {...toolBarProps} columns={columns} />
      <DataTable<PrivacyPolicyUpdateScheduleRespDto> {...tableProps} />
    </>
  );
};
