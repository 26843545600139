import { IFetcher } from '@pay/data-fetching/dist/Fetcher/IFetcher';

import { LegalAgreementModuleContext } from './module';
import { makeAgreementApi } from 'api';
import { LegalAgreementService } from './service/LegalAgreementService';

export { NewAgreementModal } from './ui/NewAgreementModal';
export { LegalAgreementPage } from './ui/LegalAgreementPage';

export const initializeLegalAgreementModuleContext = (
  ssoFetcher: IFetcher
): LegalAgreementModuleContext => {
  const agreementApi = makeAgreementApi(ssoFetcher);

  const service = new LegalAgreementService(agreementApi, ssoFetcher);

  return {
    legalAgreement: {
      service,
    },
  };
};
