import { Subject } from 'rxjs';
import { useGlobalContext } from '@pay/modules';
import { SchoolService } from './services/SchoolService';
import { SchoolStore } from './data/SchoolStore';

export interface SchoolModuleContext {
  school: {
    schoolService: SchoolService;
    events: {
      onRefreshTable$: Subject<{ itemId?: string }>;
    };
    store: SchoolStore;
  };
}

export const useSchoolService = () => useGlobalContext<SchoolModuleContext>().school.schoolService;
export const useSchoolEvents = () => useGlobalContext<SchoolModuleContext>().school.events;
export const useSchoolStore = () => useGlobalContext<SchoolModuleContext>().school.store;
