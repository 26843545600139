import {
  BOPdfQRRequest,
  FinancialOrganizationApiInterface,
} from 'apis-generated/mapper-processing-admin';

import * as T from 'fp-ts/Task';
import { mapFetcherResultEither } from 'modules/common/fetcher';
import { pipe } from 'fp-ts/lib/function';

export const makeStaticQrService = (api: FinancialOrganizationApiInterface) => {
  return {
    downloadStaticQr: (requestBody: BOPdfQRRequest) => {
      return pipe(
        () =>
          api.financialOrganizationGenerateQrPdf({
            bOPdfQRRequest: { ...requestBody, financialId: 'kp' },
          }),
        T.map(mapFetcherResultEither)
      );
    },
  };
};

export type StaticQrService = ReturnType<typeof makeStaticQrService>;
