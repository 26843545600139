import { IFetcherFailedResult, IFetcherResult } from '@pay/data-fetching';
import { ECommonError, IRemoteError } from './store';
import * as E from 'fp-ts/Either';

export const mapFetcherResultEither = <TResult>(
  result: IFetcherResult<TResult>
): E.Either<IRemoteError, TResult> => {
  if (result.success) {
    return E.right(result.body);
  }
  return E.left(mapRemoteError(result));
};

export const mapFetcherResultEither2 = <TResult, TL>(
  onError: (res: IFetcherFailedResult<unknown>) => TL
) => (result: IFetcherResult<TResult>): E.Either<TL, TResult> => {
  if (result.success) {
    return E.right(result.body);
  }
  return E.left(onError(result));
};

export const mapRemoteError = (fetcherResult: IFetcherFailedResult<any>): IRemoteError => {
  if (!fetcherResult.success && fetcherResult.response && fetcherResult.serverError) {
    return {
      type: ECommonError.Unknown,
      serverError: fetcherResult.serverError,
    };
  }
  return {
    type: ECommonError.Unknown,
  };
};
