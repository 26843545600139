import { IFetcher } from '@pay/data-fetching/dist/Fetcher/IFetcher';
import { ClientApiInterface } from 'apis-generated/mapper-sso-admin';
import { ClientApiInterface as ClientControlApiInterface } from 'apis-generated/mapper-processing-admin';
import { formatDate } from 'modules/common/format';
import { DATE_FORMAT } from 'modules/common/constants';

export const makeClientApi = (fetcher: IFetcher) => {
  const api: ClientApiInterface = {
    activateUser: (args) => fetcher.put(`bo/client/activate/${args.username}`),

    blockUser: (args) => fetcher.put(`bo/client/block/${args.username}`),

    deregister: (args) => {
      const url = `bo/client/deregister/${args.registrationId}`;
      return fetcher.delete(url);
    },

    userDevices: (args) => {
      const url = `bo/client/devices/${args.username}`;
      return fetcher.get(url);
    },
    /**
     * Use ClientService.ts instead of this method
     */
    getClientDetails(pathParams) {
      throw new Error('Method not supported');
    },
    /**
     * Use ClientService.ts instead of this method
     */
    getClients(queryParams) {
      throw new Error('Method not supported');
    },
    getClientsLog(queryParams) {
      throw new Error('Method not supported');
    },
    getToDeleteClients(queryParams) {
      throw new Error('Method not supported');
    },
    getClientsStats(queryParams) {
      return fetcher.get(
        `bo/clients/stats?from=${formatDate(
          queryParams.from,
          DATE_FORMAT.API_DATE_ONLY
        )}&to=${formatDate(queryParams.to, DATE_FORMAT.API_DATE_ONLY)}`
      );
    },
  };
  return api;
};

export const makeClientControlApi = (fetcher: IFetcher) => {
  const api: ClientControlApiInterface = {
    getPhoto(pathParams) {
      const url = `bo/client/photo/${pathParams.username}`;
      return fetcher.get(url);
    },
    getUserWorkspaceFinancialBinds(pathParams) {
      const url = `bo/client/financial/binds/${pathParams.workspaceId}`;
      return fetcher.get(url);
    },
    getUserWorkspaceFinancials(pathParams) {
      const url = `bo/client/financials/${pathParams.username}`;
      return fetcher.get(url);
    },
    unbindDevice(pathParams) {
      const url = `bo/client/unbind-device/${pathParams.deviceId}/${pathParams.workspaceId}/${pathParams.bin}`;
      return fetcher.delete(url);
    },
    unbindWorkspace(pathParams) {
      const url = `bo/client/unbind-workspace/${pathParams.workspaceId}/${pathParams.bin}`;
      return fetcher.delete(url);
    },
  };
  return api;
};
