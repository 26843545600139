import { difference } from 'lodash-es';
import { EPermissionType } from 'modules/user-management';
import { serializable, custom, list, raw } from 'serializr';

export class CurrentUserModel {
  @serializable
  public id: string = '';
  @serializable
  public username: string = '';
  @serializable(list(raw()))
  public roles: { id: string; name: string }[] = [];
  @serializable
  public firstName: string = '';
  @serializable
  public lastName: string = '';
  public get fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ');
  }
  @serializable(
    list(
      custom(
        (v) => btoa(v),
        (v) => atob(v)
      )
    )
  )
  public permissions: EPermissionType[] = [];

  public hasAllPermissions(permissions: EPermissionType[]): boolean {
    if (this.permissions.includes(EPermissionType.Superadmin)) {
      return true;
    }
    const missingPerms = difference(permissions, this.permissions);
    return missingPerms.length === 0;
  }

  public hasOneOfPermissions(permissions: EPermissionType[]): boolean {
    if (this.permissions.includes(EPermissionType.Superadmin)) {
      return true;
    }
    const hasAny = permissions.some((perm) => this.permissions.includes(perm));
    return hasAny;
  }

  public isSuperAdmin = () => {
    return this.hasOneOfPermissions([EPermissionType.Superadmin]);
  };
}
