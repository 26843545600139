import { FilterType, TextFilterFormat, Column, TextFilterMatchType } from '@pay/admin-data-table';
import { DetailsLinkContainer } from '@pay/admin-ui-kit';
import { ClientRespDto, ToDeleteClientRespDto } from 'apis-generated/mapper-sso-admin';
import React from 'react';
import { IAppTransFunction } from 'startup/utils';
import { CLIENT_DETAIL_ENTITY_ID } from '../constants';
import { formatDate } from 'modules/common/format';
import { DATE_FORMAT } from 'modules/common/constants';
import { formatInteger, formatNumeric } from '@pay/common-utils';
import { RIFM_PHONE_WITH_PLUS } from 'modules/common/rifm';

export const getClientsColumns = (t: IAppTransFunction): Column<ClientRespDto>[] => {
  return [
    {
      accessor: 'username',
      Header: t('clients_column_phone'),
      customFilter: {
        field: 'username',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
        rifmOptions: RIFM_PHONE_WITH_PLUS,
      },
      Cell: ({ row: { original }, value }) => {
        return (
          <DetailsLinkContainer
            title={value}
            entityType={CLIENT_DETAIL_ENTITY_ID}
            entityId={original.clientId}
          />
        );
      },
    },
    {
      accessor: 'firstName',
      Header: t('clients_column_first_name'),
      customFilter: {
        field: 'firstName',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
      Cell: ({ row: { original }, value }) => value || '',
    },
    {
      accessor: 'lastName',
      Header: t('clients_column_last_name'),
      customFilter: {
        field: 'lastName',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
      Cell: ({ row: { original }, value }) => value || '',
    },
    {
      accessor: 'iin',
      Header: t('clients_column_iin'),
      customFilter: {
        field: 'iin',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },
    {
      accessor: 'clientId',
      Header: t('clients_column_id'),
      customFilter: {
        field: 'id',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
        match: TextFilterMatchType.Exact,
      },
    },
    {
      accessor: 'active',
      Header: t('clients_column_active'),
      customFilter: {
        field: 'active',
        type: FilterType.Select,
        values: [
          {
            title: t('client_status_active'),
            value: 'true',
          },
          {
            title: t('client_status_blocked'),
            value: 'false',
          },
        ],
      },
      sortable: false,
      Cell: ({ value }) => {
        return value ? t('client_status_active') : t('client_status_blocked');
      },
    },
    /*{
      accessor: 'tempDevicesCount',
      Header: t('clients_column_devices_count'),
      customFilter: {
        field: 'tempDevicesCount',
        format: TextFilterFormat.Integer,
        type: FilterType.Text,
      },
    },
    {
      accessor: 'blackboxAccountCount',
      Header: t('clients_column_ws_count'),
      customFilter: {
        field: 'blackboxAccountCount',
        format: TextFilterFormat.Integer,
        type: FilterType.Text,
      },
    },*/
    // {
    //   accessor: 'tempStatus',
    //   Header: t('clients_column_status'),
    //   customFilter: {
    //     field: 'tempStatus',
    //     type: FilterType.Select,
    //     values: getEnumValues<ETempClientDtoStatusEnum>(ETempClientDtoStatusEnum).map(val => ({
    //       title: t(CLIENT_STATUS_TRANS_MAP[val]),
    //       value: val,
    //     })),
    //   },
    // },
    /*{
      accessor: 'lastActive',
      Header: t('clients_column_registration_date'),
      Cell: ({ row: { original }, value }) => (
        <>{formatDate(value, DATE_FORMAT.DD_MM_YYYY_HH_mm)}</>
      ),
      customFilter: {
        field: 'registrationDate',
        type: FilterType.DateRange,
      },
    },*/
  ];
};

/**
 * 	ИИН, ФИО, номер телефона, кол-во устройств, кол-во WS, дата подключения
 */

export const getDeleteRequestClientsColumns = (
  t: IAppTransFunction
): Column<ToDeleteClientRespDto>[] => {
  return [
    {
      accessor: 'deleteRequestedAt',
      Header: t('common_date'),
      Cell: ({ row: { original }, value }) => (
        <>{value ? formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm) : null}</>
      ),
      sorting: {
        rsqlOptions: {
          field: 'createdAt',
        },
      },
      customFilter: {
        field: 'createdAt',
        type: FilterType.DateRange,
      },
    },
    {
      accessor: 'username',
      Header: t('clients_column_phone'),
      customFilter: {
        field: 'user.username',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
        rifmOptions: RIFM_PHONE_WITH_PLUS,
      },
      sorting: {
        rsqlOptions: {
          field: 'user.username',
        },
      },
      Cell: ({ row: { original }, value }) => {
        return (
          <DetailsLinkContainer
            title={value}
            entityType={CLIENT_DETAIL_ENTITY_ID}
            entityId={original.clientId}
          />
        );
      },
    },
    {
      accessor: 'firstName',
      Header: t('clients_column_first_name'),
      customFilter: {
        field: 'user.firstName',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
      sorting: {
        rsqlOptions: {
          field: 'user.firstName',
        },
      },
      Cell: ({ row: { original }, value }) => value || '',
    },

    {
      accessor: 'lastName',
      Header: t('clients_column_last_name'),
      customFilter: {
        field: 'user.lastName',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
      sorting: {
        rsqlOptions: {
          field: 'user.lastName',
        },
      },
      Cell: ({ row: { original }, value }) => value || '',
    },
    {
      accessor: 'iin',
      Header: t('clients_column_iin'),
      customFilter: {
        field: 'user.iin',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
      sorting: {
        rsqlOptions: {
          field: 'user.iin',
        },
      },
    },

    // {
    //   accessor: 'active',
    //   Header: t('clients_column_active'),
    //   sortable: false,
    //   Cell: ({ value }) => {
    //     return value ? t('client_status_active') : t('client_status_blocked');
    //   },
    // },
    /*{
      accessor: 'tempDevicesCount',
      Header: t('clients_column_devices_count'),
      customFilter: {
        field: 'tempDevicesCount',
        format: TextFilterFormat.Integer,
        type: FilterType.Text,
      },
    },
    {
      accessor: 'blackboxAccountCount',
      Header: t('clients_column_ws_count'),
      customFilter: {
        field: 'blackboxAccountCount',
        format: TextFilterFormat.Integer,
        type: FilterType.Text,
      },
    },*/
    // {
    //   accessor: 'tempStatus',
    //   Header: t('clients_column_status'),
    //   customFilter: {
    //     field: 'tempStatus',
    //     type: FilterType.Select,
    //     values: getEnumValues<ETempClientDtoStatusEnum>(ETempClientDtoStatusEnum).map(val => ({
    //       title: t(CLIENT_STATUS_TRANS_MAP[val]),
    //       value: val,
    //     })),
    //   },
    // },
    /*{
      accessor: 'lastActive',
      Header: t('clients_column_registration_date'),
      Cell: ({ row: { original }, value }) => (
        <>{formatDate(value, DATE_FORMAT.DD_MM_YYYY_HH_mm)}</>
      ),
      customFilter: {
        field: 'registrationDate',
        type: FilterType.DateRange,
      },
    },*/
  ];
};
