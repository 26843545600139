import { DataTable, TableToolbar, useTable } from '@pay/admin-data-table';
import React, { useMemo, FC } from 'react';

import { useTranslation } from 'startup/utils';
import { useClientsStore } from '../module';
import { getClientsColumns } from './tableDefs';
import { ClientRespDto } from 'apis-generated/mapper-sso-admin';

export enum ETab {
  History = '',
  ChangeRequests = 'changeRequests',
}

interface IProps {}
export const ClientsTable: FC<IProps> = () => {
  const { t } = useTranslation();
  const store = useClientsStore();

  const columns = useMemo(() => getClientsColumns(t), [t]);

  const { tableProps, toolBarProps } = useTable<ClientRespDto>({
    fetchData: store.loadItems,
    pageSize: 20,
    columns,
    keyGetter: (it) => it.clientId,
  });

  return (
    <>
      <TableToolbar<ClientRespDto> {...toolBarProps} columns={columns} />
      <DataTable<ClientRespDto> {...tableProps} />
    </>
  );
};
