import { LegalIdRoleEnum, LegalParticipants } from 'apis-generated/mapper-processing-admin';
import { formatDate } from 'modules/common/format';
import { DATE_FORMAT } from 'modules/common/constants';
import { useTranslation } from 'startup/utils';
import { EPermissionType } from 'modules/user-management';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import React, { useCallback, useMemo } from 'react';
import { usePermissionsCheck } from 'modules/auth/hooks/usePermissionsCheck';

import { CellProps } from 'react-table';
import { IconButton } from '@material-ui/core';
import { Delete, Lock, LockOpen } from '@material-ui/icons';
import { Column, FilterType, TextFilterFormat } from '@pay/admin-data-table';
import { SimplePopup, useModals, useNotificationsStore } from '@pay/admin-ui-kit';
import { useWorkspaceService } from 'modules/clients/module';
import { ITransKey } from 'startup/i18n';

export const useBusinessAccountParticipantsColumns = (
  onRefreshTable: () => void
): Column<LegalParticipants>[] => {
  const { t } = useTranslation();
  const { showModal } = useModals();
  const { showNotification } = useNotificationsStore();

  const { deleteWorkspace, blockWorkspace, unblockWorkspace } = useWorkspaceService();

  const canWorkspaceWrite = usePermissionsCheck([
    EPermissionType.Superadmin,
    EPermissionType.WorkspaceWrite,
  ]);

  const handleLock = useCallback(
    (participant: LegalParticipants) => {
      const fullName = participant.fullName ?? '';
      showModal((close) => (
        <SimplePopup
          title={t('business_participants_lock')}
          onOk={() => {
            return pipe(
              blockWorkspace(participant.id),
              TE.match(
                () => {
                  showNotification({
                    text: t('business_participants_lock_failured', { fullName }),
                    options: { variant: 'error' },
                  });
                  close();
                },
                () => {
                  showNotification({
                    text: t('business_participants_lock_successed', { fullName }),
                    options: { variant: 'success' },
                  });
                  onRefreshTable();
                  close();
                }
              )
            )();
          }}
          okLabel={t('action_lock')}
          content={t('business_participants_lock_ask', { fullName })}
        />
      ));
    },
    [blockWorkspace, showModal, showNotification, onRefreshTable, t]
  );

  const handleUnLock = useCallback(
    (participant: LegalParticipants) => {
      const fullName = participant.fullName ?? '';
      showModal((close) => (
        <SimplePopup
          title={t('business_participants_unlock')}
          onOk={() => {
            return pipe(
              unblockWorkspace(participant.id),
              TE.match(
                () => {
                  showNotification({
                    text: t('business_participants_unlock_failured', { fullName }),
                    options: { variant: 'error' },
                  });
                  close();
                },
                () => {
                  showNotification({
                    text: t('business_participants_unlock_successed', { fullName }),
                    options: { variant: 'success' },
                  });
                  onRefreshTable();
                  close();
                }
              )
            )();
          }}
          okLabel={t('action_unlock')}
          content={t('business_participants_unlock_ask', { fullName })}
        />
      ));
    },
    [unblockWorkspace, showModal, showNotification, onRefreshTable, t]
  );

  const handleDelete = useCallback(
    (participant: LegalParticipants) => {
      const fullName = participant.fullName ?? '';
      showModal((close) => (
        <SimplePopup
          title={t('business_participants_delete')}
          onOk={() => {
            return pipe(
              deleteWorkspace(participant.id),
              TE.match(
                () => {
                  showNotification({
                    text: t('business_participants_unlock_failured', { fullName }),
                    options: { variant: 'error' },
                  });
                  close();
                },
                () => {
                  showNotification({
                    text: t('business_participants_delete_successed', { fullName }),
                    options: { variant: 'success' },
                  });
                  onRefreshTable();
                  close();
                }
              )
            )();
          }}
          okLabel={t('common_delete')}
          content={t('business_participants_delete_ask', { fullName })}
        />
      ));
    },
    [deleteWorkspace, showModal, showNotification, onRefreshTable, t]
  );

  return useMemo(() => {
    const actionColumns: Column<LegalParticipants>[] = canWorkspaceWrite
      ? [
          {
            id: 'lock-unlock',
            width: 60,
            disableResizing: true,
            horizontalAlign: 'center',
            Cell: ({ row: { original } }: CellProps<LegalParticipants>) =>
              original.active ? (
                <IconButton size="small" onClick={() => handleLock(original)}>
                  <Lock />
                </IconButton>
              ) : (
                <IconButton size="small" onClick={() => handleUnLock(original)}>
                  <LockOpen />
                </IconButton>
              ),
            sortable: false,
          },
          {
            id: 'delete',
            width: 60,
            disableResizing: true,
            horizontalAlign: 'center',
            Cell: ({ row: { original } }: CellProps<LegalParticipants>) => (
              <IconButton size="small" onClick={() => handleDelete(original)}>
                <Delete />
              </IconButton>
            ),
            sortable: false,
          },
        ]
      : [];

    const commonColumns: Column<LegalParticipants>[] = [
      {
        accessor: 'fullName',
        Header: t('business_accounts_column_name'),
        customFilter: {
          field: 'fullName',
          format: TextFilterFormat.Text,
          type: FilterType.Text,
        },
      },

      {
        accessor: 'iin',
        Header: t('business_accounts_column_identifier'),
        customFilter: {
          field: 'iin',
          format: TextFilterFormat.Text,
          type: FilterType.Text,
        },
      },

      {
        accessor: 'phone',
        Header: t('user_field_phone_number'),
        customFilter: {
          field: 'phone',
          format: TextFilterFormat.Text,
          type: FilterType.Text,
        },
      },

      {
        accessor: 'role',
        Header: t('business_accounts_participant_role'),
        Cell: ({ value }) => value && <>{t(ROLE_LABEL_MAP[value])}</>,
        customFilter: {
          field: 'role',
          type: FilterType.Text,
          format: TextFilterFormat.Text,
        },
      },

      {
        accessor: 'lastActive',
        Header: t('business_accounts_participant_date_of_last_activity'),
        Cell: ({ value }) => (
          <>{value ? formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm) : null}</>
        ),
        customFilter: {
          field: 'lastActive',
          type: FilterType.DateRange,
        },
      },
    ];
    return [...commonColumns, ...actionColumns];
  }, [t, canWorkspaceWrite, handleDelete, handleLock, handleUnLock]);
};

export const ROLE_LABEL_MAP: Record<LegalIdRoleEnum | string, ITransKey> = {
  [LegalIdRoleEnum.Employee]: 'business_accounts_participant_role_employee',
  [LegalIdRoleEnum.EmployeeWithSignPrivilege]:
    'business_accounts_participant_role_employee_with_sign_privilege',
  [LegalIdRoleEnum.Head]: 'business_accounts_participant_role_head',
  [LegalIdRoleEnum.Hr]: 'business_accounts_participant_role_hr',
};
