import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';

import { mapFetcherResultEither } from 'modules/common/fetcher';
import { DictionaryControllerApiInterface } from 'apis-generated/reports';

export const makeComapnyService = (api: DictionaryControllerApiInterface) => {
  return {
    getServiceCompanies: () => {
      return pipe(() => api.getServiceCompanies(), T.map(mapFetcherResultEither));
    },
  };
};

export type CompanyService = ReturnType<typeof makeComapnyService>;
