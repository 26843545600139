import { Column, FilterType, TextFilterFormat } from '@pay/admin-data-table';
import { DetailsLinkContainer } from '@pay/admin-ui-kit';
import {
  EPermissionType,
  PERMISSION_TRANS_MAP,
  ROLE_DETAILS_ENTITY_ID,
} from 'modules/user-management/constants';
import React from 'react';
import { IAppTransFunction } from 'startup/utils';
import { RoleRespDto } from 'apis-generated/mapper-sso-admin';

export const getRoleColumns = (t: IAppTransFunction): Column<RoleRespDto>[] => {
  return [
    {
      accessor: 'name',
      width: 80,
      Header: t('roles_table_column_name'),
      Cell: ({ row: { original }, value }) => (
        <>
          <DetailsLinkContainer
            entityId={original.id}
            entityType={ROLE_DETAILS_ENTITY_ID}
            title={value}
          />
        </>
      ),
      customFilter: {
        type: FilterType.Text,
        field: 'name',
        format: TextFilterFormat.Text,
      },
    },
    {
      accessor: 'permissions',
      width: 300,
      Header: t('roles_field_description'),
      Cell: ({ value }) => {
        return (
          <>
            {(value ?? [])
              .map((perm) => t(PERMISSION_TRANS_MAP[perm.name! as EPermissionType]))
              .join(', ')}
          </>
        );
      },
    },
    // {
    //   accessor: 'descr',
    //   width: 100,
    //   Header: t('roles_field_description'),
    // },
    // {
    //   accessor: 'permissions',
    //   width: 300,
    //   Header: t('roles_field_permissions'),
    //   Cell: ({ value }) => (
    //     <>
    //       {(value ?? [])
    //         .map((perm) => t(PERMISSION_TRANS_MAP[perm.name! as EPermissionType]))
    //         .join(', ')}
    //     </>
    //   ),
    // },
    // {
    //   Header: t('role_field_status'),
    //   horizontalAlign: 'center',
    //   sortable: false,
    //   Cell: ({ row: { original } }: CellProps<RoleRpModel>) => (
    //     <>
    //       {original.deleted ? (
    //         <BadgeCell color="error">{t('role_status_deleted')}</BadgeCell>
    //       ) : (
    //         <BadgeCell color="success">{t('role_status_active')}</BadgeCell>
    //       )}
    //     </>
    //   ),
    // },
  ];
};
