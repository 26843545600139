import { Fetcher } from '@pay/data-fetching';
import { Subject } from 'rxjs';
import { ReportModuleContext } from './module';
import { makeReportService } from './services/ReportService';
import { makeComapnyService } from './services/CompanyService';
import { makeCompanyServiceApi, makeBOMealUserApi, makeReportApi } from 'api';
import { makeUserLocationsService } from './services/UserLocationsService';
import { makeMockUserLocationApi } from 'api/mocks';
import { makeBOMealUserService } from './services/BOMealUserService';
import { makeDictionaryService } from './services/DictionaryService';

export { StatReportPage } from './ui/StatReportPage';

export { useReportService } from './module';
export { getNavigationLinks, getRoutes } from './ui/routes';

export { MealAdminPage } from './ui/MealAdminPage';

export const initializeMealsModuleContext = (
  ssoFetcher: Fetcher,
  reportsFetcher: Fetcher
): ReportModuleContext => {
  const mealUserApi = makeBOMealUserApi(ssoFetcher);
  const reportApi = makeReportApi(reportsFetcher);
  const companyServiceApi = makeCompanyServiceApi(reportsFetcher);
  const dictionaryApi = makeCompanyServiceApi(reportsFetcher);

  const reportService = makeReportService(reportApi);
  const companyService = makeComapnyService(companyServiceApi);
  const userLocationsService = makeUserLocationsService(makeMockUserLocationApi());
  const boMealUserService = makeBOMealUserService(ssoFetcher, mealUserApi);
  const dictionaryService = makeDictionaryService(dictionaryApi);

  return {
    meals: {
      reportService,
      companyService,
      userLocationsService,
      boMealUserService,
      dictionaryService,
      events: {
        onRefreshTable$: new Subject(),
      },
    },
  };
};
