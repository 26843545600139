import { IFetcher } from '@pay/data-fetching';
import { BoTestUserControllerApiInterface } from 'apis-generated/mapper-sso-admin';

export const makeTestClientsApi = (fetcher: IFetcher): BoTestUserControllerApiInterface => {
  return {
    getTestClients: () => fetcher.get('bo/testclient'),
    addTestClient: () => fetcher.postJson('bo/testclient', {}),
    removeTestClient: ({ id }) => fetcher.delete(`bo/testclient/${id}`),
  };
};
