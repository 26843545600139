import { IRestApi, IResultResponse, RestApi } from '@pay/admin-resources';
import { delay } from '@pay/common-utils';
import { range } from 'lodash-es';
import { IFetcherResult } from '@pay/data-fetching';
import { Column, FiltersState, PagingState, SortingState } from '@pay/admin-data-table';
import {
  LegalDetails,
  LegalInfo,
  SubjectType,
} from 'apis-generated/mapper-processing-admin/models';

export interface IBusinessAccountsApi extends IRestApi<LegalInfo, LegalDetails> {}

export class BusinessAccountsApi
  extends RestApi<LegalInfo, LegalDetails>
  implements IBusinessAccountsApi {}

export class MockBusinessAccountsApi implements IBusinessAccountsApi {
  public async fetchAll(): Promise<IFetcherResult<IResultResponse<LegalInfo>, unknown>> {
    throw new Error('Method not implemented.');
  }

  public async fetchMany(
    column: Column<LegalInfo>[],
    request: PagingState & {
      filters: FiltersState<LegalInfo>;
    } & SortingState<LegalInfo> & { additionalRsql?: any }
  ): Promise<IFetcherResult<IResultResponse<LegalInfo>, unknown>> {
    await delay(300);

    return {
      success: true,
      body: {
        items: range(0, 20).map((ind) => ({
          datetime: Date.now(),
          employeeCount: 2,
          extId: 'id' + ind * (request.page + 1),
          name: 'ТОО Чебурашка',
          type: SubjectType.Legal,
          createdAt: new Date(),
        })),
        total: 800,
      },
      rawResponse: undefined as any,
    };
  }

  public async fetchOne(id: string): Promise<IFetcherResult<LegalDetails, unknown>> {
    await delay(300);
    return {
      success: true,
      body: {
        createdAt: new Date(),
        // datetime: Date.now(),
        // employeeCount: 2,
        // identifier: 'id' + id,
        name: 'ТОО Чебурашка',
        // type: AccountType.LEGAL,
        extId: '123123123',
        type: SubjectType.Legal,
        participants: range(0, 10).map((ind) => ({
          active: true,
          iin: `0123456${ind}`,
          fio: 'Ivan Ivanov',
          phone: '+7777777777',
          id: '123',
        })),
      },
      rawResponse: undefined as any,
    };
  }

  public async add<TAddModel>(item: TAddModel): Promise<IFetcherResult<LegalInfo, unknown>> {
    throw new Error('Method not implemented.');
  }

  public async update<TUpdateModel>(
    id: string,
    updates: TUpdateModel
  ): Promise<IFetcherResult<LegalInfo, unknown>> {
    throw new Error('Method not implemented.');
  }

  public async delete(id: string): Promise<IFetcherResult<boolean, unknown>> {
    throw new Error('Method not implemented.');
  }
}
