import { useGlobalContext } from '@pay/modules';
import { ReportService } from './services/ReportService';
import { CompanyService } from './services/CompanyService';

export interface ReportModuleContext {
  reports: {
    reportService: ReportService;
    companyService: CompanyService;
  };
}

export const useReportService = () => useGlobalContext<ReportModuleContext>().reports.reportService;
export const useCompanyService = () =>
  useGlobalContext<ReportModuleContext>().reports.companyService;
