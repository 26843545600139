import * as React from 'react';
import { FC, useCallback, useState } from 'react';
import { useBOMealUserService } from 'modules/meals/module';
import { constRemoteError } from 'modules/common/store';
import * as TE from 'fp-ts/TaskEither';
import {
  BOMealSchoolLocation,
  BOMealUserEditRequest,
  RoleRespDto,
} from 'apis-generated/mapper-sso-admin';
import { useTranslation } from 'startup/utils';
import { useTaskEitherImmediate } from 'modules/common/async/hooks';
import {
  ErrorOverlay,
  IDetailsComponentProps,
  ITEM_ID_NEW,
  LoadingOverlay,
} from '@pay/admin-ui-kit';
import { Search } from '@material-ui/icons';
import { MealEditForm } from './MealEditForm';
import { EPermissionType, useUsersStore } from 'modules/user-management';
import { useAuthStore } from 'modules/auth';

export enum EMode {
  Create,
  Edit,
  View,
}

interface IFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
  username: string;
  roles: string[];
  schoolLocations: BOMealSchoolLocation[];
}

export const MealAdminDetails: React.FC<IDetailsComponentProps> = ({ itemId, onClose }) => {
  const boMealUserService = useBOMealUserService();

  const handleSave = useCallback(
    (values: IFormValues, roles: string[]) => {
      const createReq: IFormValues = {
        username: values.username,
        firstName: values.firstName,
        lastName: values.lastName,
        position: values.position,
        email: values.email,
        roles: roles,
        phone: values.phone,
        schoolLocations: values.schoolLocations,
      };
      return boMealUserService.addMealUser(createReq);
    },
    [boMealUserService]
  );

  return itemId === ITEM_ID_NEW ? (
    <div style={{ overflowY: 'scroll' }}>
      <MealEditForm onClose={onClose} onSave={handleSave} mode={EMode.Create} />
    </div>
  ) : (
    <UserViewEditContainer onClose={onClose} userId={itemId} />
  );
};

interface UserEditContainerProps {
  userId: string;
  onClose: () => void;
}
export const UserViewEditContainer: FC<UserEditContainerProps> = ({ userId, onClose }) => {
  const { t } = useTranslation();
  const authStore = useAuthStore();
  const boMealUserService = useBOMealUserService();
  const [isEditing, setIsEditing] = useState(false);
  const { state, retry } = useTaskEitherImmediate(() => boMealUserService.viewMealUser(userId));
  const handleEdit = useCallback(() => setIsEditing(true), []);

  const handleSave = useCallback(
    (values: IFormValues, roles: string[]) => {
      if (state.loading || state.error) {
        return TE.left(constRemoteError());
      }
      const update: BOMealUserEditRequest = {
        firstName: values.firstName,
        lastName: values.lastName,
        position: values.position,
        phone: values.phone,
        email: values.email,
        roles: roles,
        schoolLocations: values.schoolLocations,
      };
      return boMealUserService.editMealUser(state.value, update);
    },
    [state, boMealUserService]
  );

  if (state.loading) {
    return <LoadingOverlay />;
  }

  if (state.error) {
    if (state.error.type === 'unknown') {
      return <ErrorOverlay icon={Search} text={t('user_not_found')} onClose={onClose} />;
    }
    return <ErrorOverlay text={t('common_unknown_error')} onClose={onClose} />;
  }

  return (
    <MealEditForm
      mode={isEditing ? EMode.Edit : EMode.View}
      originalUser={state.value}
      onStartEdit={handleEdit}
      userCanEdit={authStore.currentUser?.hasOneOfPermissions([
        EPermissionType.Superadmin,
        EPermissionType.ServiceMealAdmin,
      ])}
      onClose={onClose}
      onSave={handleSave}
    />
  );
};
