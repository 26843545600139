import { getEnumValues } from '@pay/common-utils';
import { ITransKey } from 'startup/i18n';

export enum EPermissionType {
  Superadmin = 'superadmin:all',
  AgreementWrite = 'agreement:write',
  AgreementRead = 'agreement:read',
  ApkWrite = 'apk:write',
  ApkRead = 'apk:read',
  PrivacyPolicyWrite = 'privacyPolicy:write',
  PrivacyPolicyRead = 'privacyPolicy:read',
  ClientsRead = 'clients:read',
  ClientsWrite = 'clients:write',
  LegalRead = 'legals:read',
  LegalWrite = 'legals:write',
  BlackboxRead = 'blackbox:read',
  BlackboxWrite = 'blackbox:write',
  WorkspaceRead = 'workspace:read',
  WorkspaceWrite = 'workspace:write',
  SmsRead = 'sms:read',
  SmsWrite = 'sms:write',
  UsersRead = 'users:read',
  UsersWrite = 'users:write',
  UsersPassword = 'users:password',
  UsersAccess = 'users:access',
  RolesRead = 'roles:read',
  RolesWrite = 'roles:write',
  DashboardRead = 'dash:read',
  FinancialOrganizationRead = 'financialorg:read',
  FinancialOrganizationWrite = 'financialorg:write',
  ReportsRead = 'reports:read',
  ReportsWrite = 'reports:write',
  ReportsDownload = 'reports:download',
  ServiceMealReports = 'service:meal:reports',
  ServiceMealAdmin = 'service:meal:admin',
  LogRead = 'sys:readLogs',
  CoidRead = 'coid:read',
  System = 'system',
}

export const ALL_PERMISSIONS = getEnumValues<EPermissionType>(EPermissionType);

export const USER_DETAILS_ENTITY_ID = 'user';
export const ROLE_DETAILS_ENTITY_ID = 'role';

export const PERMISSION_TRANS_MAP: Record<EPermissionType, ITransKey> = {
  [EPermissionType.Superadmin]: '' as any,
  [EPermissionType.AgreementWrite]: 'permission_agreement_write',
  [EPermissionType.AgreementRead]: 'permission_agreement_read',
  [EPermissionType.ApkWrite]: 'permission_apk_write',
  [EPermissionType.ApkRead]: 'permission_apk_read',
  [EPermissionType.ClientsRead]: 'permission_clients_read',
  [EPermissionType.ClientsWrite]: 'permission_clients_write',
  [EPermissionType.LegalRead]: 'permission_legal_read',
  [EPermissionType.LegalWrite]: 'permission_legal_write',
  [EPermissionType.WorkspaceRead]: 'permission_workspace_read',
  [EPermissionType.WorkspaceWrite]: 'permission_workspace_write',
  [EPermissionType.BlackboxRead]: 'permission_blackbox_read',
  [EPermissionType.BlackboxWrite]: 'permission_blackbox_write',
  [EPermissionType.SmsRead]: 'permission_sms_read',
  [EPermissionType.SmsWrite]: 'permission_sms_write',
  [EPermissionType.UsersRead]: 'permission_users_read',
  [EPermissionType.UsersWrite]: 'permission_users_write',
  [EPermissionType.UsersPassword]: 'permission_users_password',
  [EPermissionType.UsersAccess]: 'permission_users_access',
  [EPermissionType.RolesRead]: 'permission_roles_read',
  [EPermissionType.RolesWrite]: 'permission_roles_write',
  [EPermissionType.DashboardRead]: 'permission_dashboard_read',
  [EPermissionType.FinancialOrganizationRead]: 'permission_fiorg_read',
  [EPermissionType.FinancialOrganizationWrite]: 'permission_fiorg_write',
  [EPermissionType.ReportsRead]: 'permission_reports_read',
  [EPermissionType.ReportsWrite]: 'permission_reports_write',
  [EPermissionType.ReportsDownload]: 'permission_reports_download',
  [EPermissionType.ServiceMealAdmin]: 'permission_service_meal_admin',
  [EPermissionType.ServiceMealReports]: 'permission_service_meal_reports',
  [EPermissionType.LogRead]: 'permission_reports_download',
  [EPermissionType.PrivacyPolicyRead]: 'permission_privacy_policy_read',
  [EPermissionType.PrivacyPolicyWrite]: 'permission_privacy_policy_write',
  [EPermissionType.CoidRead]: 'permission_coid_read',
  [EPermissionType.System]: '' as any,
};
