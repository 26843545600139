type Primitive = string | number;
/**
 *
 */
export const matchPrimitive = <T extends Primitive, B>(
  item: T,
  matchers: Record<T, () => B>
): B => {
  const matcher = matchers[item];
  return matcher();
};

export const isDefined = <T>(arg: T): arg is NonNullable<T> => {
  return typeof arg !== 'undefined' && arg !== null;
};
