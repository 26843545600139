import { EAuthCodeError } from '../constants';
import { ECommonError, ICommonError } from 'modules/common/store';
import { ITransKey } from 'startup/i18n';

export const SECOND_AUTH_CODE_ERROR_TRANS_MAP: Record<
  EAuthCodeError | ECommonError | ICommonError['type'],
  ITransKey
> = {
  [EAuthCodeError.WrongCode]: 'auth_error_invalid_code',
  [ECommonError.Unknown]: 'auth_error_unknown',
  'unknown-error': 'auth_error_unknown',
};
