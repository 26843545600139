import { makeStyles, Tab, Tabs } from '@material-ui/core';
import { Page, PageTitle } from '@pay/admin-ui-kit';
import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, RouteChildrenProps, useHistory, useRouteMatch } from 'react-router-dom';

import { EPermissionType } from 'modules/user-management/constants';
import { useAuthStore } from 'modules/auth/module';
import { RolesPane } from '../../roles/ui/RolesPane';
import { UsersPane } from './UsersPane';

enum ETab {
  Users = 'users',
  Roles = 'roles',
}

const useStyles = makeStyles((theme) => ({
  root: {},
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    flex: 0,
  },
}));

export const UserManagementPage: FC<RouteChildrenProps<{ tab: ETab }>> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const authStore = useAuthStore();

  const permittedTabs = useMemo(
    () =>
      [
        { tab: ETab.Users, perms: [EPermissionType.UsersRead, EPermissionType.System] },
        { tab: ETab.Roles, perms: [EPermissionType.RolesRead, EPermissionType.System] },
      ]
        .filter(({ tab, perms }) => authStore.currentUser?.hasOneOfPermissions(perms))
        .map((t) => t.tab),
    [authStore]
  );

  const tab = props.match?.params.tab || permittedTabs[0];
  const match = useRouteMatch();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: ETab) => {
    history.push(`/user-management/${newValue}`);
  };

  return (
    <>
      <Page>
        <PageTitle>{t('users_page_title')}</PageTitle>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          classes={{ root: classes.tabsRoot }}
        >
          {permittedTabs.includes(ETab.Users) && (
            <Tab value={ETab.Users} label={t('users_page_tab_users')} />
          )}
          {permittedTabs.includes(ETab.Roles) && (
            <Tab value={ETab.Roles} label={t('users_page_tab_roles')} />
          )}
        </Tabs>
        {tab === ETab.Users && <Route path={`${match.url}/:userId?`} component={UsersPane} />}
        {tab === ETab.Roles && <Route path={`${match.url}/:roleId?`} component={RolesPane} />}
      </Page>
    </>
  );
};
