import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { LocationInfoDto, LocationNextDTO } from 'apis-generated/reports';

declare module 'csstype' {
  interface Properties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  hasChild?: boolean;
  isExpanded?: boolean;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    group: {
      '& $content': {
        paddingLeft: theme.spacing(1),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
  })
);

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    hasChild,
    isExpanded,
    ...prop
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {hasChild && !isExpanded && <ArrowRightIcon />}
          {hasChild && isExpanded && <ArrowDropDownIcon />}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...prop}
    />
  );
}

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 400,
      width: 400,
      overFlow: 'auto',
    },
  })
);

type Props = {
  regionsData: LocationInfoDto[];
  childData: { [key: string]: LocationNextDTO[] };
  expanded: string[] | undefined;
  selected: string;
  onNodeToggle: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void;
  onNodeSelect: (event: React.ChangeEvent<{}>, nodeId: string) => void;
};

export const CustomTreeView = ({
  regionsData,
  childData,
  expanded,
  selected,
  onNodeSelect,
  onNodeToggle,
}: Props) => {
  const classes = useStyles();

  const renderTreeItems = (items: LocationInfoDto[]) => {
    return items.map((item: LocationInfoDto) => {
      const isExpanded = expanded?.includes(item.code!);
      if (!item.code) {
        return null;
      }
      return (
        <StyledTreeItem
          nodeId={item.code!}
          key={item.code}
          labelText={item.name!}
          hasChild={item.hasChild}
          isExpanded={isExpanded}
        >
          {childData[item.code] && renderTreeItems(childData[item.code])}
        </StyledTreeItem>
      );
    });
  };

  return (
    <TreeView
      className={classes.root}
      expanded={expanded}
      selected={selected}
      onNodeToggle={onNodeToggle}
      onNodeSelect={onNodeSelect}
    >
      {regionsData && renderTreeItems(regionsData)}
    </TreeView>
  );
};
