import {
  combineValidators,
  formatNumeric,
  makeExactLengthValidator,
  requiredValidator,
} from '@pay/common-utils';
import { makeUseTranslation } from '@pay/i18n-utils';
import { ITransKey } from 'startup/i18n';
export const CODE_LENGTH = 6;

const LENGTH_ERROR_MESSAGE: ITransKey = 'auth_validation_code_length';
const lengthValidator = makeExactLengthValidator(CODE_LENGTH, LENGTH_ERROR_MESSAGE);

export const secondAuthCodeValidator = combineValidators(requiredValidator, lengthValidator);

export const formatSecondAuthCode = (string: string) => {
  return formatNumeric(string).substr(0, CODE_LENGTH);
};

export const useTranslation = makeUseTranslation<ITransKey>();
