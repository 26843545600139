import React from 'react';
import { makeStyles, Fade, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      theme.palette.type === 'light'
        ? theme.palette.common.white
        : theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
}));

interface IProps {
  in: boolean;
}
export const LoadingOverlay: React.FC<IProps> = (props) => {
  const classes = useStyles();
  return (
    <>
      <Fade in={props.in}>
        <div className={classes.root}>
          <CircularProgress />
        </div>
      </Fade>
    </>
  );
};
