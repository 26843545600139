import { IRestApi, IResultResponse, RestApi } from '@pay/admin-resources';
import { delay } from '@pay/common-utils';
import { range } from 'lodash-es';
import { IFetcherResult } from '@pay/data-fetching';
import { Column, FiltersState, PagingState, SortingState } from '@pay/admin-data-table';
import { SchoolViewDTO, SchoolViewDTOServiceCompanyEnum } from 'apis-generated/school-meal';

export interface ISchoolApi extends IRestApi<SchoolViewDTO, SchoolViewDTO> {}

export class SchoolApi extends RestApi<SchoolViewDTO, SchoolViewDTO> implements ISchoolApi {}

export class MockBusinessAccountsApi implements ISchoolApi {
  public async fetchAll(): Promise<IFetcherResult<IResultResponse<SchoolViewDTO>, unknown>> {
    throw new Error('Method not implemented.');
  }

  public async fetchMany(
    column: Column<SchoolViewDTO>[],
    request: PagingState & {
      filters: FiltersState<SchoolViewDTO>;
    } & SortingState<SchoolViewDTO> & { additionalRsql?: any }
  ): Promise<IFetcherResult<IResultResponse<SchoolViewDTO>, unknown>> {
    await delay(300);

    return {
      success: true,
      body: {
        items: range(0, 20).map((ind) => ({
          name: 'ТОО Школа',
          number: '123',
          bin: '123123123',
          areaCode: '1234',
          regionCode: '1234',
          locationCode: '1234',
          location: '',
          serviceCompany: SchoolViewDTOServiceCompanyEnum.Alaqan,
        })),
        total: 800,
      },
      rawResponse: undefined as any,
    };
  }

  public async fetchOne(id: string): Promise<IFetcherResult<SchoolViewDTO, unknown>> {
    await delay(300);
    return {
      success: true,
      body: {
        name: 'ТОО Школа',
        number: '123',
        bin: '123123123',
        areaCode: '1234',
        regionCode: '1234',
        locationCode: '1234',
        location: '',
        serviceCompany: SchoolViewDTOServiceCompanyEnum.Alaqan,
      },
      rawResponse: undefined as any,
    };
  }

  public async add<TAddModel>(item: TAddModel): Promise<IFetcherResult<SchoolViewDTO, unknown>> {
    throw new Error('Method not implemented.');
  }

  public async update<TUpdateModel>(
    id: string,
    updates: TUpdateModel
  ): Promise<IFetcherResult<SchoolViewDTO, unknown>> {
    throw new Error('Method not implemented.');
  }

  public async delete(id: string): Promise<IFetcherResult<boolean, unknown>> {
    throw new Error('Method not implemented.');
  }
}
