import { DialogActions, DialogContent, Grid, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeRhfMuiTextFieldProps } from '@pay/mui-enhancement';
import React, { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { pipe } from 'fp-ts/es6/function';
import { useNotificationsStore } from '@pay/admin-ui-kit';
import * as TE from 'fp-ts/TaskEither';

import { requiredValidator } from 'modules/common/validators';
import { useCompanyService } from 'modules/meals/module';
import { TEtoPromise } from 'modules/common/fpts-utils';
import { AsyncSelect } from 'modules/common/ui/AsyncSelect/AsyncSelect';
import { useSchoolService } from 'modules/schools/module';
import {
  AddSchoolRequestServiceCompanyEnum,
  EditSchoolRequestServiceCompanyEnum,
  SchoolViewDTO,
  SchoolViewDTOServiceCompanyEnum,
} from 'apis-generated/school-meal';

const useStyles = makeStyles((theme) => ({
  root: {},
  buttons: {
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  select: {
    marginRight: theme.spacing(2),
    minWidth: 155,
  },
}));

interface FormValues {
  number: string;
  nameRu: string;
  bin: string;
  areaCode: string;
  regionCode: string;
  locationCode: string;
  serviceCompanyCode:
    | SchoolViewDTOServiceCompanyEnum
    | AddSchoolRequestServiceCompanyEnum
    | EditSchoolRequestServiceCompanyEnum;
}

export type IProps = {
  onClose: () => void;
  onSuccess: () => void;
  school?: SchoolViewDTO;
};

export const SchoolForm: FC<IProps> = ({ onClose, onSuccess, school }) => {
  const companyService = useCompanyService();
  const handleFetchServiceCompanies = useCallback(
    () => pipe(companyService.getServiceCompanies(), TEtoPromise)(),
    [companyService]
  );

  const { t } = useTranslation();
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm<FormValues>({
    defaultValues: {
      number: school?.number,
      nameRu: school?.name,
      bin: school?.bin,
      areaCode: school?.areaCode,
      regionCode: school?.regionCode,
      locationCode: school?.locationCode,
      serviceCompanyCode: school?.serviceCompany,
    },
  });
  const schoolService = useSchoolService();
  const { showNotification } = useNotificationsStore();

  const handleSubmitInternal = useCallback(
    (values: FormValues) => {
      if (school == null) {
        pipe(
          schoolService.createSchool({
            bin: values.bin,
            name: values.nameRu,
            number: values.number,
            areaCode: values.areaCode,
            regionCode: values.regionCode,
            locationCode: values.locationCode,
            serviceCompany: values.serviceCompanyCode as AddSchoolRequestServiceCompanyEnum,
          }),
          TE.map(() => onSuccess()),
          TE.mapLeft(() => {
            showNotification({
              text: t('meal_school_create_error'),
              options: { variant: 'error' },
            });
          })
        )();
      } else {
        pipe(
          schoolService.editSchool(
            {
              name: values.nameRu,
              number: values.number,
              areaCode: values.areaCode,
              regionCode: values.regionCode,
              locationCode: values.locationCode,
              serviceCompany: values.serviceCompanyCode as EditSchoolRequestServiceCompanyEnum,
            },
            values.bin
          ),
          TE.map(() => onSuccess()),
          TE.mapLeft(() => {
            showNotification({
              text: t('meal_school_create_error'),
              options: { variant: 'error' },
            });
          })
        )();
      }
    },
    [schoolService, onSuccess, showNotification, t]
  );

  return (
    <>
      <DialogContent dividers>
        <Grid
          id="SchoolForm"
          container
          spacing={3}
          component="form"
          onSubmit={handleSubmit(handleSubmitInternal)}
        >
          <Controller
            control={control}
            name="bin"
            rules={{ validate: requiredValidator }}
            render={({ field }) => (
              <TextField
                id="bin"
                label={t('meal_school_bin_field_title')}
                fullWidth
                {...makeRhfMuiTextFieldProps(errors.bin, t)}
                variant="outlined"
                {...field}
                inputProps={{ maxLength: 12 }}
              />
            )}
          />
          <Controller
            control={control}
            name="number"
            rules={{ validate: requiredValidator }}
            render={({ field }) => (
              <TextField
                id="number"
                label={t('meal_school_number_field_title')}
                fullWidth
                {...makeRhfMuiTextFieldProps(errors.number, t)}
                variant="outlined"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="nameRu"
            rules={{ validate: requiredValidator }}
            render={({ field }) => (
              <TextField
                id="nameRu"
                label={t('meal_school_name_ru_field_title')}
                fullWidth
                {...makeRhfMuiTextFieldProps(errors.nameRu, t)}
                variant="outlined"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="areaCode"
            rules={{ validate: requiredValidator }}
            render={({ field }) => (
              <TextField
                id="address"
                label={t('meal_school_area_code_title')}
                fullWidth
                {...makeRhfMuiTextFieldProps(errors.nameRu, t)}
                variant="outlined"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="regionCode"
            rules={{ validate: requiredValidator }}
            render={({ field }) => (
              <TextField
                id="address"
                label={t('meal_school_region_code_title')}
                fullWidth
                {...makeRhfMuiTextFieldProps(errors.nameRu, t)}
                variant="outlined"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="locationCode"
            rules={{ validate: requiredValidator }}
            render={({ field }) => (
              <TextField
                id="address"
                label={t('meal_school_location_code_title')}
                fullWidth
                {...makeRhfMuiTextFieldProps(errors.nameRu, t)}
                variant="outlined"
                {...field}
              />
            )}
          />
          <Controller
            name={'serviceCompanyCode'}
            control={control}
            render={({ field }) => (
              <AsyncSelect
                size="medium"
                style={{ minWidth: 260 }}
                fullWidth
                label={t('meal_school_service_company_field_title')}
                onLoadItems={handleFetchServiceCompanies}
                {...makeRhfMuiTextFieldProps(errors.serviceCompanyCode, t)}
                {...field}
              >
                {(serviceCompanies) =>
                  serviceCompanies?.map((company) => (
                    <MenuItem value={company.code} key={company.id}>
                      {company.name}
                    </MenuItem>
                  ))
                }
              </AsyncSelect>
            )}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button variant="text" color="primary" onClick={onClose}>
            {t('common_close')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="SchoolForm"
            disabled={!formState.isValid && formState.isSubmitted}
          >
            {school ? t('meal_school_edit_action_title') : t('meal_school_create_action_title')}
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

SchoolForm.displayName = 'NewAgreementModal';
