import { FinOrganizationsModuleContext } from './module';
import { FinOrganizationsStore } from './data/FinOrganizationsStore';
import { makeBlackboxApi } from '../../api';
import { OrganizationsService } from './service/OrganizationsService';
import { FinOrganizationsApi } from './api/FinOrganizationsApi';
import { Fetcher } from '@pay/data-fetching';

export { FinOrganizationsPage } from './ui/FinOrganizationsPage';
export { ORGANIZATION_ENTITY_ID } from './ui/details/constants';
export { OrganizationDetails } from './ui/details/OrganizationDetails';
export { OrganizationsService } from './service/OrganizationsService';
export { ORGANIZATIONS_SERVICE_KEY } from './service';

export const initializeFinOrganizationsModuleContext = (
  mapperFetcher: Fetcher
): FinOrganizationsModuleContext => {
  const finOrganizationsApi = makeBlackboxApi(mapperFetcher);

  const service = new OrganizationsService(finOrganizationsApi);
  return {
    finOrganizations: {
      service,
      store: new FinOrganizationsStore(
        new FinOrganizationsApi('bo/blackboxes', mapperFetcher, { pluralize: false })
      ),
    },
  };
};
