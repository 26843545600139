import { BusinessAccountsModuleContext } from './module';
import { BusinessAccountsApi } from './api/BusinessAccountsApi';
import { Fetcher } from '@pay/data-fetching';
import { BusinessAccountsStore } from './data/BusinessAccountsStore';
import { makeNonmontaryApi, makeOrganizationsApi } from 'api';
import { makeNonMonetaryService } from './services/NonMonetaryService';
import { makeStaticQrService } from './services/StaticQrService';

export { BusinessAccountsPage } from './ui/BusinessAccountsPage';
export * from './ui/details';
export { BUSINESS_ACCOUNT_DETAILS_ENTITY_ID } from './ui/constants';

export const initializeBusinessAccountsModuleContext = (
  mapperFetcher: Fetcher
): BusinessAccountsModuleContext => {
  const businessAccountsApi = new BusinessAccountsApi('bo/legal', mapperFetcher);

  const nonMonetaryApi = makeNonmontaryApi(mapperFetcher);
  const staticQrApi = makeOrganizationsApi(mapperFetcher);
  return {
    businessAccounts: {
      store: new BusinessAccountsStore(businessAccountsApi),
      nonMonetaryService: makeNonMonetaryService(nonMonetaryApi, mapperFetcher),
      staticQrService: makeStaticQrService(staticQrApi),
    },
  };
};
