import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';

import { mapFetcherResultEither } from 'modules/common/fetcher';
import {
  DictionaryControllerApiInterface,
  GetLocationsQueryParams,
  GetNextLocationQueryParams,
  GetSchoolsQueryParams,
} from 'apis-generated/reports';

export const makeDictionaryService = (api: DictionaryControllerApiInterface) => {
  return {
    getLocations: (queryParams: GetLocationsQueryParams) =>
      pipe(() => api.getLocations(queryParams), T.map(mapFetcherResultEither)),
    getNextLocation: (queryParams: GetNextLocationQueryParams) =>
      pipe(() => api.getNextLocation(queryParams), T.map(mapFetcherResultEither)),
    getSchools: (queryParams: GetSchoolsQueryParams) =>
      pipe(() => api.getSchools(queryParams), T.map(mapFetcherResultEither)),
    getServiceCompanies: () => pipe(() => api.getServiceCompanies(), T.map(mapFetcherResultEither)),
  };
};

export type DictionaryService = ReturnType<typeof makeDictionaryService>;
