import { makeTableDataSource } from '@pay/admin-resources';
import { IFetcher } from '@pay/data-fetching';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import {
  mapFetcherResultEither,
  mapFetcherResultEither2,
  mapRemoteError,
} from 'modules/common/fetcher';
import { Subject } from 'rxjs';
import {
  BackofficeUserApiInterface,
  BackofficeUserRespDto,
  BackofficeUserAddRequest,
  BackofficeUserEditReqDto,
} from 'apis-generated/mapper-sso-admin';
import { map2FaVerificationError } from 'modules/auth';

export class UsersStore {
  public updates$ = new Subject<{ userId: string | undefined }>();
  constructor(private _fetcher: IFetcher, private _api: BackofficeUserApiInterface) {}

  public fetchUsers = makeTableDataSource<BackofficeUserRespDto>(this._fetcher, 'bo/users');

  public addUser = (user: BackofficeUserAddRequest) => {
    return pipe(
      () => this._api.addUser({ backofficeUserAddRequest: user }),
      T.map(
        mapFetcherResultEither2((res) => {
          if (res.response && res.serverError?.hasCode('400000')) {
            return {
              type: 'user-already-exists',
            } as const;
          }
          return mapRemoteError(res);
        })
      ),
      TE.chain((_) => {
        this.updates$.next({ userId: undefined });
        return TE.of(undefined);
      })
    );
  };

  public updateUser = (user: BackofficeUserRespDto, updates: BackofficeUserEditReqDto) => {
    return pipe(
      () => this._api.editUser({ backofficeUserEditReqDto: updates }, { id: user.id }),
      T.map(mapFetcherResultEither),
      TE.chain((_) => {
        this.updates$.next({ userId: user.id });
        return TE.of(undefined);
      })
    );
  };

  public deleteMany(ids: string[]): Promise<string[]> {
    throw new Error('Use deleteUsers');
  }

  public deleteUsers = (userIds: string[], code: string) => {
    const tasks = pipe(
      userIds.map((id) =>
        pipe(
          () => this._api.removeUser({ backofficeRemoveUserReqDto: { code: code } }, { id: id }),
          T.map(mapFetcherResultEither),
          TE.mapLeft(map2FaVerificationError)
        )
      ),
      A.sequence(TE.taskEither)
    );
    return tasks;
  };

  public deleteUser = (userId: string, code: string) => {
    return pipe(
      () => this._api.removeUser({ backofficeRemoveUserReqDto: { code } }, { id: userId }),
      T.map(mapFetcherResultEither),
      TE.mapLeft(map2FaVerificationError),
      TE.map((_) => {
        this.updates$.next({ userId: userId });
        return undefined;
      })
    );
  };

  public fetchOneUser = (userId: string) => {
    return pipe(() => this._api.getUser({ id: userId }), T.map(mapFetcherResultEither));
  };

  // public restoreUser = (userId: string, code: string) => {
  //   return pipe(
  //     () => this._api.restoreUser({ restoreUserRqModel: { code: code, userId: userId } }),
  //     T.map(mapFetcherResultEither),
  //     TE.mapLeft(map2FaVerificationError),
  //     TE.map((_) => {
  //       this.updates$.next({ userId: userId });
  //       return undefined;
  //     })
  //   );
  // };

  //#region other users security

  public resetPassword = (userId: string, code: string) => {
    const reqParamCode = {
      backofficeResetUserReqDto: {
        code,
      },
    };
    const reqParamId = {
      id: userId,
    };
    return pipe(
      () => this._api.resetUserKey(reqParamCode, reqParamId),
      T.map(mapFetcherResultEither),
      TE.mapLeft(map2FaVerificationError)
    );
  };

  public reset2fa = (userId: string, code: string) => {
    const reqParamUserId = {
      backofficeReset2FAUserReqDto: {
        code: code,
      },
    };
    const reqParamCode = { id: userId };
    return pipe(
      () => this._api.resetUser2FA(reqParamUserId, reqParamCode),
      T.map(mapFetcherResultEither),
      TE.mapLeft(map2FaVerificationError)
    );
  };
  // public lockUnlock = (user: BackofficeUserAddRequest, reason: string, code: string) => {
  //   return pipe(
  //     () =>
  //       this._api.({
  //         lockUnlockUserRqModel: {
  //           userId: user.id,
  //           code: code,
  //           lockReason: reason,
  //           lock: !user.locked,
  //         },
  //       }),
  //     T.map(mapFetcherResultEither),
  //     TE.mapLeft(map2FaVerificationError)
  //   );
  // };

  //#endregion
}
