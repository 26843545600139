import { IFetcher } from '@pay/data-fetching/dist/Fetcher/IFetcher';

import { makeSmsApi } from '../../api';
import { ConfigurationModuleContext } from './module';
import { ConfigurationService } from './service/ConfigurationService';
export { ConfigurationPage } from './ui/ConfigurationPage';

export const initializeConfigurationModuleContext = (
  ssoFetcher: IFetcher
): ConfigurationModuleContext => {
  const configurationApi = makeSmsApi(ssoFetcher);

  const service = new ConfigurationService(configurationApi);

  return {
    configuration: {
      service,
    },
  };
};
