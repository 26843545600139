import { action, computed, observable, runInAction } from 'mobx';
import { deserialize, serialize } from 'serializr';
import { constUndefined, pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import * as AuthState from '../core/AuthState';
import { IStorageManager } from '@pay/storage';

import { IAuthLoginResponse, IAuthService } from '../service/AuthService';
import { CurrentUserModel } from './CurrentUserModel';
import { EPermissionType } from 'modules/user-management';

export const STORAGE_KEY = 'userId';

export enum EAuthStage {
  Login = 'log',
  SecondFactor = '2auth',
}

export interface ILoginStageContext {
  stage: EAuthStage.Login;
}

export interface I2AuthStageContext {
  stage: EAuthStage.SecondFactor;
  authResponse: IAuthLoginResponse;
}

export class AuthStore {
  // @observable public returnUrl: string = '/';
  // @observable.ref public currentUser: CurrentUserModel | undefined;
  // @observable public logOutReason: ELogoutReason | undefined;

  @observable.ref public state: AuthState.T = AuthState.initialize();

  @computed
  public get isLoggedIn() {
    return AuthState.isLoggedIn(this.state);
  }
  @computed
  public get initialState() {
    return AuthState.isInitial(this.state) ? this.state : undefined;
  }
  @computed
  public get currentUser() {
    return AuthState.isLoggedIn(this.state)
      ? this.state.currentUser
      : this.state.fromExpiredSession
      ? this.state.currentUser
      : undefined;
  }

  constructor(
    private _service: IAuthService,
    private _storage: IStorageManager,
    public readonly withSecondFactor: boolean
  ) {
    const currentUserSerialized = this._storage.getObject(STORAGE_KEY) ?? undefined;
    if (currentUserSerialized) {
      try {
        this.state = AuthState.handleLoggedIn(deserialize(CurrentUserModel, currentUserSerialized));
      } catch (e) {
        // TODO: handle
      }
    }
  }

  public userHasAllPermissions = (permissions: EPermissionType[]) => {
    if (!this.currentUser!) return false;
    return this.currentUser.hasAllPermissions(permissions);
  };

  public userHasOneOfPermissions = (permissions: EPermissionType[]) => {
    if (!this.currentUser!) return false;
    return this.currentUser.hasOneOfPermissions(permissions);
  };

  @action.bound
  public logIn(login: string, password: string) {
    return pipe(
      this._service.login(login, password),
      TE.map((res) => {
        return runInAction(() => {
          if (AuthState.isLoggedIn(this.state)) {
            return;
          }
          this.state = {
            ...this.state,
            stageContext: {
              authResponse: res,
              stage: AuthState.InitialStage.SecondFactor,
              password,
            },
          };
        });
      })
    );
  }

  @action.bound
  public setReturnUrl(url: string) {
    if (AuthState.isLoggedIn(this.state)) {
      // throw new Error('User is already loggedIn');
      return;
    }
    this.state = {
      ...this.state,
      returnUrl: url,
    };
  }

  @action.bound
  public verify2AuthCode(code: string) {
    if (AuthState.isLoggedIn(this.state)) {
      throw new Error('User is already loggedIn');
    }

    const { stageContext } = this.state;

    if (stageContext.stage !== AuthState.InitialStage.SecondFactor) {
      throw new Error('invalid invocation');
    }

    return pipe(
      this._service.verifyCode({
        authSessionId: stageContext.authResponse.authSessionId,
        code: code,
      }),
      TE.chainW(this.loadCurrentUser),
      TE.map(constUndefined)
    );
  }

  @action.bound
  public async logOut(reason?: AuthState.LogoutReason) {
    // logout itself throws 401 if session is expired.
    if (!this.isLoggedIn && !AuthState.isSessionExpired(this.state)) {
      return;
    }

    this.state = {
      ...AuthState.initialize(),
      logOutReason: reason,
    };

    this._storage.delete(STORAGE_KEY);

    // For now we will just fire and forget without checking the result
    this._service.logout();
    window.location.reload();
  }

  public changePassword = (newPassword: string, code: string) =>
    this._service.changePassword(newPassword, code);

  @action.bound
  private loadCurrentUser = () => {
    return pipe(
      this._service.fetchCurrentUser(),
      TE.map((res) => {
        return runInAction(() => {
          this.state = AuthState.handleLoggedIn(res);
          const serialized = serialize(this.state.currentUser);
          this._storage.saveObject(STORAGE_KEY, serialized);
        });
      })
    );
  };

  public generateSecondFactorData = this._service.generate2FA;

  public validateUserSecondFactor = this._service.validate2FA;

  @action.bound public async tryLogOut(reason?: AuthState.LogoutReason) {
    // if (!this.isLoggedIn) {
    //   return;
    // }

    if (reason === AuthState.LogoutReason.SessionExpiredOrInvalidToken && this.currentUser) {
      return this.expireSession();
    }

    this.logOut(reason);
  }

  @action.bound public expireSession() {
    if (!AuthState.isLoggedIn(this.state)) return;

    this.state = AuthState.expireSession(this.state.currentUser);
  }

  @action.bound public sessionExpired = (currentUser: CurrentUserModel) => {
    this.state = {
      type: 'Initial',
      stageContext: {
        stage: AuthState.InitialStage.Login,
      },
      fromExpiredSession: true,
      currentUser,
    };
  };
}

export enum ELogoutReason {
  SessionExpiredOrInvalidToken = 'SessionExpiredOrInvalidToken',
}
