import { Box, Tab } from '@material-ui/core';
import { useAuthStore } from '../../auth';
import { Page, PageTitle, Tabs } from '@pay/admin-ui-kit';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneralSettings } from './areas/general/GeneralSettings';
import { SecuritySettingsContainer } from './areas/security/SecuritySettings';

enum ETab {
  General = 'general',
  Security = 'securty',
}

export const SettingsPage: React.FC = (props) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<ETab>(ETab.General);

  const authStore = useAuthStore();

  const handleTabChange = useCallback((ev: unknown, tabAr: ETab) => {
    setTab(tabAr);
  }, []);

  return (
    <>
      <Page>
        <PageTitle>{t('navigation_settings')}</PageTitle>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab value={ETab.General} label={t(TAB_TITLE_TRANS[ETab.General])} />
          <Tab value={ETab.Security} label={t(TAB_TITLE_TRANS[ETab.Security])} />
        </Tabs>
        <Box pt={2} pb={2}>
          <>{tab === ETab.General && <GeneralSettings user={authStore.currentUser!} />}</>
          <>{tab === ETab.Security && <SecuritySettingsContainer />}</>
        </Box>
      </Page>
    </>
  );
};

const TAB_TITLE_TRANS: Record<ETab, string> = {
  [ETab.General]: 'settings_tab_general',
  [ETab.Security]: 'settings_tab_security',
};
