import { ServerError } from '@pay/data-fetching';

export enum ECommonError {
  Unknown = 'unknown',
}

export interface IRemoteError {
  type: ECommonError.Unknown;
  serverError?: ServerError | undefined;
}

export type ICommonError = {
  type: 'unknown-error';
  serverError?: ServerError;
};

export const constRemoteError = (): IRemoteError => ({ type: ECommonError.Unknown });

export const constCommonError = (): ICommonError => ({ type: 'unknown-error' });
