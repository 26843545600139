import React, { useMemo, FC } from 'react';
import { TableToolbar, useTable } from '@pay/admin-data-table';
import { Page, PageTitle } from '@pay/admin-ui-kit';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@pay/admin-data-table';

import { getColumns } from './tableDefs';
import { useLogsStore } from '../module';
import { BackofficeAuditRespDto } from 'apis-generated/mapper-sso-admin';

export const LogsPage: FC = () => {
  const { t } = useTranslation();
  const store = useLogsStore();
  const columns = useMemo(() => getColumns(t), [t]);
  const { tableProps, toolBarProps } = useTable<BackofficeAuditRespDto>({
    fetchData: store.fetchUsersLogs,
    pageSize: 20,
    columns,
    keyGetter: (it) => it.id,
  });

  return (
    <Page style={{ flexFlow: 'column' }}>
      <PageTitle>{t('logs_page_title')}</PageTitle>
      <TableToolbar<BackofficeAuditRespDto> {...toolBarProps} columns={columns} />
      <DataTable<BackofficeAuditRespDto> {...tableProps} />
    </Page>
  );
};
LogsPage.displayName = 'LogsPage';
