import { makeEmailValidator, makeRequiredValidator, combineValidators } from '@pay/common-utils';
import { isValid } from 'date-fns';
import { ITransKey } from 'startup/i18n';

export const emailValidator = makeEmailValidator<ITransKey>('validation_email');

export const requiredValidator = makeRequiredValidator<ITransKey>('validation_required');
export const requiredAnyValidator = makeRequiredValidator<ITransKey>('validation_required') as (
  val: any
) => ReturnType<typeof requiredValidator>;

export const requiredDateValidate = (value: Date) =>
  value && isValid(value) ? undefined : 'validation_required';

const VALIDATION_TRANS: ITransKey = 'validation_required';
export const requiredAndNotZeroValidate = combineValidators(
  makeRequiredValidator<ITransKey>('validation_required'),
  (str) => (str !== '0' ? undefined : VALIDATION_TRANS)
);
