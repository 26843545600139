import {
  UserLocationSchoolsDto,
  UserLocationsControllerApiInterface,
} from 'apis-generated/reports';
import { wrapMockSuccessRespone } from 'modules/common/mockApiResponse';
import { delayedResolve } from 'modules/common/utils';

export const makeMockUserLocationApi = (): UserLocationsControllerApiInterface => {
  const USER_LOCATION_SCHOOL_ITEMS: UserLocationSchoolsDto[] = [
    {
      location: {
        id: '1',
        code: '111',
        name: 'Акмл обл',
      },
      schools: [
        {
          address: 'adress 1',
          bin: '111',
          name: 'Школа №1',
        },
      ],
    },

    {
      location: {
        id: '2',
        code: '222',
        name: 'Крг обл',
      },
    },
  ];

  return {
    getCurrentUserLocationSchoolsItems: delayedResolve(
      wrapMockSuccessRespone(USER_LOCATION_SCHOOL_ITEMS),
      300
    ),
    getUserLocationSchoolsItems: delayedResolve(
      wrapMockSuccessRespone(USER_LOCATION_SCHOOL_ITEMS),
      300
    ),
  };
};
