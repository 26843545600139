import { useGlobalContext } from '@pay/modules';

import { UsersStore } from './users';
import { RolesStore } from './roles';

export interface UserManagementModuleContext {
  userManagement: {
    usersStore: UsersStore;
    rolesStore: RolesStore;
  };
}

export const useUsersStore = () =>
  useGlobalContext<UserManagementModuleContext>().userManagement.usersStore;
export const useRolesStore = () =>
  useGlobalContext<UserManagementModuleContext>().userManagement.rolesStore;
