import { DataTable, TableToolbar, useTable, SortDirection } from '@pay/admin-data-table';
import React, { useMemo, FC } from 'react';

import { useTranslation } from 'startup/utils';
import { useClientService, useClientsStore } from '../module';
import { getDeleteRequestClientsColumns } from './tableDefs';
import { ClientRespDto, ToDeleteClientRespDto } from 'apis-generated/mapper-sso-admin';

export enum ETab {
  History = '',
  ChangeRequests = 'changeRequests',
}

interface IProps {}
export const DeleteRequestClientsTable: FC<IProps> = () => {
  const { t } = useTranslation();
  const service = useClientService();

  const columns = useMemo(() => getDeleteRequestClientsColumns(t), [t]);

  const { tableProps, toolBarProps } = useTable<ToDeleteClientRespDto>({
    fetchData: service.getToDeleteClients,
    pageSize: 20,
    columns,
    keyGetter: (it) => it.clientId,
    initialQuery: {
      sortings: {
        deleteRequestedAt: {
          direction: SortDirection.Asc,
        },
      },
      page: 0,
      pageSize: 20,
      filters: {},
    },
  });

  return (
    <>
      <TableToolbar<ClientRespDto> {...toolBarProps} columns={columns} />
      <DataTable<ToDeleteClientRespDto> {...tableProps} />
    </>
  );
};
