import { Page, PageTitle } from '@pay/admin-ui-kit';
import React, { FC, ChangeEvent, useState } from 'react';
import { useTranslation } from 'startup/utils';
import { Box, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { REPORT_FORM_TRANS_MAP, ReportFormEnum } from '../constants';
import { MealVoucherReportPage } from './MealVoucherReportPage';
import { StatReportPage } from './StatReportPage';

interface IProps {}

export const ReportsPage: FC<IProps> = () => {
  const { t } = useTranslation();

  const [reportForm, setReportForm] = useState<ReportFormEnum>(ReportFormEnum.Form1);

  const handleChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const selected = ReportFormEnum[e.target.value as keyof typeof ReportFormEnum];
    setReportForm(selected);
  };

  return (
    <Page>
      <PageTitle>{t('reports_title')}</PageTitle>
      <Box mb={2}>
        <Paper>
          <Box p={2} display="flex" alignItems="center">
            <Box mr={2}>
              <Typography>{t('reports_form')}</Typography>
            </Box>
            <Select
              variant="outlined"
              style={{ minWidth: 160 }}
              value={reportForm}
              onChange={handleChange}
            >
              {Object.entries(REPORT_FORM_TRANS_MAP).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {t(value)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Paper>
      </Box>
      {reportForm === ReportFormEnum.Form1 && <StatReportPage />}
      {reportForm === ReportFormEnum.Form2 && <MealVoucherReportPage />}
    </Page>
  );
};
