import { BusinessAccountsStore } from './data/BusinessAccountsStore';
import { useGlobalContext } from '@pay/modules';
import { NonMonataryService } from './services/NonMonetaryService';
import { StaticQrService } from './services/StaticQrService';

export interface BusinessAccountsModuleContext {
  businessAccounts: {
    store: BusinessAccountsStore;
    nonMonetaryService: NonMonataryService;
    staticQrService: StaticQrService;
  };
}

export const useBusinessAccountsStore = () =>
  useGlobalContext<BusinessAccountsModuleContext>().businessAccounts.store;

export const useNonMonetaryService = () =>
  useGlobalContext<BusinessAccountsModuleContext>().businessAccounts.nonMonetaryService;

export const useStaticQrService = () =>
  useGlobalContext<BusinessAccountsModuleContext>().businessAccounts.staticQrService;
