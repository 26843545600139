import { IRestApi, RestApi, IResultResponse } from '@pay/admin-resources';
import { IFetcherResult } from '@pay/data-fetching';
import { PagingState, FiltersState, SortingState, Column } from '@pay/admin-data-table';
import { delay } from '@pay/common-utils';
import { range } from 'lodash-es';

export interface ITempClientAuditEntry {
  id: string;
  wsId: string;
  wsName: string;
  datetime: number;
  description: string;
  device: string;
}

export interface IClientWsApi extends IRestApi<ITempClientAuditEntry> {}

export class ClientWsApi extends RestApi<ITempClientAuditEntry> {}

export class MockClientWsApi implements IRestApi<ITempClientAuditEntry> {
  public async fetchAll(): Promise<
    IFetcherResult<IResultResponse<ITempClientAuditEntry>, unknown>
  > {
    throw new Error('Method not implemented.');
  }
  public async fetchMany(
    column: Column<ITempClientAuditEntry>[],
    request: PagingState & {
      filters: FiltersState<ITempClientAuditEntry>;
    } & SortingState<ITempClientAuditEntry> & { additionalRsql?: any }
  ): Promise<IFetcherResult<IResultResponse<ITempClientAuditEntry>, unknown>> {
    await delay(300);
    return {
      success: true,
      rawResponse: null as any,
      body: {
        items: range(0, 20).map((ind) => ({
          id: 'id_' + ind * (request.page + 1),
          datetime: Date.now(),
          description: 'Запрос на добавление ФИ',
          device: 'IPhone 7',
          wsId: 'ws' + ind * (request.page + 1),
          wsName: 'Workspace #' + ind * (request.page + 1),
        })),
        total: 800,
      },
    };
  }
  public async fetchOne(id: string): Promise<IFetcherResult<ITempClientAuditEntry, unknown>> {
    throw new Error('Method not implemented.');
  }
  public async add<TAddModel>(
    item: TAddModel
  ): Promise<IFetcherResult<ITempClientAuditEntry, unknown>> {
    throw new Error('Method not implemented.');
  }
  public async update<TUpdateModel>(
    id: string,
    updates: TUpdateModel
  ): Promise<IFetcherResult<ITempClientAuditEntry, unknown>> {
    throw new Error('Method not implemented.');
  }
  public async delete(id: string): Promise<IFetcherResult<boolean, unknown>> {
    throw new Error('Method not implemented.');
  }
}
