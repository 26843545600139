import React from 'react';
import { InsertDriveFileOutlined } from '@material-ui/icons';
import { INavigationLinkGroup, INavigationLinkUnion } from '@pay/admin-ui-kit';

import { CurrentUserModel } from 'modules/auth';
import { RouteConfig } from 'modules/common/routing';
import { EPermissionType } from 'modules/user-management';
import { IAppTransFunction } from 'startup/utils';
import { hasOneOfPermissions, isServiceMealAdmin } from '../utils/permission';
import { MealAdminPage } from './MealAdminPage';
import { ReportsPage } from 'modules/reports';
import { SchoolsPage } from 'modules/schools';

const reportPaths = {
  admin: '/meals/admin',
  reports: '/meals/reports',
  schools: '/meals/schools',
} as const;

export const getNavigationLinks = ({
  userModel,
  t,
}: {
  userModel: CurrentUserModel;
  t: IAppTransFunction;
}): INavigationLinkUnion | null => {
  if (!hasOneOfPermissions(userModel, [EPermissionType.ServiceMealReports])) return null;

  const link: INavigationLinkGroup = {
    group: true,
    menuIcon: InsertDriveFileOutlined,
    title: t('navigation_service_meals'),
    key: 'meals',
    subLinks: [
      {
        child: true,
        group: false,
        path: reportPaths.reports,
        exact: false,
        title: t('navigation_reports_nb'),
      },
    ],
  };
  if (isServiceMealAdmin(userModel)) {
    const adminSublink: INavigationLinkGroup['subLinks'][number] = {
      child: true,
      group: false,
      path: reportPaths.admin,
      exact: false,
      title: t('navigation_service_meals_admin'),
    };
    link.subLinks = [adminSublink, ...link.subLinks];
  }
  if (userModel.isSuperAdmin()) {
    const schoolSublink: INavigationLinkGroup['subLinks'][number] = {
      child: true,
      group: false,
      path: reportPaths.schools,
      exact: false,
      title: t('navigation_service_meals_schools'),
    };
    link.subLinks = [...link.subLinks, schoolSublink];
  }
  return link;
};

export const getRoutes = (): RouteConfig[] => {
  return [
    {
      path: reportPaths.admin,
      exact: false,
      component: () => <MealAdminPage />,
      permissions: withdminPermissions([
        EPermissionType.Superadmin,
        EPermissionType.ServiceMealAdmin,
      ]),
    },
    {
      path: reportPaths.reports,
      exact: false,
      component: () => <ReportsPage />,
      permissions: withdminPermissions([EPermissionType.ReportsRead]),
    },
    {
      path: reportPaths.schools,
      exact: false,
      component: () => <SchoolsPage />,
      permissions: [EPermissionType.Superadmin],
    },
  ];
};

const withdminPermissions = (permissions: EPermissionType[] = []) => [
  EPermissionType.ServiceMealAdmin,
  ...permissions,
];
