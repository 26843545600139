import { Subject } from 'rxjs';
import { useGlobalContext } from '@pay/modules';
import { TestClientService } from './service/TestClientsService';

export interface TestClientsModuleContext {
  testClients: {
    service: TestClientService;
    events: {
      onRefreshTable$: Subject<{ itemId?: string }>;
    };
  };
}

export const useTestClientsService = () =>
  useGlobalContext<TestClientsModuleContext>().testClients.service;
export const useTestClientsEvents = () =>
  useGlobalContext<TestClientsModuleContext>().testClients.events;
