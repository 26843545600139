import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorScreen } from './ErrorScreen';

const useStyles = makeStyles((theme) => ({}));

interface IProps {}
export const UnkownErrorPage: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return <ErrorScreen shouldReload={true} title={t('unknown_error_page_title')} />;
};
