import {
  Box,
  Grid,
  Typography,
  TextField,
  Popover,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LocationNextDTO, SchoolDictionaryDto } from 'apis-generated/reports';
import { useTaskEither, useTaskEitherImmediate } from 'modules/common/async/hooks';
import { debounce } from 'lodash-es';
import { useDictionaryService } from 'modules/meals/module';
import { CustomTreeView } from 'modules/meals/ui/edit/TreeView';
import React from 'react';
import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Clear } from '@material-ui/icons';

export const LocationAndSchoolForm: React.FC<{ control: any }> = ({ control }) => {
  const { t } = useTranslation();
  const dictionaryService = useDictionaryService();

  const anchorEls = useRef<HTMLElement | null>(null);

  const [expanded, setExpanded] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>('');

  const [childData, setChildData] = useState<{ [key: string]: LocationNextDTO[] }>({});
  const [openPopover, setOpenPopover] = useState(false);

  const { state } = useTaskEitherImmediate(() =>
    dictionaryService.getLocations({ page: 0, size: 20 })
  );
  const { execute: updateRequest, state: childState } = useTaskEither(
    dictionaryService.getNextLocation
  );

  const handleSelect = (event: React.ChangeEvent<{}>, nodeId: string) => {
    clearUnrelatedNodes(nodeId);
    setSelectedLocation(nodeId);

    updateRequest({ parentCode: nodeId });
  };

  useEffect(() => {
    if (childState?.value && selectedLocation) {
      setChildData((prevData) => ({
        ...prevData,
        [selectedLocation]: childState.value,
      }));
    }
  }, [childData?.value, childState, selectedLocation]);

  const getParentCode = (code: string) => code.slice(0, 2);

  const clearUnrelatedNodes = (selectedNode: string) => {
    const parentCode = getParentCode(selectedNode);

    setChildData((prevData) => {
      const newChildData: { [key: string]: LocationNextDTO[] } = {};
      Object.keys(prevData).forEach((key) => {
        if (key.startsWith(parentCode) || key === selectedNode) {
          newChildData[key] = prevData[key];
        }
      });
      return newChildData;
    });

    setExpanded((prevExpanded) => {
      const isAlreadyExpanded = prevExpanded.includes(selectedNode);
      if (isAlreadyExpanded) {
        return prevExpanded.filter((id) => id !== selectedNode);
      } else {
        const newExpanded = prevExpanded.filter((id) => id.startsWith(parentCode));
        return [...newExpanded, selectedNode];
      }
    });
  };

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setSelectedLocation(nodeIds[0]);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    anchorEls.current = e.currentTarget;
    setOpenPopover(true);
  };

  const handleLocationClear = () => {
    setSelectedLocation('');
  };

  const { state: schoolsState, execute: getSchools } = useTaskEither(dictionaryService.getSchools);

  useEffect(() => {
    if (selectedLocation) {
      getSchools({ locationCodes: [selectedLocation] });
    }
    return () => {
      getSchools({});
    };
  }, [getSchools, selectedLocation]);

  const schoolOptions = useMemo(() => {
    if (schoolsState?.value) {
      return schoolsState.value;
    }
    return [];
  }, [schoolsState?.value]);

  const debouncedFetchSchools = useMemo(
    () =>
      debounce((symbol?: string) => {
        getSchools({ locationCodes: [selectedLocation], search: symbol });
      }, 1000),
    [getSchools, selectedLocation]
  );

  const handleFetchSchools = useCallback(
    (symbol?: string) => {
      debouncedFetchSchools(symbol);
    },
    [debouncedFetchSchools]
  );

  return (
    <Box mt={2}>
      <Grid container item key={1} alignItems="center" xs={12} sm={6}>
        <Grid container item alignItems="center" xs={12}>
          <Grid item xs={2}>
            <Typography>{t('report_meal_voucher_region')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name={'locationCode'}
              control={control}
              render={({ field: { value, onChange, ...fieldProps } }) => (
                <>
                  <TextField
                    variant="outlined"
                    size="small"
                    id={'locationCode'}
                    value={selectedLocation}
                    onClick={handleClick}
                    fullWidth
                    {...fieldProps}
                    InputProps={{
                      endAdornment: (
                        <Box width={30}>
                          {selectedLocation && (
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleLocationClear();
                                onChange('');
                              }}
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          )}
                        </Box>
                      ),
                    }}
                  />
                  <Popover
                    id={'region_popover'}
                    open={openPopover}
                    anchorEl={anchorEls.current || null}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <CustomTreeView
                      regionsData={state?.value?.content || []}
                      childData={childData}
                      expanded={expanded}
                      selected={selectedLocation}
                      onNodeToggle={handleToggle}
                      onNodeSelect={(e, nodeId) => {
                        handleSelect(e, nodeId);
                        onChange(nodeId);
                      }}
                    />
                  </Popover>
                </>
              )}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} alignItems="center">
          <Grid item xs={2}>
            <Typography>{t('report_meal_voucher_school')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1}>
              <Controller
                name={'schoolBin'}
                control={control}
                render={({ field: { ref, onChange, value, ...fieldProps }, fieldState }) => (
                  <Autocomplete<SchoolDictionaryDto>
                    onInputChange={(_, value, reason) => {
                      if (reason === 'input') {
                        handleFetchSchools(value);
                      }
                    }}
                    onChange={(e, val) => {
                      onChange(val?.bin);
                    }}
                    value={
                      schoolOptions.find((school: SchoolDictionaryDto) => school.bin === value) ||
                      null
                    }
                    options={schoolOptions}
                    getOptionLabel={(school) =>
                      school.number ? `Школа №${school.number}` : school.bin || ''
                    }
                    renderOption={(op) => {
                      return (
                        <Box flexDirection="column" overflow="auto" mb={1}>
                          {op.number && <Typography>Школа №{op.number}</Typography>}
                          <Typography>{op.bin}</Typography>
                        </Box>
                      );
                    }}
                    {...fieldProps}
                    noOptionsText={t('common_table_empty')}
                    renderInput={(params) => (
                      <TextField
                        margin="dense"
                        contentEditable={false}
                        error={!!fieldState.error}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
