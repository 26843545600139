import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';

import { mapFetcherResultEither } from 'modules/common/fetcher';
import { IFetcher } from '@pay/data-fetching';

import { TEtoPromise } from 'modules/common/fpts-utils';
import {
  AddSchoolRequest,
  EditSchoolRequest,
  ListQueryParams,
  SchoolControllerApiInterface,
} from 'apis-generated/school-meal';

export const makeSchoolService = (fetcher: IFetcher, api: SchoolControllerApiInterface) => {
  return {
    fetchTable: (args: ListQueryParams) => {
      return pipe(
        () => api.list(args),
        T.map(mapFetcherResultEither),
        TE.map((items) => {
          return { items, total: items.total };
        }),
        TEtoPromise
      );
    },

    createSchool: (request: AddSchoolRequest) =>
      pipe(() => api.addSchool({ addSchoolRequest: request }), T.map(mapFetcherResultEither)),

    editSchool: (request: EditSchoolRequest, bin: string) =>
      pipe(
        () => api.editSchool({ editSchoolRequest: request }, { bin: bin }),
        T.map(mapFetcherResultEither)
      ),

    deleteSchool: (bin: string) =>
      pipe(() => api.deleteSchool({ bin: bin }), T.map(mapFetcherResultEither)),
  };
};

export type SchoolService = ReturnType<typeof makeSchoolService>;
