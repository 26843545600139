import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';

import { mapFetcherResultEither } from 'modules/common/fetcher';
import { IFetcher } from '@pay/data-fetching';
import {
  TerminalApkControllerApiInterface,
  TerminalPlanApkInstallRequest,
} from 'apis-generated/mapper-documents-admin';
import { TEtoPromise } from 'modules/common/fpts-utils';
import { TerminalApiInterface } from 'apis-generated/mapper-sso-admin';

export const makePosTerminalsService = (
  fetcher: IFetcher,
  api: TerminalApkControllerApiInterface,
  deletePosTerminalApi: TerminalApiInterface
) => {
  return {
    // apk file list is not save in the database,
    // so we need to transform the fetcher result to a table data source
    fetchTable: () => {
      return pipe(
        api.apkList,
        T.map(mapFetcherResultEither),
        TE.map((items) => {
          return { items, total: items.length };
        }),
        TEtoPromise
      );
    },
    fetchTerminalListByIinOrBin: (iinOrBin: string) => {
      return pipe(
        () => api.getTerminalListByIinOrBin({ iinOrBin }),
        T.map(mapFetcherResultEither),
        TE.map((items) => {
          return { items, total: items.length };
        }),
        TEtoPromise
      );
    },

    uploadApk: (body: FormData) =>
      pipe(
        () => fetcher.post<void>(`/bo/terminal/apk`, { body }),
        T.map(mapFetcherResultEither)
      ),

    apkList: () => pipe(() => api.apkList(), T.map(mapFetcherResultEither)),

    changeTerminalApkVersion: (requestData: TerminalPlanApkInstallRequest) =>
      pipe(
        () => api.planInstallationApkForTerminal({ terminalPlanApkInstallRequest: requestData }),
        T.map(mapFetcherResultEither)
      ),

    deactivatePosTerminal: (id: string) =>
      pipe(
        () => deletePosTerminalApi.deactivateTerminalById({ id: id }),
        T.map(mapFetcherResultEither)
      ),
  };
};

export type PosTerminalApkService = ReturnType<typeof makePosTerminalsService>;
