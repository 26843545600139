import { Fetcher } from '@pay/data-fetching';
import { makeDeletePosTerminalApi, makePostTerminalApkApi } from './api';
import { PosTerminalModuleContext } from './module';
import { makePosTerminalsService } from './services/PostTerminalApkService';
import { Subject } from 'rxjs';

export { PosTerminalApkPage } from './ui/PosTerminalApkPage';

export { usePosTerminalsService } from './module';
export { getPosTerminalInfoColumns } from './ui/tableDefs';

export const initializePosTerminalssModuleContext = (
  documentsFetcher: Fetcher,
  ssoFetcher: Fetcher
): PosTerminalModuleContext => {
  const posTerminalApkApi = makePostTerminalApkApi(documentsFetcher);
  const deletePosTerminalApi = makeDeletePosTerminalApi(ssoFetcher);

  const apkService = makePosTerminalsService(
    documentsFetcher,
    posTerminalApkApi,
    deletePosTerminalApi
  );
  return {
    posTerminals: {
      apkService,
      events: {
        onRefreshTable$: new Subject<{ itemId?: string }>(),
      },
    },
  };
};
