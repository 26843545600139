import { FilterType, TextFilterFormat, Column } from '@pay/admin-data-table';
import { IAppTransFunction } from 'startup/utils';
import { DATE_FORMAT } from 'modules/common/constants';
import React from 'react';
import { formatDate } from 'modules/common/format';
import { IMockCoidLogs } from '../utils';

export const getColumns = (t: IAppTransFunction): Column<IMockCoidLogs>[] => {
  return [
    {
      accessor: 'fromDevice',
      Header: t('coid_table_column_from_device') as string,
      customFilter: {
        field: 'fromDevice',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },
    {
      accessor: 'firstName',
      Header: t('coid_table_column_user_firstname') as string,
      customFilter: {
        field: 'firstName',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },
    {
      accessor: 'lastName',
      Header: t('coid_table_column_user_lastname') as string,
      customFilter: {
        field: 'lastName',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },
    {
      accessor: 'patronymic',
      Header: t('coid_table_column_user_patronymic') as string,
      customFilter: {
        field: 'patronymic',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },
    {
      accessor: 'phoneNumber',
      Header: t('coid_table_column_user_phone_number') as string,
      customFilter: {
        field: 'phoneNumber',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },
    {
      accessor: 'IIN',
      Header: t('coid_table_column_user_iin') as string,
      customFilter: {
        field: 'IIN',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },
    {
      accessor: 'appealDate',
      Header: t('coid_table_column_appeal_date') as string,
      Cell: ({ value }) => <>{formatDate(value, DATE_FORMAT.DD_MM_YYYY_HH_mm)}</>,
      customFilter: {
        field: 'appealDate',
        type: FilterType.DateRange,
        withTime: true,
      },
    },
    {
      accessor: 'result',
      Header: t('coid_table_column_result') as string,
      customFilter: {
        field: 'result',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },
  ];
};
