import { SelectFilterItem } from '@pay/admin-data-table';
import { observable } from 'mobx';
import { delay } from '@pay/common-utils';
import { Fetcher } from '@pay/data-fetching';

export class DictionariesStore {
  @observable.ref public limitRestrictionPeriods: SelectFilterItem[] | undefined;
  constructor(private _fetcher: Fetcher) {}

  public async loadDictionaries() {
    await delay(100);

    this.limitRestrictionPeriods = [
      {
        value: 'MONTH',
        title: 'Месяц',
      },
      {
        value: 'INFINITE',
        title: 'Бессрочно',
      },
    ];
  }

  public async getRestrictionPeriods(): Promise<SelectFilterItem[]> {
    await delay(100);

    return [
      {
        value: 'MONTH',
        title: 'Месяц',
      },
      {
        value: 'INFINITE',
        title: 'Бессрочно',
      },
    ];
  }
}
