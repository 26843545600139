import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  makeStyles,
} from '@material-ui/core';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { pipe } from 'fp-ts/lib/function';
import { Button } from '@pay/mui-enhancement';
import { combineValidators, makeRequiredValidator } from '@pay/common-utils';
import { LoadingOverlay, useNotificationsStore } from '@pay/admin-ui-kit';

import { BOMealUserDto, RoleRespDto } from 'apis-generated/mapper-sso-admin';
import { IRemoteError } from 'modules/common/store';
import { I2FaVerificationError } from 'modules/auth/constants';
import { useBOMealUserService, useMealsEvents } from 'modules/meals/module';
import { COUNTRY_PHONE_CODE, ECountryCode, parsePhone } from 'modules/common/format-phone';
import { useTaskEitherImmediate } from 'modules/common/async/hooks';
import { emailValidator } from 'modules/common/validators';
import { phoneValidator } from 'modules/user-management/users/ui/edit/utils';
import { ErrorAlert } from 'modules/common/ui/errors';
import { matchI } from 'modules/common/match';
import { extractErrorMessage } from 'modules/common/errors';
import { useAuthModals } from 'modules/auth';
import { TITLE_MODE_MAP } from 'modules/meals/constants';
import { ITransKey } from 'startup/i18n';
import { useTranslation } from 'startup/utils';
import { EMode, FormValues } from '../../types';
import { UserForm } from './UserForm';
import { SchoolLocationsForm } from './SchoolLocationsForm';

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    autocompleteInput: {
      '&$root &': {
        padding: '0 4px',
      },
    },
  }),
  { name: 'MealEditForm' }
);

interface IProps {
  onClose: () => void;
  mode: EMode;
  userCanEdit?: boolean;
  originalUser?: BOMealUserDto;
  onStartEdit?: () => void;
  onSave?: (
    values: FormValues,
    roles: string[]
  ) => TE.TaskEither<IRemoteError | { type: 'user-already-exists' }, unknown>;
  onLoadRoles?: () => TE.TaskEither<IRemoteError | I2FaVerificationError, RoleRespDto[]>;
}

export const MealEditForm: FC<IProps> = ({
  onClose,
  onSave,
  mode,
  originalUser,
  userCanEdit,
  onLoadRoles,
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const notificationStore = useNotificationsStore();
  const boMealUserService = useBOMealUserService();
  const { showSecondAuthModal } = useAuthModals();
  const [error, setError] = useState<IRemoteError | { type: 'user-already-exists' } | undefined>();
  const [roles, setRoles] = useState<RoleRespDto[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const { onRefreshTable$ } = useMealsEvents();

  const rolesState = useTaskEitherImmediate(() => boMealUserService.getMealRoles());
  const steps = [
    t('navigation_service_meals_admin'),
    t('navigation_service_meals_admin_region_school'),
  ];

  const rolesOptions = useMemo(() => {
    if (rolesState.state.value) {
      return rolesState.state.value;
    }
    return [];
  }, [rolesState.state.value]);

  useEffect(() => {
    if (rolesState.state.value) {
      const origingalRoles = originalUser?.roles?.map((role) => role.id);
      if (origingalRoles && origingalRoles.length) {
        const newRoles = rolesState.state.value.filter((role) => origingalRoles.includes(role.id));
        setRoles(newRoles);
      }
    }
  }, [originalUser, rolesState.state.value]);

  const handleRolesChange = useCallback(
    (ev: unknown, newRoles: RoleRespDto[] | null) => {
      setRoles(newRoles ?? []);
    },
    [setRoles]
  );

  const { control, handleSubmit, formState, setValue, getValues } = useForm<FormValues>({
    defaultValues: {
      email: originalUser?.email,
      firstName: originalUser?.firstName,
      lastName: originalUser?.lastName,
      position: originalUser?.position,
      username: originalUser?.username,
      phone: originalUser?.phone ? parsePhone(originalUser.phone)?.[1] ?? '' : '',
      schoolLocations: originalUser?.schoolLocations,
    },
  });

  const schoolLocationsValue = getValues('schoolLocations');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDeleteUser = useCallback(() => {
    if (!originalUser) {
      return;
    }
    showSecondAuthModal({
      onSubmit: (code) => {
        return pipe(
          boMealUserService.deleteMealUser(originalUser.id, { code }),
          TE.map((_) => onRefreshTable$.next({ itemId: originalUser.id })),
          TE.fold(
            (e) => T.of(e),
            (_) => {
              onClose();
              notificationStore.showNotification({
                text: t('user_delete_success', { userName: originalUser.username }),
                options: { variant: 'success' },
              });
              return T.of(undefined);
            }
          )
        )();
      },
    });
  }, [notificationStore, onClose, boMealUserService, originalUser, showSecondAuthModal, t]);

  const handleSubmitInternal = useCallback(
    async (values: FormValues) => {
      setError(undefined);
      if (!onSave) {
        return;
      }
      const phoneNumberRaw =
        values.phone && COUNTRY_PHONE_CODE[ECountryCode.Kk] + values.phone.replace(/ /g, '');

      return pipe(
        onSave(
          {
            ...values,
            phone: phoneNumberRaw,
          },
          roles.map((role) => role.id)
        ),
        TE.map((_) => onRefreshTable$.next({})),
        TE.fold(
          (e) => {
            setError(e);
            return T.of(undefined);
          },
          (r) => {
            onClose();
            return T.of(undefined);
          }
        )
      )();
    },
    [onClose, onSave, roles]
  );

  const validateEmail = useMemo(
    () =>
      combineValidators(makeRequiredValidator<ITransKey>('validation_required'), emailValidator),
    []
  );
  const validatePhone = useMemo(
    () =>
      combineValidators(makeRequiredValidator<ITransKey>('validation_required'), phoneValidator),
    []
  );

  if (rolesState.state.loading) {
    return <LoadingOverlay />;
  }

  const errorText = error
    ? matchI(error)({
        'user-already-exists': () => t('user_already_exists'),
        unknown: (err) => extractErrorMessage(err, t),
      })
    : '';

  return (
    <Box style={{ width: '100%', overflowY: 'auto' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 ? (
        <>
          <UserForm
            onClose={onClose}
            control={control}
            classes={classes}
            mode={mode}
            formState={formState}
            userCanEdit={userCanEdit}
            onStartEdit={props.onStartEdit}
            handleDeleteUser={handleDeleteUser}
            originalUser={originalUser as BOMealUserDto}
            handleRolesChange={handleRolesChange}
            roles={roles}
            rolesOptions={rolesOptions}
            validateEmail={validateEmail}
            validatePhone={validatePhone}
            handleNext={handleNext}
          />
        </>
      ) : (
        <>
          <DialogTitle> {t(TITLE_MODE_MAP[mode])}</DialogTitle>
          <DialogContent dividers className={classes.content}>
            <ErrorAlert error={errorText} />

            <SchoolLocationsForm
              control={control}
              mode={mode}
              onLocationSelected={setValue}
              initialLocationSelected={schoolLocationsValue}
            />
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button disableElevation variant="text" onClick={onClose}>
              {mode === EMode.View ? t('common_close') : t('common_cancel')}
            </Button>
            <Box>
              <Button disableElevation variant="text" onClick={handleBack}>
                {t('common_back')}
              </Button>
              {mode !== EMode.View && (
                <Button
                  form="MealEditForm"
                  loading={formState.isSubmitting}
                  disableElevation
                  type="submit"
                  onClick={handleSubmit(handleSubmitInternal)}
                  disabled={Object.keys(formState.errors).length > 0}
                  variant="contained"
                  color="primary"
                >
                  {t('common_save')}
                </Button>
              )}
            </Box>
          </DialogActions>
        </>
      )}
    </Box>
  );
};
