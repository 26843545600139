import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { format } from 'date-fns';

import {
  CommonResponseWrapperBOFinancialOrganization,
  DashboardDataRequest,
  DashboardDataResponse,
  FinancialOrganizationApiInterface,
} from 'apis-generated/mapper-processing-admin';
import { mapFetcherResultEither } from 'modules/common/fetcher';
import { DATE_FORMAT } from 'modules/common/constants';
import { IRemoteError } from 'modules/common/store';
import { IDashboardError } from '../constants';

export class DashboardService {
  constructor(private _api: FinancialOrganizationApiInterface) {}

  public fetchAllFinOrganizations = () => {
    return pipe(
      () => this._api.getFinancialOrganizations({}),
      T.map(mapFetcherResultEither),
      TE.map(mapAllFinOrganization)
    );
  };

  public fetchFinOrganizationAvailability = (
    request: DashboardDataRequest
  ): TE.TaskEither<IRemoteError | IDashboardError, IDataEntry[]> => {
    return pipe(
      () => this._api.financialOrganizationDashboardData({ dashboardDataRequest: request }),
      T.map(mapFetcherResultEither),
      TE.map(mapDashboardResult),
      TE.mapLeft((err) => {
        if (err && err.serverError?.hasCode('400000')) {
          return { type: 'DataExceeded' };
        }
        return err;
      })
    );
  };
}

export interface IDataEntry {
  dateTime: string;
  available: number;
  notAvailable: number;
}

const mapDashboardResult = (entries: DashboardDataResponse): IDataEntry[] => {
  const mappedResult = entries.map((entry) => ({
    available: entry.available === true ? 1 : 0,
    notAvailable: entry.available === false ? -1 : 0,
    dateTime: format(new Date(entry.dateTime), DATE_FORMAT.DD_MM_YYYY_HH_mm),
  }));
  return mappedResult;
};

const mapAllFinOrganization = (finOrgs: CommonResponseWrapperBOFinancialOrganization) =>
  finOrgs.items?.filter((org) => org.available) || [];
