import { IFetcher } from '@pay/data-fetching/dist/Fetcher/IFetcher';

import { AgreementModuleContext } from './module';
import { makeAgreementApi } from 'api';
import { AgreementService } from './service/AgreementService';

export { NewAgreementModal } from './ui/NewAgreementModal';
export { AgreementPage } from './ui/AgreementPage';

export const initializeAgreementModuleContext = (ssoFetcher: IFetcher): AgreementModuleContext => {
  const agreementApi = makeAgreementApi(ssoFetcher);

  const service = new AgreementService(agreementApi, ssoFetcher);

  return {
    agreement: {
      service,
    },
  };
};
