import { IFetcher } from '@pay/data-fetching/dist/Fetcher/IFetcher';
import { IStorageManager } from '@pay/storage';
import { AuthModuleContext } from './module';
import { AuthService } from './service/AuthService';
import { makeBackofficeUserApi, makeVerifyApi } from 'api';
import { AuthStore } from './data';

export { makeAuthErrorMiddleware, CurrentUserModel, ELogoutReason } from './data';
export { EAuthCodeError } from './constants';
export { AUTH_STORE_KEY } from './utils';

export { AuthPage } from './ui/AuthPage';
export type { ISecondAuthCodeModalProps } from './ui/SecondAuthModal/SecondAuthCodeModal';
export { ResetSecondAuthContainer } from './ui/reset-second-factor/ResetSecondAuthContainer';
export { AuthModalsProvider, useAuthModals } from './ui/AuthProvider';
export { SecondFactorCodeForm } from './ui/second-factor/SecondFactorCodeForm';
export { ChangePasswordContainer } from './ui/change-password/ChangePasswordContainer';
export { ProfileCard } from './ui/profile-card/ProfileCard';
export { WithPermissions } from './ui/common/WIthPermissions';

export { map2FaVerificationError } from './service/utils';
export { AuthStore };
export { useAuthStore } from './module';

export const initializeAuthModuleContext = (
  ssoFetcher: IFetcher,
  storage: IStorageManager
): AuthModuleContext => {
  const authApi = makeBackofficeUserApi(ssoFetcher);
  const verifyApi = makeVerifyApi(ssoFetcher);

  const service = new AuthService(authApi, ssoFetcher, verifyApi);

  return {
    auth: {
      service: service,
      store: new AuthStore(service, storage, false),
    },
  };
};
