import { Badge, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { DataTable, TableToolbar, useTable } from '@pay/admin-data-table';
import { SimplePopup, useModals, useNotificationsStore } from '@pay/admin-ui-kit';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLatest } from 'react-use';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { pipe } from 'fp-ts/lib/function';

import { WithPermissions } from 'modules/auth';
import { useDetailsStore } from 'modules/common/details';
import { EPermissionType } from 'modules/user-management';
import { ROLE_DETAILS_ENTITY_ID } from 'modules/user-management/constants';
import { useRolesStore } from '../../module';
import { getRoleColumns } from './tableDefs';

interface IProps {}

export const RolesPane: FC<IProps> = () => {
  const { t } = useTranslation();
  const store = useRolesStore();
  const notStore = useNotificationsStore();
  const { showModal } = useModals();
  const detailsStore = useDetailsStore();

  const columns = useMemo(() => getRoleColumns(t), [t]);
  const { tableProps, toolBarProps, refresh, refreshIfContainsId } = useTable({
    selectable: true,
    columns: columns,
    keyGetter: (it) => it.id,
    fetchData: store.fetchAllRoles,
  });

  const selectedRows = Object.keys(tableProps.tableInstance.state.selectedRowIds);
  const selectedRowsRef = useLatest(selectedRows);

  useEffect(() => {
    const updatesHandler = (arg: { roleId: string | undefined }) => {
      if (arg.roleId) {
        refreshIfContainsId([arg.roleId]);
      } else {
        refresh();
      }
    };

    const sub = store.updates$.subscribe(updatesHandler);
    return () => {
      sub.unsubscribe();
    };
  }, [refresh, refreshIfContainsId, store.updates$]);

  const handleAddRoleClick = useCallback(() => {
    detailsStore.openNewDetails(ROLE_DETAILS_ENTITY_ID);
  }, [detailsStore]);

  const handleDeleteRoles = useCallback(
    async (close: () => void) => {
      const ids = selectedRowsRef.current;
      return pipe(
        store.delete(ids),
        TE.fold(
          (e) => {
            notStore.showNotification({
              text: t('roles_delete_failure'),
            });
            return T.of(undefined);
          },
          (_) => {
            notStore.showNotification({
              text: t('roles_delete_success'),
            });
            refreshIfContainsId(ids);
            tableProps.tableInstance.toggleAllRowsSelected(false);
            close();
            return T.of(undefined);
          }
        )
      )();
    },
    [notStore, refreshIfContainsId, selectedRowsRef, store, t, tableProps.tableInstance]
  );

  const handleDeleteRolesClick = useCallback(() => {
    showModal((close) => (
      <SimplePopup
        content={t('roles_delete_confirmation')}
        title={t('common_delete')}
        okLabel={t('common_delete')}
        onOk={() => handleDeleteRoles(close)}
      />
    ));
  }, [handleDeleteRoles, showModal, t]);

  return (
    <>
      <TableToolbar {...toolBarProps} columns={columns}>
        <WithPermissions allow={[EPermissionType.RolesWrite, EPermissionType.System]}>
          <Badge
            invisible={!selectedRows.length}
            color="secondary"
            badgeContent={selectedRows.length}
          >
            <Button
              startIcon={<Delete />}
              disableElevation
              disabled={!selectedRows.length}
              variant="contained"
              color="default"
              onClick={handleDeleteRolesClick}
            >
              {t('common_delete')}
            </Button>
          </Badge>
          <Button disableElevation variant="contained" color="primary" onClick={handleAddRoleClick}>
            {t('users_page_action_new_role')}
          </Button>
        </WithPermissions>
      </TableToolbar>
      <DataTable {...tableProps} />
    </>
  );
};
