/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { IFetcherResult, IRequestInit, Fetcher } from '@pay/data-fetching';
import {
  CommonResponseWrapperAgreementUpdateScheduleRespDto,
  CommonResponseWrapperPrivacyPolicyHistoryRespDto,
  UpdatePrivacyPolicyRequest,
} from '../models';

export interface DownloadPrivacyPolicyPathParams {
  id: string;
  type: DownloadPrivacyPolicyTypeEnum;
}

export interface PrivacyPolicyHistoryQueryParams {
  search?: string;
}

export interface PrivacyPolicyUpdateScheduleHistoryQueryParams {
  search?: string;
}

export interface UpdatePrivacyPolicyOperationRequestBody {
  updatePrivacyPolicyRequest?: UpdatePrivacyPolicyRequest;
}

/**
 * PrivacyPolicyApi - interface
 *
 * @export
 * @interface PrivacyPolicyApiInterface
 */
export interface PrivacyPolicyApiInterface {
  /**
   *
   * @summary Скачать политику конфиденциальности
   * @param {string} id
   * @param {'DOCUMENT' | 'ACTIVATION'} type
   * @param {string} [xLocale]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PrivacyPolicyApiInterface
   * URL: /bo/privacy-policy/download/{type}/{id}
   * METHOD: GET
   */
  downloadPrivacyPolicy(pathParams: DownloadPrivacyPolicyPathParams): Promise<IFetcherResult<void>>;
  /**
   *
   * @summary Исторические данные политики конфидециальности
   * @param {string} [search]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PrivacyPolicyApiInterface
   * URL: /bo/privacy-policy/history
   * METHOD: GET
   */
  privacyPolicyHistory(
    queryParams: PrivacyPolicyHistoryQueryParams
  ): Promise<IFetcherResult<CommonResponseWrapperPrivacyPolicyHistoryRespDto>>;
  /**
   *
   * @summary График обновлений политики конфиденциальности
   * @param {string} [search]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PrivacyPolicyApiInterface
   * URL: /bo/privacy-policy/update-schedule
   * METHOD: GET
   */
  privacyPolicyUpdateScheduleHistory(
    queryParams: PrivacyPolicyUpdateScheduleHistoryQueryParams
  ): Promise<IFetcherResult<CommonResponseWrapperAgreementUpdateScheduleRespDto>>;
  /**
   *
   * @summary Обновить политику конфидециальности
   * @param {UpdatePrivacyPolicyRequest} [updatePrivacyPolicyRequest] Контент политики конфидециальности
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PrivacyPolicyApiInterface
   * URL: /bo/privacy-policy
   * METHOD: POST
   */
  updatePrivacyPolicy(
    requestParameters: UpdatePrivacyPolicyOperationRequestBody
  ): Promise<IFetcherResult<void>>;
}

/**
 * @export
 * @enum {string}
 */
export enum DownloadPrivacyPolicyTypeEnum {
  Document = 'DOCUMENT',
  Activation = 'ACTIVATION',
}
