import { IFetcher } from '@pay/data-fetching/dist/Fetcher/IFetcher';
import { LogsModuleContext } from './module';
import { LogsStore } from './data/LogsStore';

export { LogsPage } from './ui/LogsPage';

export const initializeLogsModuleContext = (ssoFetcher: IFetcher): LogsModuleContext => {
  return {
    logs: {
      store: new LogsStore(ssoFetcher),
    },
  };
};
