import { Box, Button, Grow, makeStyles, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Image from 'material-ui-image';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useQrStyles = makeStyles((theme) => ({
  root: {},
  qr: {
    width: '100%',
    display: 'block',
  },
}));

interface IAuthQrCodeBoxProps {
  qrUrl: string;
  secret: string;
}
export const AuthQrCodeBox: React.FC<IAuthQrCodeBoxProps> = (props) => {
  const { qrUrl, secret } = props;
  const classes = useQrStyles();
  const [isSecretVisible, setIsSecretVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Alert severity="info">{t('auth_code_new_description')}</Alert>
      <Box mb={1} mt={1} p={2}>
        <Box>
          <Image style={{ display: 'block' }} className={classes.qr} aspectRatio={1} src={qrUrl} />
        </Box>
        {!isSecretVisible && (
          <Box textAlign="center">
            <Button
              variant="text"
              size="small"
              color="primary"
              onClick={() => setIsSecretVisible(true)}
            >
              {t('auth_qr_secret_reveal')}
            </Button>
          </Box>
        )}
      </Box>
      <Grow in={isSecretVisible} unmountOnExit>
        <Box>
          <Typography variant="body2" color="textSecondary">
            {t('auth_qr_secret_description')}
          </Typography>
          <Box textAlign="center" mt={1}>
            <Paper variant="outlined">
              <Typography>{secret}</Typography>
            </Paper>
          </Box>
        </Box>
      </Grow>
    </>
  );
};
