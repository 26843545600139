import React, { useMemo, FC } from 'react';
import { DataTable, TableToolbar, useTable } from '@pay/admin-data-table';
import { ComparisonOperator } from '@pay/admin-resources';

import { ClientAuditRespDto, ClientDetailRespDto } from 'apis-generated/mapper-sso-admin';
import { useTranslation } from 'startup/utils';
import { getClientLogsColumns } from './tableDefs';
import { useClientService } from '../../../module';

interface IProps {
  client: ClientDetailRespDto;
}

export const ClientLogsPane: FC<IProps> = ({ client }) => {
  const service = useClientService();
  const { t } = useTranslation();
  const columns = useMemo(() => getClientLogsColumns(t), [t]);

  const { tableProps, toolBarProps } = useTable<ClientAuditRespDto>({
    fetchData: (column, query) =>
      service.getClientLogs(column, {
        ...query,
        additionalRsql: {
          selector: 'user.id',
          arguments: client.clientId,
          operator: ComparisonOperator.Equals,
        },
      }),
    columns,
    keyGetter: (it) => it.id,
  });

  return (
    <div>
      <TableToolbar<ClientAuditRespDto> {...toolBarProps} columns={columns} />
      <DataTable<ClientAuditRespDto> {...tableProps} />
    </div>
  );
};
