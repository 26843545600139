import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeRhfMuiTextFieldProps } from '@pay/mui-enhancement';
import React, { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DateTimePicker } from '@material-ui/pickers';
import { pipe } from 'fp-ts/es6/function';
import { useNotificationsStore } from '@pay/admin-ui-kit';
import * as TE from 'fp-ts/TaskEither';

import { requiredDateValidate, requiredValidator } from 'modules/common/validators';
import { useAgreementService } from '../module';
import { DATE_FORMAT } from 'modules/common/constants';

const useStyles = makeStyles((theme) => ({
  root: {},
  buttons: {
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
}));

interface FormValues {
  contentRu: string;
  contentKk: string;
  activationDate: Date;
}

interface IProps extends Omit<DialogProps, 'children'> {
  onClose: () => void;
  isEdit?: boolean;
}

export const NewAgreementModal: FC<IProps> = ({ onClose, isEdit, ...dialogProps }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm<FormValues>({
    defaultValues: { contentKk: '', contentRu: '', activationDate: new Date() },
  });
  const agreementService = useAgreementService();
  const { showNotification } = useNotificationsStore();

  const handleSubmitInternal = useCallback(
    (values: FormValues) => {
      pipe(
        agreementService.updateAgreement({
          contentRu: values.contentRu,
          contentKz: values.contentKk,
          activationDate: values.activationDate,
        }),
        TE.map(() => onClose()),
        TE.mapLeft(() => {
          showNotification({
            text: t('agreement_create_error'),
            options: { variant: 'error' },
          });
        })
      )();
    },
    [agreementService, onClose, showNotification, t]
  );

  return (
    <>
      <Dialog {...dialogProps} onClose={onClose} PaperProps={{ style: { width: 488 } }}>
        <DialogTitle>{t('offer_page_title')}</DialogTitle>
        <DialogContent dividers>
          <Grid
            id="AgreementForm"
            container
            spacing={3}
            component="form"
            onSubmit={handleSubmit(handleSubmitInternal)}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                name="contentKk"
                rules={{ validate: requiredValidator }}
                render={({ field }) => (
                  <TextField
                    id="filled-multiline-static"
                    label={t('offer_kk_field_title')}
                    fullWidth
                    multiline
                    {...makeRhfMuiTextFieldProps(errors.contentKk, t)}
                    rows={10}
                    variant="filled"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="contentRu"
                rules={{ validate: requiredValidator }}
                render={({ field }) => (
                  <TextField
                    id="filled-multiline-static"
                    label={t('offer_ru_field_title')}
                    fullWidth
                    multiline
                    {...makeRhfMuiTextFieldProps(errors.contentRu, t)}
                    rows={10}
                    variant="filled"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="activationDate"
                rules={{ validate: requiredDateValidate }}
                render={({ field }) => (
                  <DateTimePicker
                    size="small"
                    ampm={false}
                    label={t('agreement_activation_date_time')}
                    format={DATE_FORMAT.DD_MM_YYYY_HH_mm}
                    disablePast
                    showTodayButton
                    cancelLabel={t('common_cancel')}
                    okLabel={t('common_ok')}
                    todayLabel={t('common_today')}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttons}>
            <Button variant="text" color="primary" onClick={onClose}>
              {t('common_close')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form="AgreementForm"
              disabled={!formState.isValid && formState.isSubmitted}
            >
              {isEdit ? t('agreement_edit_action_title') : t('agreement_create_action_title')}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

NewAgreementModal.displayName = 'NewAgreementModal';
