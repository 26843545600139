import { EPermissionType } from 'modules/user-management';
import { EPermissionGroup, PERMISSION_GROUPS } from '../constants';

export const PERMISSION_GROUPS_ARRAY = Object.keys(PERMISSION_GROUPS).map((gr) => ({
  key: gr as EPermissionGroup,
  permissions: PERMISSION_GROUPS[gr as EPermissionGroup],
}));

export const isSomePermissionsSet = (
  group: EPermissionGroup,
  state: Record<EPermissionType, boolean>
) => {
  return PERMISSION_GROUPS[group].some((perm) => state[perm]);
};

export const isAllPermissionsSet = (
  group: EPermissionGroup,
  state: Record<EPermissionType, boolean>
) => {
  return PERMISSION_GROUPS[group].every((perm) => state[perm]);
};
