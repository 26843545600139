import { useEffect } from 'react';
import { Observable } from 'rxjs';

/**
 * Add a subscription upon component mouting
 * @param observable Source
 * @param fn Subscription function. Must be memoized
 */
export const useSubscription = <T>(observable: Observable<T>, fn: (arg: T) => void) => {
  useEffect(() => {
    const sub = observable.subscribe(fn);
    return () => {
      sub.unsubscribe();
    };
  }, [fn, observable]);
};
