import { IFetcher } from '@pay/data-fetching/dist/Fetcher/IFetcher';

import { UserManagementModuleContext } from './module';
import { makeBackofficeUserApi, makeRolesApi } from 'api';

import { UsersStore } from './users';
import { RolesStore } from './roles';
export * from './roles';
export * from './users';
export * from './constants';

export const initializeUserManagementModuleContext = (
  ssoFetcher: IFetcher
): UserManagementModuleContext => {
  const backofficeUserApi = makeBackofficeUserApi(ssoFetcher);
  const backofficeRolesApi = makeRolesApi(ssoFetcher);

  return {
    userManagement: {
      usersStore: new UsersStore(ssoFetcher, backofficeUserApi),
      rolesStore: new RolesStore(backofficeRolesApi, ssoFetcher),
    },
  };
};
