import { IFetcher } from '@pay/data-fetching';
import { DictionaryControllerApiInterface } from 'apis-generated/reports';

export const makeCompanyServiceApi = (fetcher: IFetcher): DictionaryControllerApiInterface => {
  return {
    getServiceCompanies: () => {
      return fetcher.get(`bo/dictionary/service_companies`);
    },
    getSchools: () => fetcher.get(`bo/dictionary/schools`),
    getLocations: () => fetcher.get(`bo/dictionary/locations`),
    getNextLocation: () => fetcher.get(`bo/dictionary/location/children`),
  };
};
