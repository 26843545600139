/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface BlackboxData
 */
export interface BlackboxData {
    /**
     * 
     * @type {string}
     * @memberof BlackboxData
     */
    bin?: string;
    /**
     * 
     * @type {string}
     * @memberof BlackboxData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BlackboxData
     */
    clientPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BlackboxData
     */
    blackboxUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof BlackboxData
     */
    contactPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof BlackboxData
     */
    status?: BlackboxDataStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum BlackboxDataStatusEnum {
    Init = 'INIT',
    Processing = 'PROCESSING',
    Connected = 'CONNECTED'
}


