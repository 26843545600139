import { makeTableDataSource } from '@pay/admin-resources';
import { IFetcher } from '@pay/data-fetching';
import { BackofficeAuditRespDto } from 'apis-generated/mapper-sso-admin';

export class LogsStore {
  constructor(private _fetcher: IFetcher) {}

  public fetchUsersLogs = makeTableDataSource<BackofficeAuditRespDto>(
    this._fetcher,
    'bo/users/log'
  );
}
