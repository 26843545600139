import { ResourceStoreBase } from '@pay/admin-resources';
import { IClientWsApi, ITempClientAuditEntry } from '../api/ClientAuditApi';
import { IClientsApi } from '..';
import { ClientRespDto } from 'apis-generated/mapper-sso-admin';

class ClientTransactionStore extends ResourceStoreBase<
  ITempClientAuditEntry,
  ITempClientAuditEntry,
  IClientWsApi
> {}

export class ClientsStore extends ResourceStoreBase<ClientRespDto> {
  public readonly logsStore = new ClientTransactionStore(this._clientsWsApi);

  constructor(clientsApi: IClientsApi, private _clientsWsApi: IClientWsApi) {
    super(clientsApi);
  }
}
