import { Fetcher } from '@pay/data-fetching';
import { makeTestClientsApi } from './api';
import { TestClientsModuleContext } from './module';
import { makeTestClientsService } from './service/TestClientsService';
import { Subject } from 'rxjs';

export { TestClientsPage } from './ui/TestClientsPage';

export const initializeTestClientsModuleContext = (
  ssoFetcher: Fetcher
): TestClientsModuleContext => {
  const testPhonesApi = makeTestClientsApi(ssoFetcher);

  const service = makeTestClientsService(ssoFetcher, testPhonesApi);
  return {
    testClients: {
      service,
      events: {
        onRefreshTable$: new Subject<{ itemId?: string }>(),
      },
    },
  };
};
