import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Logo } from './Logo';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      height: '100vh',
    },
    leftContent: {
      // minWidth: 400,
      // width: '33%',
      background:
        theme.palette.type === 'light'
          ? theme.palette.common.white
          : theme.palette.background.default,
      height: '100vh',
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      padding: theme.spacing(6),
      width: 240 + theme.spacing(6) * 2,
      overflowY: 'auto',
    },
    logoWrap: {
      width: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      padding: theme.spacing(2),
      flex: '0 0 35vh',
    },
    formWrap: {
      // flexBasis: '45%'
      flex: '1 0 auto',
      width: '100%',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      position: 'relative',
      flexFlow: 'column',
    },
    splashWrap: {
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      flexGrow: 1,
    },
    copyright: {
      flex: '0 0 auto',
      textAlign: 'center',
    },
  }),
  { name: 'AuthPage' }
);

interface IProps {
  backgroundUrl: string;
}

export const AuthFormWrapper: React.FC<IProps> = (props) => {
  const { backgroundUrl } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.leftContent}>
        <div className={classes.logoWrap}>
          <Box>
            <Logo />
          </Box>
        </div>
        <div className={classes.formWrap}>{props.children}</div>
        <Typography variant="body2" className={classes.copyright} color="textSecondary">
          @ 2020 Backoffice LTD
        </Typography>
      </div>
      <div
        className={classes.splashWrap}
        style={{ backgroundImage: `url(${backgroundUrl})` }}
      ></div>
    </div>
  );
};
