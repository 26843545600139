import { Subject } from 'rxjs';
import { useGlobalContext } from '@pay/modules';
import { PosTerminalApkService } from './services/PostTerminalApkService';

export interface PosTerminalModuleContext {
  posTerminals: {
    apkService: PosTerminalApkService;
    events: {
      onRefreshTable$: Subject<{ itemId?: string }>;
    };
  };
}

export const usePosTerminalsService = () =>
  useGlobalContext<PosTerminalModuleContext>().posTerminals.apkService;
export const usePosTerminalEvents = () =>
  useGlobalContext<PosTerminalModuleContext>().posTerminals.events;
