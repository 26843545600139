import { LegalAgreementService } from './service/LegalAgreementService';
import { useGlobalContext } from '@pay/modules';

export interface LegalAgreementModuleContext {
  legalAgreement: {
    service: LegalAgreementService;
  };
}

export const useLegalAgreementService = () =>
  useGlobalContext<LegalAgreementModuleContext>().legalAgreement.service;
