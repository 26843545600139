import { Page, PageTitle } from '@pay/admin-ui-kit';
import React, { FC, useState } from 'react';
import { useTranslation } from 'startup/utils';
import { Tab, Tabs } from '@material-ui/core';
import { ClientsTable } from './ClientsTable';
import { DeleteRequestClientsTable } from './DeleteRequestClientsTable';

export enum ETab {
  History = '',
  ChangeRequests = 'changeRequests',
}

interface IProps {}
export const ClientsPage: FC<IProps> = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(ETab.History);

  const handleChange = (event: React.ChangeEvent<{}>, tab: ETab) => {
    setTab(tab);
  };

  return (
    <Page>
      <PageTitle>{t('clients_page_title')}</PageTitle>
      <Tabs
        value={tab}
        indicatorColor="primary"
        onChange={handleChange}
        textColor="primary"
        variant="standard"
      >
        <Tab value={ETab.History} label={t('clients_page_title')} />
        <Tab value={ETab.ChangeRequests} label={t('clients_page_delete_request')} />
      </Tabs>
      {tab === ETab.History && <ClientsTable />}
      {tab === ETab.ChangeRequests && <DeleteRequestClientsTable />}
    </Page>
  );
};
