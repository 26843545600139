import { BOMealSchoolLocation } from 'apis-generated/mapper-sso-admin/models/BOMealSchoolLocation';

export enum EFormField {
  Username = 'username',
  Roles = 'roles',
  Firstname = 'firstName',
  Lastname = 'lastName',
  Email = 'email',
  Phone = 'phone',
  Position = 'position',
  SchoolLocations = 'schoolLocations',
}

export enum EMode {
  Create,
  Edit,
  View,
}

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
  username: string;
  roles: string[];
  schoolLocations: Array<BOMealSchoolLocation>;
}

export type IFormValues = Record<EFormField, string>;
