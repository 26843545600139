import { makeStyles, TextFieldProps } from '@material-ui/core';
import { IRifmTextFieldProps, makeRhfMuiTextFieldProps, RifmTextField } from '@pay/mui-enhancement';
import React, { useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { ITransKey } from 'startup/i18n';
import { CODE_LENGTH, formatSecondAuthCode, useTranslation } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

interface IProps extends Omit<TextFieldProps, 'onChange' | 'error'> {
  error: FieldError | undefined;
  onChange?: IRifmTextFieldProps['onChange'];
}
export const SecondAuthCodeField: React.FC<IProps> = (props) => {
  const { error, ...textFieldProps } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const errorTransFn = useCallback(
    (key: string) => t(key as ITransKey, { maxLength: CODE_LENGTH }),
    [t]
  );

  return (
    <RifmTextField
      rifm={{
        accept: /\d/g,
        format: formatSecondAuthCode,
      }}
      size="small"
      autoFocus
      fullWidth
      variant="outlined"
      label={t('auth_code')}
      inputProps={{ 'data-test-id': 'code' }}
      {...textFieldProps}
      {...makeRhfMuiTextFieldProps(error, errorTransFn)}
    />
  );
};
