import { ITransKey } from 'startup/i18n';

export enum ECurrencty {
  Kzt = 'KZT',
  Eur = 'EUR',
  Rur = 'RUR',
  Usd = 'USD',
}

export const DATE_FORMAT = {
  DD_MM_YYYY: 'dd.MM.yyyy',
  DD_MM_YYYY_HH_mm: 'dd.MM.yyyy HH:mm',
  API_DATE_ONLY: 'yyyy-MM-dd',
};

export const CURRENCY_TRANS_MAP: Record<ECurrencty, ITransKey> = {
  [ECurrencty.Kzt]: 'currency_kzt',
  [ECurrencty.Eur]: 'currency_eur',
  [ECurrencty.Rur]: 'currency_rub',
  [ECurrencty.Usd]: 'currency_usd',
};
