import { AgreementService } from './service/AgreementService';
import { useGlobalContext } from '@pay/modules';

export interface AgreementModuleContext {
  agreement: {
    service: AgreementService;
  };
}

export const useAgreementService = () =>
  useGlobalContext<AgreementModuleContext>().agreement.service;
