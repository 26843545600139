import { Box } from '@material-ui/core';
import { Column, FilterType, TextFilterFormat } from '@pay/admin-data-table';
import { DetailsLinkContainer } from '@pay/admin-ui-kit';
import { BOMealUserDto } from 'apis-generated/mapper-sso-admin';
import { ROLE_DETAILS_ENTITY_ID } from 'modules/user-management/constants';
import React from 'react';
import { ITransFunction } from 'startup/i18n';
import { MEAL_ADMIN_DETAILS_ENTITY_ID, MEAL_ADMIN_EDIT_DETAILS_ENTITY_ID } from '../constants';

export const getUserColumns = (t: ITransFunction): Column<BOMealUserDto>[] => {
  type RolesFilterPath = [keyof BOMealUserDto, keyof NonNullable<BOMealUserDto['roles']>[0]];
  const rolesFilterPath: RolesFilterPath = ['roles', 'name'];

  return [
    {
      accessor: 'username',
      Header: t('users_table_column_user_name') as string,
      Cell: ({ row: { original }, value }) => (
        <>
          <DetailsLinkContainer
            entityId={original.id}
            entityType={MEAL_ADMIN_EDIT_DETAILS_ENTITY_ID}
            title={value}
          />
        </>
      ),
      customFilter: {
        type: FilterType.Text,
        field: 'userName',
        format: TextFilterFormat.Text,
      },
      sorting: {
        rsqlOptions: {
          field: 'userName',
        },
      },
    },
    {
      accessor: 'firstName',
      Header: t('users_table_column_first_name') as string,
      customFilter: {
        type: FilterType.Text,
        format: TextFilterFormat.Text,
      },
    },
    {
      accessor: 'lastName',
      Header: t('users_table_column_last_name') as string,
      customFilter: {
        type: FilterType.Text,
        format: TextFilterFormat.Text,
      },
    },
    {
      accessor: 'position',
      Header: t('users_table_column_position') as string,
      customFilter: {
        type: FilterType.Text,
        format: TextFilterFormat.Text,
      },
    },
    {
      accessor: 'roles',
      Header: t('users_table_column_role') as string,
      Cell: ({ value = [] }) => (
        <Box>
          {value.map((role, ind, arr) => (
            <React.Fragment key={role.id}>
              <DetailsLinkContainer
                entityId={role.id}
                entityType={ROLE_DETAILS_ENTITY_ID}
                title={role.name}
              />
              {ind !== arr.length - 1 && <span>, </span>}
            </React.Fragment>
          ))}
        </Box>
      ),
      customFilter: {
        type: FilterType.Text,
        field: rolesFilterPath.join('.') as Extract<keyof BOMealUserDto, string>,
        format: TextFilterFormat.Text,
      },
    },
    {
      accessor: 'email',
      Header: t('users_table_column_email') as string,
      Cell: ({ row: { original }, value }) => <>{value}</>,
      customFilter: {
        type: FilterType.Text,
        format: TextFilterFormat.Text,
      },
    },
    {
      accessor: 'schoolLocations',
      Header: t('users_table_column_location') as string,
      Cell: ({ row: { original }, value }) => <>{value?.map((item) => item.location)}</>,
      customFilter: {
        type: FilterType.Text,
        format: TextFilterFormat.Text,
      },
    },
  ];
};
