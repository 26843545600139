import { Column, FilterType, TextFilterFormat } from '@pay/admin-data-table';
import { DetailsLinkContainer } from '@pay/admin-ui-kit';
import React from 'react';
import { IAppTransFunction } from 'startup/utils';
import { BUSINESS_ACCOUNT_DETAILS_ENTITY_ID, SUBJECT_TYPE_MAP } from '../../constants';
import {
  LegalFinancialOrganizations,
  LegalInfo,
  LegalParticipants,
  SubjectType,
} from 'apis-generated/mapper-processing-admin/models';
import { DATE_FORMAT } from 'modules/common/constants';
import { formatDate } from 'modules/common/format';

export const getBusinessAccountsColumns = (t: IAppTransFunction): Column<LegalInfo>[] => {
  return [
    {
      accessor: 'name',
      Header: t('business_accounts_column_name'),
      customFilter: {
        field: 'name',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
      Cell: ({ row: { original }, value }) => (
        <DetailsLinkContainer
          title={value}
          entityType={BUSINESS_ACCOUNT_DETAILS_ENTITY_ID}
          entityId={original.extId}
        />
      ),
    },

    {
      accessor: 'extId',
      Header: t('business_accounts_column_identifier'),
      customFilter: {
        field: 'extId',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },

    {
      accessor: 'type',
      Header: t('business_accounts_column_type'),
      customFilter: {
        field: 'type',
        type: FilterType.Select,
        values: [
          {
            title: t('business_account_type_legal'),
            value: SubjectType.Legal,
          },
          {
            title: t('business_account_type_entrepreneur'),
            value: SubjectType.Entrepreneur,
          },
        ],
      },
      Cell: ({ value }) => {
        return value ? t(SUBJECT_TYPE_MAP[value]) : '';
      },
    },

    {
      accessor: 'updatedAt',
      Header: t('business_accounts_column_date_of_last_activity'),
      Cell: ({ row: { original }, value }) => (
        <>{value ? formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm) : null}</>
      ),
      customFilter: {
        field: 'updatedAt',
        type: FilterType.DateRange,
      },
    },
    {
      accessor: 'createdAt',
      Header: t('common_added_date'),
      Cell: ({ row: { original }, value }) => (
        <>{value ? formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm) : null}</>
      ),
      customFilter: {
        field: 'createdAt',
        type: FilterType.DateRange,
      },
    },
  ];
};

export const getBusinessAccountOrganizationsColumns = (
  t: IAppTransFunction
): Column<LegalFinancialOrganizations>[] => {
  return [
    {
      accessor: 'financialName',
      Header: t('business_accounts_column_name'),
      customFilter: {
        field: 'financialName',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },

    {
      accessor: 'financialBin',
      Header: t('business_accounts_column_identifier'),
      customFilter: {
        field: 'financialBin',
        format: TextFilterFormat.Text,
        type: FilterType.Text,
      },
    },

    {
      accessor: 'dateAdded',
      Header: t('business_accounts_fi_added_date'),
      Cell: ({ value }) => (
        <>{value ? formatDate(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm) : null}</>
      ),
      customFilter: {
        field: 'dateAdded',
        type: FilterType.DateRange,
      },
    },
  ];
};
