import { ConfigurationService } from './service/ConfigurationService';
import { useGlobalContext } from '@pay/modules';

export interface ConfigurationModuleContext {
  configuration: {
    service: ConfigurationService;
  };
}

export const useConfigurationService = () =>
  useGlobalContext<ConfigurationModuleContext>().configuration.service;
