import { DataTable, TableToolbar, useTable } from '@pay/admin-data-table';
import React, { useMemo, FC } from 'react';
import { pipe } from 'fp-ts/es6/function';
import * as TE from 'fp-ts/TaskEither';
import * as E from 'fp-ts/Either';
import { useModals, useNotificationsStore } from '@pay/admin-ui-kit';

import { useTranslation } from 'startup/utils';
import { getWorkspaceColumns } from './tableDefs';
import { ClientDetailRespDto } from 'apis-generated/mapper-sso-admin';
import { useClientService, useWorkspaceService } from '../../../module';
import { ClientWorkspaceFinancial } from 'apis-generated/mapper-processing-admin';
import { Button } from '@material-ui/core';
import { shouldTrans } from 'modules/common/utils';

interface IProps {
  client: ClientDetailRespDto;
}

export const ClientWorkspacesPane: FC<IProps> = ({ client }) => {
  const service = useClientService();
  const workspaceService = useWorkspaceService();
  const { t } = useTranslation();
  const { showNotification } = useNotificationsStore();

  // FIXME: check real permissions
  const canAddBusiness = true;

  const columns = useMemo(
    () =>
      getWorkspaceColumns(t, client, (clientWorkspaceFinancial) => {
        pipe(
          workspaceService.deleteWorkspace(clientWorkspaceFinancial.workspace?.id!),
          TE.map(() => refresh()),
          TE.mapLeft(() => {
            showNotification({
              text: t('client_details_ws_delete_error'),
              options: { variant: 'error' },
            });
          })
        )();
      }),
    [client, showNotification, t, workspaceService]
  );

  const { tableProps, toolBarProps, refresh } = useTable<ClientWorkspaceFinancial>({
    fetchData: async () => {
      const res = await service.getClientWorkspaces(client.username)();
      if (E.isLeft(res)) {
        throw new Error();
      }
      return {
        items: res.right,
        total: res.right.length,
      };
    },
    columns,
    keyGetter: (it) => it.workspace?.id ?? '',
  });

  return (
    <div>
      <TableToolbar<ClientWorkspaceFinancial> {...toolBarProps} columns={columns}></TableToolbar>
      <DataTable<ClientWorkspaceFinancial> {...tableProps} />
    </div>
  );
};
