import { constUndefined, pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as E from 'fp-ts/Either';
import { encode } from 'js-base64';
import { IFetcher } from '@pay/data-fetching';

import {
  AuthResponse,
  BackofficeChangeCurrentKeyReqDto,
  BackofficeUserApiInterface,
  BackofficeUserGenerated2FAResponse,
  VerifyApiInterface,
} from 'apis-generated/mapper-sso-admin';
import { constCommonError, ICommonError, IRemoteError } from 'modules/common/store';
import { mapFetcherResultEither } from 'modules/common/fetcher';
import { matchServerError } from 'modules/common/errors';

import { mapToCurrentUserModel } from '../data/CurrentUserModelMapper';
import { I2FaVerificationError, IAuthLoginError } from '../constants';
import { CurrentUserModel } from '../data';
import { map2FaVerificationError } from './utils';

export interface IAuthLoginResponse {
  authSessionId: string;
  activated2FA: boolean;
  qrUrl?: string;
  secret?: string;
}

export interface IResetSecondFactorContext {
  generatedID2FA: string;

  qrUrl: string;

  secret: string;
}

export interface IAuthService {
  login: (
    login: string,
    password: string
  ) => TE.TaskEither<IAuthLoginError | ICommonError | IRemoteError, AuthResponse>;

  logout(): Promise<void>;

  verifyCode(codeDto: {
    authSessionId: string;
    code: string;
  }): TE.TaskEither<I2FaVerificationError | IRemoteError, undefined>;

  changePassword: (
    newPassword: string,
    code: string
  ) => TE.TaskEither<IRemoteError | I2FaVerificationError, undefined>;

  generate2FA: (
    code: string
  ) => TE.TaskEither<IRemoteError | I2FaVerificationError, BackofficeUserGenerated2FAResponse>;

  validate2FA: (
    generatedId: string,
    newCode: string
  ) => TE.TaskEither<IRemoteError | I2FaVerificationError, boolean>;

  fetchCurrentUser(): TE.TaskEither<IRemoteError, CurrentUserModel>;
}

export class AuthService implements IAuthService {
  constructor(
    private _backofficeUserApi: BackofficeUserApiInterface,
    private _ssoFetcher: IFetcher,
    private _verifyApi: VerifyApiInterface
  ) {}

  public login = (
    login: string,
    password: string
  ): TE.TaskEither<IRemoteError | ICommonError | IAuthLoginError, AuthResponse> => {
    const createCreds = E.tryCatch(() => {
      return encode(login + ':' + password);
    }, constCommonError);

    const makeRequest = (creds: string) =>
      pipe(
        () =>
          this._ssoFetcher.post<AuthResponse>('bo/auth', {
            headers: {
              Authorization: `Basic ${creds}`,
            },
          }),
        T.map(mapFetcherResultEither),
        TE.mapLeft(
          matchServerError({
            '401000': (): IAuthLoginError => ({ type: 'InvalidCredentials' }),
          })
        )
      );

    return pipe(TE.fromEither(createCreds), TE.chainW(makeRequest));
  };

  public verifyCode = (codeDto: { authSessionId: string; code: string }) => {
    return pipe(
      () => this._verifyApi.verify2FABackoffice({ verifyRequest: codeDto }),
      T.map(mapFetcherResultEither),
      TE.map(constUndefined),
      TE.mapLeft(map2FaVerificationError)
    );
  };

  public fetchCurrentUser = () => {
    return pipe(
      () => this._backofficeUserApi.getCurrentUser(),
      T.map(mapFetcherResultEither),
      TE.map(mapToCurrentUserModel)
    );
  };

  public changePassword = (newPassword: string, code: string) => {
    const req: BackofficeChangeCurrentKeyReqDto = {
      key: newPassword,
      code: code,
    };

    return pipe(
      () => this._backofficeUserApi.changeCurrentUserKey({ backofficeChangeCurrentKeyReqDto: req }),
      T.map(mapFetcherResultEither),
      TE.map(constUndefined),
      TE.mapLeft(map2FaVerificationError)
    );
  };

  public generate2FA = (code: string) => {
    const reqParams = { backofficeGenerate2FAUserReqDto: { code } };
    return pipe(
      () => this._backofficeUserApi.generateUser2FA(reqParams),
      T.map(mapFetcherResultEither),
      TE.mapLeft(map2FaVerificationError)
    );
  };

  public validate2FA = (generatedId: string, newCode: string) => {
    const reqParams = {
      backofficeAccept2FAUserReqDto: {
        generatedID2FA: generatedId,
        codeNew: newCode,
      },
    };
    return pipe(
      () => this._backofficeUserApi.acceptUser2FA(reqParams),
      T.map(mapFetcherResultEither),
      TE.mapLeft(map2FaVerificationError)
    );
  };

  public async logout(): Promise<void> {
    await this._backofficeUserApi.logout();
  }
}
