import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';

import { mapFetcherResultEither } from 'modules/common/fetcher';
import { BlackboxApiInterface } from 'apis-generated/mapper-processing-admin';

export class OrganizationsService {
  constructor(private _api: BlackboxApiInterface) {}

  public fetchOrganization = (bin: string) =>
    pipe(() => this._api.getBlackbox({ bin: bin }), T.map(mapFetcherResultEither));

  public fetchAllOrganizations = () =>
    pipe(() => this._api.allBlackboxes({}), T.map(mapFetcherResultEither));

  public approveOrganization = (bin: string) => {
    return pipe(
      () => this._api.approveBlackbox({ blackboxApproveRequest: { bin: bin } }),
      T.map(mapFetcherResultEither)
    );
  };
}
